<template>
  <div class="centenr">
    <div class="newsContent-main">
      <div class="main-left">
        <div class="main-left-top">
          <div class="main-left-top-title">
            <div class="main-left-top-title-xw">产品中心</div>
            <div class="main-left-top-title-yw">PRoduct CEMTER</div>
          </div>
        </div>
        <div class="main-left-top-title-xt"></div>
        <div class="main-left-bottom">
          <el-row>
            <el-col :span="24">
              <!-- :default-active="" -->
              <el-menu ref="navmenu" background-color="#C70F1A" class="el-menu-vertical-demo" @select="handSelect"
                :unique-opened=true :default-openeds="openeds" text-color=" #FFFFFF" @open="onClickMenu" @close="close"
                collapse-transition>
                <el-submenu :index="String(index + 1)" v-for="(item, index) in tableData" :key="index">
                  <template slot="title">
                    <span>{{ item.name }}</span>
                  </template>
                  <el-menu-item-group v-for="(items, indexs) in CpList " :key="indexs">
                    <el-menu-item :index=String(items.id)>
                      <div class="main-left-bottom-title" style="display:block;" :title="items.title">{{
                        items.title }}</div>
                    </el-menu-item>
                  </el-menu-item-group>
                </el-submenu>
              </el-menu>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="main-right-table">
        <div v-html="htmlData" class="main-right-table-center"> </div>
      </div>
    </div>
    <Regularbox></Regularbox>
  </div>
</template>
<script>
import { get } from '../axios/axios.js';
import Regularbox from './regularbox.vue';
import { Message } from 'element-ui'
export default {
  props: {
    navSubItemIndex: { type: String, default: () => "0" },
  },
  components: { Regularbox },
  // computed: {
  //   openeds() {
  //     return [this.navSubItemIndex]
  //   }
  // },
  data() {
    return {
      currentPage: 1,
      pagesize: 99,
      totalPages: 0,
      tableData: [{ name: '产品系列', id: '1' }, { name: '主营业务', id: '2' }],
      select: 41,
      parentId: this.navSubItemIndex,
      CpList: [],
      selectID: '37',
      //解决方案三级标题
      solutiontype: 1,
      htmlData: '',
      threeSolution: [],
    };
  },
  created() {
    if (this.$route.query.name == '产品系列') {
      this.select = this.$route.query.data
      this.GetFrontProductServiceSelectChild()
    } else {
      this.select = this.$route.query.data
      this.GetFrontMainBusinessSelect()
    }
  },
  mounted() {
  },

  watch: {
    // navSubItemIndex(newVal) {
    //   this.$refs['navmenu'].open(newVal)
    //   this.onClickMenu(newVal)
    // },
    '$route'() {
      if (this.$route.query.data !== undefined) {
        this.select = this.$route.query.data
        this.GetFrontProductServiceChild()
      }
      if (this.$route.query.name == '产品系列') {
        this.GetFrontProductServiceSelectChild()
      } else {
        this.select = this.$route.query.data
        this.GetFrontMainBusinessSelect()
      }
    }
  },
  methods: {
    //产品中心左侧导航栏
    async GetFrontProductServiceChild() {
      this.CpList = []
      get('/api/PageQuery/GetFrontProductServiceChild', { title: '', Page: 1, Rows: 99, ServiceType: 0, content: '', }).then(res => {
        if (res.flag) {
          this.CpList = res.data.gettProductService
        } else {
          Message.error("数据加载失败");
        }
      })
    },
    //主营业务左侧导航栏
    async GetFrontMainBusiness() {
      this.CpList = []
      get('/api/PageQuery/GetFrontMainBusiness', { title: '', Page: 1, Rows: 99, mainBusinessType: 0, content: '', }).then(res => {
        if (res.flag) {
          this.CpList = res.data
        } else {
          Message.error("数据加载失败");
        }
      })
    },
    //产品系列详情
    async GetFrontProductServiceSelectChild() {
      await get('/api/PageQuery/GetFrontProductServiceSelectChild', { id: this.select }).then(res => {
        if (res.flag) {
          this.htmlData = res.data.content
        } else {
          this.htmlData = '暂无数据'
        }
      })
    },
    //主营业务详情
    async GetFrontMainBusinessSelect() {
      await get('/api/PageQuery/GetFrontMainBusinessSelect', { id: this.select }).then(res => {
        if (res.flag) {
          this.htmlData = res.data.content
        } else {
          this.htmlData = '暂无数据'
        }
      })
    },
    handSelect(key) {
      this.select = key
      if (this.selectID == '2') {
        this.GetFrontMainBusinessSelect()
      } else {
        this.GetFrontProductServiceSelectChild()
      }
    },
    onClickMenu(key) {
      this.selectID = key
      if (key == '2') {
        this.GetFrontMainBusiness()
      } else {
        this.GetFrontProductServiceChild()
      }
    },
    close() {
    }
  },
}
</script>

<style lang="scss" scoped>
.centenr {
  display: flex;
  align-items: center;
  justify-content: center;

  .newsContent-main {
    margin-left: 8px;
    width: 62.5rem;
    height: auto;
    margin-top: 1.25rem;
    padding-bottom: 1.25rem;
    display: flex;
    background-color: #FFFFFF;

    .main-left {
      width: 11.25rem;
      background: #C70F1A;
      opacity: 1;
      border-radius: 0rem;
      display: flex;
      flex-direction: column;

      .main-left-top {
        height: 4.3125rem;

        .main-left-top-title {
          margin: 1.25rem 0rem .6875rem 1.25rem;
          text-align: left;

          .main-left-top-title-xw {
            width: 4rem;
            height: 1.3125rem;
            font-size: 1rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 0rem;
            color: #FFFFFF;
            opacity: 1;
          }

          .main-left-top-title-yw {
            margin-top: .125rem;
            height: .9375rem;
            font-size: .6875rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 0rem;
            color: #FB9898;
            opacity: 1;
          }
        }
      }

      .main-left-top-title-xt {
        height: .0625rem;
        margin: 0 .375rem 0 .4375rem;
        background: url("../assets/newGW/xwxt.png");
      }

      .main-left-bottom {
        margin-top: .0625rem;

        .el-menu-vertical-demo {
          text-align: left;
          font-size: .6875rem;
          padding-bottom: 20px;
          font-family: Microsoft YaHei;
          line-height: 0rem;
          color: #FFFFFF;
          opacity: 1;
          border-right: none;

          .main-left-bottom-title {
            width: 128px;
            margin-left: 15px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .main-left-bottom-title:hover {
          transition: all 0.8s;
          transform: translateX(10px);
        }
      }
    }

    .main-right-table {
      margin-left: 20px;
      flex: 1;
      width: calc(100% - 11.25rem);
      background-color: #F5F5F5;
      text-align: left;

      .main-right-table-center {
        padding: 20px;
      }
    }
  }
}



::v-deep .el-table__body {
  -webkit-border-vertical-spacing: .625rem;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  width: 2.0625rem;
  height: 1.75rem;
  font-size: .5625rem;
  margin-top: .125rem;
  background: #C21F32;
  opacity: 1;
  border-radius: .125rem;
}

::v-deep .el-table .el-table__cell {
  padding: .5625rem 0;
}



::v-deep .el-submenu .el-menu-item {
  min-width: 4.25rem;
  font-size: .6875rem;
  height: 1.5625rem;
  line-height: 1.375rem;
  padding-left: 1.25rem !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

//设置鼠标悬停时el-menu-item的样式
.el-menu-item:hover {
  background-image: url("../assets/newGW/navebg.png");
  background-size: 100% 100%;
}



::v-deep .el-menu-item.is-active {
  // 被点击以后字体颜色改变
  background-image: url("../assets/newGW/navebg.png");
  background-size: 100% 100%;
  color: #F5F5F5;
}

::v-deep .el-submenu__title {
  height: 44px;
  line-height: 37px;
}

::v-deep .el-submenu__title i {
  color: #FFFFFF
}
</style>