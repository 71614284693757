<template>
  <div class="main-right-table">
    <div class="main-right-table-top">
      <div class="main-right-table-top-title">
        <div><img src="../assets/newGW/fk.png" alt=""></div>
        <div class="main-right-table-top-title-wz">方案概述 </div>
        <div><img src="../assets/newGW/fk.png" alt=""></div>
      </div>
      <div class="main-right-table-top-bottom">
        <div class="bottom-box-wz">
          <span>资产云是用友政务投资并负责运营的一套以满足行政事业单位、财政资产管理系统需求为基点的大型网络化应用。它以平台化、开放性、生态化、综合性为构建理念，以在线服务方式向行政事业单位、财政提供资产管理业务服务，并能够对接财政、行政事业单位的第三方业务系统，融入单位业务之中，形成整体业务优势。</span>
          资产云业务应用由多个模块构成，既有互联网业务应用，也有政务网业务应用，还有智能手机端应用。其中，财政资产监管管理系统、行政事业单位资产管理系统、通用报表平台、数据分析平台、资产出租、调剂维修、公共资源有偿使用等业务构成资产云的业务主体，满足财政、行政事业单位不同类型用户的管理需要。预留与财政、行政事业单位第三方业务系统接口服务。
          <span>
            资产云通过统一资产信息库，归集全辖区内资产业务信息，是一套完整的全辖区资产信息清单 ；资产数据仓库从多方归集资产业务数据，满足统计分析类业务应用需求。</span>
        </div>
      </div>
    </div>
    <div class="main-right-table-two-box">
      <div class="main-right-table-two-box-name">资产云模式 </div>
      <div class="main-right-table-two-title-two">
        “资产云”利用物联网技术构建实物数据追溯体系，对接现有资产档案数据建设资产智能管理云数据中心，建立资产编码云、资产物联云、智慧共享云等资产管控云平台，
        <span>实现资产大数据可视化、采购决策支持、资源管理一张图、远程诊断运营维护、资产共享等目标。</span>
      </div>
      <div class="main-right-table-two-box-title">
        <div class="main-right-table-two-box-title-box">
          <div class="main-right-table-two-box-title-wz">新型资产管理标准的应用</div>
          <div class="main-right-table-two-box-title-box-bottom">
            <div class="main-right-table-two-box-title-box-bottom-tp">
              构建新型资产管理标准，保证信息流和资产实物流对应。基于大数据、物联网等技术，“资产云”将单位资产管理内部控制规范的要求嵌入信息系统，从资产立项开始，按照统一管理标准予以分类编码、分级构建组织关系、灵活设置流程权限、自定义资产卡片样式和报表格式等。通过“资产云”平台的创新实践，实现全过程资产管理跟踪、全生命周期动态监管
            </div>
          </div>
        </div>
        <div class="main-right-table-two-box-title-box">
          <div class="main-right-table-two-box-title-wz">物联网和GS1编码技术的应用</div>
          <div class="main-right-table-two-box-title-box-bottom">
            <div class="main-right-table-two-box-title-box-bottom-tp">
              运用物联网和GS1编码技术对资产进行全口径全过程全生命周期监控：基于GS1统一编码服务实现对资产全生命周期管理。建立从采购到送货到建卡入库的全流程数据统一贯通服务。通过一站式录入和数据便捷同步操作，提升资产管理效率和数据准确度，实现资产管理的“最多录一次”
            </div>
          </div>
        </div>
        <div class="main-right-table-two-box-title-box">
          <div class="main-right-table-two-box-title-wz">构建资产共享系统</div>
          <div class="main-right-table-two-box-title-box-bottom">
            <div class="main-right-table-two-box-title-box-bottom-tp">
              通过建构资产共享服务平台，单位可以针对闲置、高值资产，在线发布有偿共享服务，服务需求方通过平台获取可供使用的资产信息，通过线上和线下交流，达成资产合理化利用交易，资产积分作为单位后续资产绩效管理的重要考核指标之一，并可用来兑换资产服务。资产共享服务平台不仅避免国有资产重复建设和投资浪费，也为资产拥有方创造了经济效益，能够极大提升资产使用效能
            </div>
          </div>
        </div>
        <div class="main-right-table-two-box-title-box">
          <div class="main-right-table-two-box-title-wz">打造资产的动态管理与绩效评价体系</div>
          <div class="main-right-table-two-box-title-box-bottom">
            <div class="main-right-table-two-box-title-box-bottom-tp">
              依托“资产云”平台，及时动态更新资产信息，使资产配置在阳光下运行，实现财政支出公开透明。结合资产申报单位的资产使用绩效和实物绩效，为资产使用单位提供预算上报数据支撑，同时也为资产管理单位提供预算审批依据</div>
          </div>
        </div>
      </div>

    </div>
    <div class="main-right-table-center">
      <div class="main-right-table-center-title">方案价值</div>
      <div class="main-right-table-center-fsBox">
        <img src="../assets/newGW/zcyone.png" alt="">
      </div>
      <div class="main-right-table-center-hswzBox">对各部门和预算单位业务用户的价值</div>
      <div class="main-right-table-center-hsBox">
        <img src="../assets/newGW/zcyTwo.png" alt="">
      </div>
      <div class="main-right-table-center-hsBoxwz">对财政和各部门及预算单位IT运维支持人员的价值</div>
      <div class="main-right-table-center-redBox">
        <img src="../assets/newGW/zcyThree.png" alt="">
      </div>
      <div class="redbox-one-wz-btn">对财政等部门和政府领导的价值</div>
    </div>
    <div class="main-right-table-foot">
      <div class="foot-title">应用亮点</div>
      <div class="main-right-table-bottom-foot">
        <img src="../assets/newGW/zcyfour.png" alt="">
      </div>
    </div>


  </div>
</template>
<script>

export default {

  data() {
    return {
      currentPage: 1,
      pagesize: 99,
      totalPages: 0,
      tableData: [],
      ZizeData: [],
      DataType: '1',
      titleData: '公司简介',
      select: '1',
      scrollerHeight: '420',
      dataHtml: [],
      isCollapse: true, //导航栏默认为展开
    };
  },
  created() {
  },
  mounted() {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.main-right-table {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: 800px;
  background-color: #F5F5F5;

  .main-right-table-top {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #F5F5F5;

    .main-right-table-top-title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 33px;

      .main-right-table-top-title-wz {
        height: 16px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 15px;
        color: #000000;
        opacity: 1;
        padding: 0px 9px 0px 9px;
      }
    }

    .main-right-table-top-bottom {
      display: flex;
      flex-wrap: nowrap;
      padding: 22px 50px 35px 50px;
      justify-content: space-between;

      .bottom-box-wz {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        opacity: 1;
        text-align: left;
        text-indent: 30px;
      }

      .bottom-box-wz>span {
        color: #C70F1A;
      }
    }

  }

  .main-right-table-two-box {
    width: 100%;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;

    .main-right-table-two-box-name {
      padding-top: 32px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      line-height: 0px;
      color: #2E2E2E;
      opacity: 1;
    }

    .main-right-table-two-title-two {
      padding: 20px 50px 0px 50px;
      font-size: 14px;
      color: #000000;
      text-align: left;
    }

    .main-right-table-two-title-two>span {
      font-size: 14px;
      color: #C70F1A;
    }

    .main-right-table-two-box-title {
      padding: 34px 50px 28px 50px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .main-right-table-two-box-title-box {
        display: flex;
        flex-direction: column;
        padding-top: 12px;

        .main-right-table-two-box-title-wz {
          width: 331px;
          height: 44px;
          background-size: 100% 100%;
          font-size: 15px;

          background: #E20000;
          color: #FFFFFF;
          line-height: 39px;
          border-radius: 5px;
        }

        .main-right-table-two-box-title-box-bottom {
          width: 331px;
          height: 278px;
          margin-top: 13px;
          display: flex;
          background: #F5F5F5;
          opacity: 1;
          border-radius: 10px;

          .main-right-table-two-box-title-box-bottom-tp {
            width: 296px;
            height: 223px;
            font-size: 13px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 30px;
            color: #090909;
            opacity: 1;
            padding: 20px 15px 35px 20px;
            text-align: left;
          }
        }
      }
    }
  }

  .main-right-table-center {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #F5F5F5;

    .main-right-table-center-title {
      padding-top: 32px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      line-height: 0px;
      color: #2E2E2E;
      opacity: 1;
    }

    .main-right-table-center-fsBox {
      padding: 40px 51px 0px 50px;
      display: flex;
      justify-content: space-between;

      .main-right-table-center-fsBox-one {
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 16px;
        opacity: 1;
        background: #FF8484;
        opacity: 1;
        border-radius: 10px;
        text-align: left;
        width: 155px;
        height: 88px;

        .fsBox-nei {
          font-size: 12px;
          transform: scale(0.75, 0.75);
          color: #FFFFFF;
        }
      }

      .main-right-table-center-fsBox-three {
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 16px;
        opacity: 1;
        background: #FF8484;
        opacity: 1;
        border-radius: 10px;
        text-align: left;
        width: 125px;
        height: 88px;

        .fsBox-nei {
          font-size: 12px;
          transform: scale(0.75, 0.75);
          color: #FFFFFF;
        }
      }

      .main-right-table-center-fsBox-four {
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 16px;
        opacity: 1;
        background: #FF8484;
        opacity: 1;
        border-radius: 10px;
        text-align: left;
        width: 184px;
        height: 88px;

        .fsBox-nei {
          font-size: 12px;
          color: #FFFFFF;
          transform: scale(0.75);
        }
      }
    }

    .main-right-table-center-hsaBox {
      padding-top: 16px;
    }

    .main-right-table-center-hsBoxwz {
      font-size: 10px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 24px;
      color: #FEAA00;
      opacity: 1;
    }

    .main-right-table-center-hswzBox {
      font-size: 10px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 24px;
      color: #E20000;
      opacity: 1;
      padding-top: 9px;
    }

    .main-right-table-center-hsBox {
      padding-top: 18px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      padding: 22px 51px 0px 50px;

      .main-right-table-center-hsBox-one {
        width: 218px;
        height: 88px;
        background: #FFD47D;
        opacity: 1;
        border-radius: 10px;

        .hsBox-one-wz {
          font-size: 12px;
          transform: scale(0.75, 0.75);
          color: #FFFFFF;
          ;
        }
      }
    }

    .main-right-table-center-redBox {
      display: flex;
      justify-content: space-between;
      padding: 22px 51px 0px 50px;

      .main-right-table-center-redBox-one {
        width: 218px;
        height: 88px;
        background: #65BFE6;
        opacity: 1;
        border-radius: 10px;

        .redBox-one-wz {
          font-size: 9px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 16px;
          color: #FFFFFF;
          opacity: 1;
          transform: scale(0.75, 0.75);
        }
      }

    }

    .redBox-one-xt {
      padding-top: 10px;
    }

    .redbox-one-wz-btn {
      font-size: 10px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 24px;
      color: #00ACF6;
      opacity: 1;
      padding-bottom: 27px;

    }
  }

  .main-right-table-foot {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #FFFFFF;

    .foot-title {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      line-height: 0px;
      color: #2E2E2E;
      opacity: 1;
      padding-top: 32px;
    }

    .main-right-table-bottom-foot {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 45px 0px 30px 0px;
      justify-content: center;

      .main-right-table-bottom-foot-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 25%;

        .box-top-title {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #2E2E2E;
          opacity: 1;
          padding-top: 16px;
        }

        .box-top-center {
          width: 126px;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #7A7A7A;
          opacity: 1;
          padding-top: 11px;
        }
      }

      #box-top-size {
        margin-top: 25px;
      }
    }
  }

}



@keyframes myMou1 {
  0% {
    margin-top: -12.5rem;
  }

  100% {
    margin-top: 0rem;
  }
}



::v-deep .el-table__body {
  -webkit-border-vertical-spacing: .625rem;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  width: 2.0625rem;
  height: 1.75rem;
  font-size: .5625rem;
  margin-top: .125rem;
  background: #C21F32;
  opacity: 1;
  border-radius: .125rem;
}

::v-deep .el-table .el-table__cell {
  padding: .5625rem 0;
}



::v-deep .el-submenu .el-menu-item {
  min-width: 6.25rem;
  font-size: .6875rem;
  height: 1.5625rem;
  line-height: 1.375rem;
  padding-left: 1.25rem !important;
}

//设置鼠标悬停时el-menu-item的样式
.el-menu-item:hover {
  background-image: url("../assets/newGW/navebg.png");
  background-size: 100% 100%;
  animation: 0.5s;
}

::v-deep .el-menu-item.is-active {
  // 被点击以后字体颜色改变
  background-image: url("../assets/newGW/navebg.png");
  background-size: 100% 100%;
  color: #F5F5F5;
}
</style>