<template>
  <div class="main-right-table">
    <div class="main-right-table-top">
      <div class="main-right-table-top-title">
        <div><img src="../assets/newGW/fk.png" alt=""></div>
        <div class="main-right-table-top-title-wz">方案概述 </div>
        <div><img src="../assets/newGW/fk.png" alt=""></div>
      </div>
      <div class="main-right-table-top-bottom">
        <div class="bottom-box-wz"> 利用大数据手段，提升财政资金运行效率，科学支持财政决策，
          <span>
            推进财政服务经济社会发展能力，助力财政提升管理与公共服务水平。</span>
        </div>
      </div>
    </div>
    <div class="main-right-table-two-box">
      <div class="main-right-table-two-box-title">
        <div class="main-right-table-two-box-title-box">
          <div class="main-right-table-two-box-title-wz">连接、共享</div>
          <div class="main-right-table-two-box-title-box-bottom">
            <div><img src="../assets/newGW/yx.png" alt=""></div>
            <div class="main-right-table-two-box-title-box-bottom-tp">数据集成平台</div>
          </div>
          <div class="main-right-table-two-box-title-box-bottom">
            <div><img src="../assets/newGW/yx.png" alt=""></div>
            <div class="main-right-table-two-box-title-box-bottom-tp">共享交换平台</div>
          </div>
        </div>
        <div class="main-right-table-two-box-title-box">
          <div class="main-right-table-two-box-title-wz">融合、分析</div>
          <div class="main-right-table-two-box-title-box-bottom">
            <div><img src="../assets/newGW/yx.png" alt=""></div>
            <div class="main-right-table-two-box-title-box-bottom-tp">决策大屏</div>
          </div>
          <div class="main-right-table-two-box-title-box-bottom">
            <div><img src="../assets/newGW/yx.png" alt=""></div>
            <div class="main-right-table-two-box-title-box-bottom-tp">主题分析</div>
          </div>
          <div class="main-right-table-two-box-title-box-bottom">
            <div><img src="../assets/newGW/yx.png" alt=""></div>
            <div class="main-right-table-two-box-title-box-bottom-tp">专题分析</div>
          </div>
          <div class="main-right-table-two-box-title-box-bottom">
            <div><img src="../assets/newGW/yx.png" alt=""></div>
            <div class="main-right-table-two-box-title-box-bottom-tp">智能报告</div>
          </div>
        </div>
        <div class="main-right-table-two-box-title-box">
          <div class="main-right-table-two-box-title-wz">实时、查询</div>
          <div class="main-right-table-two-box-title-box-bottom">
            <div><img src="../assets/newGW/yx.png" alt=""></div>
            <div class="main-right-table-two-box-title-box-bottom-tp">实时查询</div>
          </div>
          <div class="main-right-table-two-box-title-box-bottom">
            <div><img src="../assets/newGW/yx.png" alt=""></div>
            <div class="main-right-table-two-box-title-box-bottom-tp">智能报表</div>
          </div>
          <div class="main-right-table-two-box-title-box-bottom">
            <div><img src="../assets/newGW/yx.png" alt=""></div>
            <div class="main-right-table-two-box-title-box-bottom-tp">自定义查询</div>
          </div>
          <div class="main-right-table-two-box-title-box-bottom">
            <div><img src="../assets/newGW/yx.png" alt=""></div>
            <div class="main-right-table-two-box-title-box-bottom-tp">移动APP查询</div>
          </div>
        </div>
        <div class="main-right-table-two-box-title-box">
          <div class="main-right-table-two-box-title-wz">智能、预测</div>
          <div class="main-right-table-two-box-title-box-bottom">
            <div><img src="../assets/newGW/yx.png" alt=""></div>
            <div class="main-right-table-two-box-title-box-bottom-tp">收支预测</div>
          </div>
          <div class="main-right-table-two-box-title-box-bottom">
            <div><img src="../assets/newGW/yx.png" alt=""></div>
            <div class="main-right-table-two-box-title-box-bottom-tp">可用财力分析</div>
          </div>
          <div class="main-right-table-two-box-title-box-bottom">
            <div><img src="../assets/newGW/yx.png" alt=""></div>
            <div class="main-right-table-two-box-title-box-bottom-tp">智能预算编审</div>
          </div>
          <div class="main-right-table-two-box-title-box-bottom">
            <div><img src="../assets/newGW/yx.png" alt=""></div>
            <div class="main-right-table-two-box-title-box-bottom-tp">智能搜索</div>
          </div>
          <div class="main-right-table-two-box-title-box-bottom">
            <div><img src="../assets/newGW/yx.png" alt=""></div>
            <div class="main-right-table-two-box-title-box-bottom-tp">智能匹配</div>
          </div>
        </div>

      </div>

    </div>
    <div class="main-right-table-center">
      <div class="main-right-table-center-title">
        <div class="main-right-table-center-title-wz">财政大数据平台 </div>
      </div>
      <div class="main-right-table-center-bottom">
        <img src="../assets/newGW/czdsjData.png" alt="">
      </div>
    </div>
    <div class="main-right-table-bottom">
      <div class="main-right-table-bottom-title">核心服务 </div>
      <div class="main-right-table-bottom-title-two">财政大数据管理平台</div>
      <div class="main-right-table-bottom-title-box">
        <img src="../assets/newGW/hxfwbg.png" alt="">
      </div>
    </div>
    <div class="main-right-table-foot">
      <div class="foot-title">数据共享交换平台</div>
      <div class="main-right-table-bottom-foot">
        <div class="main-right-table-bottom-foot-box">
          <div class="box-img"><img src="../assets/newGW/sjbz1.png" alt=""></div>
          <div class="box-top-title">数据标准</div>
          <div class="box-top-center">构建政务信息共享标准体系，建立健全政务信息资源数据标准 </div>
        </div>
        <div class="main-right-table-bottom-foot-box">
          <div class="box-img"><img src="../assets/newGW/sjgx2.png" alt=""></div>
          <div class="box-top-title">强化协同</div>
          <div class="box-top-center">建设政务信息共享网站和共享
            交换平台，支撑政府部门间的
            信息共享与业务协同 </div>
        </div>
        <div class="main-right-table-bottom-foot-box">
          <div class="box-img"><img src="../assets/newGW/sjgx3.png" alt=""></div>
          <div class="box-top-title">决策支持</div>
          <div class="box-top-center">在共享数据的基础上，对数据资
            源进行深层次的综合利用，为政
            府决策提供科学依据 </div>
        </div>
        <div class="main-right-table-bottom-foot-box" id="box-top-size">
          <div class="box-img"><img src="../assets/newGW/sjgx4.png" alt=""></div>
          <div class="box-top-title">促进共享</div>
          <div class="box-top-center">构建多级互联的数据共享交换平台体系，促进政务信息向各级政府部门共享 </div>
        </div>
        <div class="main-right-table-bottom-foot-box" id="box-top-size">
          <div class="box-img"><img src="../assets/newGW/sjgx5.png" alt=""></div>
          <div class="box-top-title">推进整合</div>
          <div class="box-top-center">加快推动分散隔离的政务信息
            系统整合共享，实现互联互
            通、业务协同、信息共享 </div>
        </div>
        <div class="main-right-table-bottom-foot-box" id="box-top-size">
          <div class="box-img"><img src="../assets/newGW/sjgx6.png" alt=""></div>
          <div class="box-top-title">数据编目</div>
          <div class="box-top-center">开展政务信息资源目录编制，建
            立政务信息资源目录体系，实现
            数据规范管理 </div>
        </div>
      </div>
    </div>
    <div class="main-right-table-productBox">
      <div class="main-right-table-productBox-title">税源管理（综合治税）平台 </div>

      <div class="img-box">
        <img src="../assets/newGW/ptss.png" alt="">
      </div>
    </div>

  </div>
</template>
<script>

export default {

  data() {
    return {
      currentPage: 1,
      pagesize: 99,
      totalPages: 0,
      tableData: [],
      ZizeData: [],
      DataType: '1',
      titleData: '公司简介',
      select: '1',
      scrollerHeight: '420',
      dataHtml: [],
      isCollapse: true, //导航栏默认为展开
    };
  },
  created() {
  },
  mounted() {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.main-right-table {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: 800px;
  background-color: #F5F5F5;

  .main-right-table-top {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #F5F5F5;

    .main-right-table-top-title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 33px;

      .main-right-table-top-title-wz {
        height: 16px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 15px;
        color: #000000;
        opacity: 1;
        padding: 0px 9px 0px 9px;
      }
    }

    .main-right-table-top-bottom {
      display: flex;
      flex-wrap: nowrap;
      padding: 22px 50px 35px 50px;
      justify-content: space-between;

      .bottom-box-wz {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        opacity: 1;
        text-align: left;
        text-indent: 50px;
      }

      .bottom-box-wz>span {
        color: #C70F1A;
      }
    }

  }

  .main-right-table-two-box {
    width: 100%;
    background: #FFFFFF;

    .main-right-table-two-box-title {
      padding: 34px 50px 28px 50px;
      display: flex;
      justify-content: space-between;

      .main-right-table-two-box-title-box {
        display: flex;
        flex-direction: column;

        .main-right-table-two-box-title-wz {
          width: 155px;
          height: 39px;
          background: url('../assets/newGW/exdxbj.png');
          background-size: 100% 100%;
          font-size: 15px;
          color: #FFFFFF;
          line-height: 39px;
        }

        .main-right-table-two-box-title-box-bottom {
          padding: 11px 46px 0px 10px;
          display: flex;

          .main-right-table-two-box-title-box-bottom-tp {
            font-size: 14px;
            margin-left: 10px;
          }
        }
      }
    }
  }

  .main-right-table-center {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #F5F5F5;

    .main-right-table-center-title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 33px;

      .main-right-table-center-title-wz {
        height: 16px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 15px;
        color: #000000;
        opacity: 1;
        padding: 0px 9px 0px 9px;
      }
    }

    .main-right-table-center-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 11px 42px 15px 50px;
    }
  }

  .main-right-table-bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #FFFFFF;

    .main-right-table-bottom-title-two {
      padding-top: 32px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      line-height: 0px;
      color: #C70F1A;
      opacity: 1;
    }

    .main-right-table-bottom-title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 33px;
      height: 16px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      line-height: 15px;
      color: #000000;
      opacity: 1;

    }

    .main-right-table-bottom-title-box {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 30px;
      color: #000000;
      opacity: 1;
      text-align: left;
      text-indent: 30px;
      padding-top: 6px;
    }

    .main-right-table-bottom-title-box span {
      color: #C70F1A;
      margin-left: 3px;
    }
  }

  .main-right-table-foot {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #F5F5F5;

    .foot-title {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      line-height: 0px;
      color: #2E2E2E;
      opacity: 1;
      padding-top: 33px;
    }
  }

  .main-right-table-bottom-foot {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 25px 0px 30px 0px;

    .main-right-table-bottom-foot-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 33.33%;

      .box-top-title {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #2E2E2E;
        opacity: 1;
        padding-top: 16px;
      }

      .box-top-center {
        width: 126px;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #7A7A7A;
        opacity: 1;
        padding-top: 11px;
      }
    }

    #box-top-size {
      margin-top: 25px;
    }
  }

  .main-right-table-productBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #FFFFFF;
    margin-top: 30px;

    .main-right-table-productBox-title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 33px;
      height: 16px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      line-height: 15px;
      color: #000000;
      opacity: 1;

    }

    .img-box {
      padding: 30px 50px 38px 14px;
    }
  }
}



@keyframes myMou1 {
  0% {
    margin-top: -12.5rem;
  }

  100% {
    margin-top: 0rem;
  }
}



::v-deep .el-table__body {
  -webkit-border-vertical-spacing: .625rem;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  width: 2.0625rem;
  height: 1.75rem;
  font-size: .5625rem;
  margin-top: .125rem;
  background: #C21F32;
  opacity: 1;
  border-radius: .125rem;
}

::v-deep .el-table .el-table__cell {
  padding: .5625rem 0;
}



::v-deep .el-submenu .el-menu-item {
  min-width: 6.25rem;
  font-size: .6875rem;
  height: 1.5625rem;
  line-height: 1.375rem;
  padding-left: 1.25rem !important;
}

//设置鼠标悬停时el-menu-item的样式
.el-menu-item:hover {
  background-image: url("../assets/newGW/navebg.png");
  background-size: 100% 100%;
  animation: 0.5s;
}

::v-deep .el-menu-item.is-active {
  // 被点击以后字体颜色改变
  background-image: url("../assets/newGW/navebg.png");
  background-size: 100% 100%;
  color: #F5F5F5;
}
</style>