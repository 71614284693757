 <template>
  <div class="pos-main">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item v-for="(item)  in tagList" :key="item.path">
        <router-link :to="item.redirect||item.path">{{item.meta.title}}</router-link>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tagList: []
    };
  },
  mounted() {
    this.getTagList()

  },

  methods: {
    isHome(route) {
      return route.name === "Home";
    },
    getTagList() {
      // 通过$route.matched获取到当前路由及父级list
      let list = this.$route.matched
      // 再将获取到得到list缓存下来
      //如果不是首页
      if (!this.isHome(list[0])) {
        list = [{ path: "/", meta: { title: "首页" } }].concat(list);
      }
      this.tagList = list;
    }

  },
  watch: {
    $route() {
      this.getTagList();
    }
  },
};
</script>
<style lang="scss" scoped>
.pos-main {
  .el-breadcrumb {
    margin-left: 10px;
    color: #000;
    font-size: 20px;
  }
}
</style>
