<template>
  <div class="center">
    <div class="newsContent-main">
      <div class="main-left" style="height:577px">
        <div class="main-left-top">
          <div class="main-left-top-title">
            <div class="main-left-top-title-xw">关于我们</div>
            <div class="main-left-top-title-yw">ABOUT US</div>
          </div>
        </div>
        <div class="main-left-top-title-xt"></div>
        <div class="main-left-bottom">
          <el-row>
            <el-col :span="24">
              <el-menu background-color="#C70F1A" class="el-menu-vertical-demo" @select="handSelect" unique-opened
                :collapse-transition="true" :default-active="activeIndex" text-color="#FFFFFF">
                <el-submenu index="1">
                  <template slot="title">
                    <span>公司简介</span>
                  </template>
                  <el-menu-item-group>
                    <el-menu-item index="2">
                      <div class="main-left-bottom-title" style="display:block;">公司简介</div>
                    </el-menu-item>
                    <el-menu-item index="3">
                      <div class="main-left-bottom-title" style="display:block;">使命与愿景</div>
                    </el-menu-item>
                    <el-menu-item index="4">
                      <div class="main-left-bottom-title" style="display:block;">团队管理</div>
                    </el-menu-item>
                  </el-menu-item-group>
                </el-submenu>
                <el-menu-item index="5">
                  <span>发展历程</span>
                </el-menu-item>
                <el-menu-item index="6">
                  <span>企业资质</span>
                </el-menu-item>
                <el-menu-item index="7">
                  <span>加入我们</span>
                </el-menu-item>
              </el-menu>
            </el-col>
          </el-row>
        </div>
      </div>
      <div>
        <div class="main-right-table">
          <div class="main-right-table-title">
            <div><img src="../assets/newGW/fk.png" alt=""></div>
            <div class="table-title-wz">{{ titleData }}</div>
            <div><img src="../assets/newGW/fk.png" alt=""></div>
          </div>
          <div><img src="../assets/newGW/guanyuxt.png" alt=""></div>
          <div class="main-right-table-box" v-if="select == '2'">
            <div class="main-right-table-box-top">
              <div> <img src="../assets/newGW/gsjjleftBox.png" alt=""></div>
              <div style="margin-left:21px"><img src="../assets/newGW/gsjjlrightBox.png" alt=""></div>
            </div>
            <div class="main-right-table-box-wz">
              湖南四毅软件有限公司成立于2013年,总部设于长沙市高新区麓云路兴工科技园。是一家专注于政务信息化和财政咨询服务的高新技术企业。深耕“政务行业”十余载，是用友政务在湖南省的授权销售机构和核心协同伙伴，负责用友政务全省的市县市场管理、市场营销以及售后服务工作；在湖南省政务信息化领域市场占有率第一。
            </div>
            <div class="main-right-table-box-wz">
              公司拥有强大的技术研发团队 ——
              拥有数名省内一流的信息技术专家和行业应用专家，通过湖南省高新技术企业认证、软件企业认证和ISO9001质量体系认证，获得20余项国家版权局颁发的软件著作权证书。
            </div>
            <div class=" main-right-table-box-wz">
              公司拥有成熟的软件运维团队 ——

              为湖南省四千多家行政事业单位提供核算云、内控建设、财政大数据平台、专项资金管理、资产管理、工资管理等软件优质运维服务，为全省三万多个村和社区的村级财务统一软件提供软件开发及运维保障。
            </div>
            <div class="main-right-table-box-wz">
              公司拥有专业的咨询服务团队 ——
              为湖南省四千多家行政事业单位提供政府会计改革、内部控制建设、政府财报、决算报告、预算绩效、资产报表等专业的咨询服务。
            </div>
          </div>
          <div class="main-right-table-box" v-if="select == '3'">
            <div class="main-right-table-box-top">
              <div> <img src="../assets/newGW/smyyj.png" alt=""></div>
            </div>
            <div class="main-right-table-box-wz1" style="margin-left:15px">
              <div class="wz-one">使命愿景：用信息技术推动政府及公共事业的管理进步。</div>
              <div class="wz-two">团队打造：重视挑选，勤加操练，赏罚分明，同甘共苦。</div>
              <div class="wz-two">服务理念：为客户创造价值，用心服务客户。</div>
              <div class="wz-two">服务宗旨：专业及时，全心全意。</div>
            </div>
          </div>
          <div class="main-right-table-box" v-if="select == '4'">
            <div class="main-right-table-box-top">
              <div> <img src="../assets/newGW/tdglleft.png" alt=""></div>
              <div style="margin-left:21px"><img src="../assets/newGW/tdglright.png" alt=""></div>
            </div>
            <div class="main-right-table-box-wz">
              公司拥有专业的咨询服务团队—为湖南省四千多家行政事业单位的政府会计改革、内部控制建设、政府财报、决算报告、资产报表等提供专业的咨询服务。与用友政务、太极华青等软件业界同行共同努力，在湖南省财政财务软件领域市场占有率第—。
            </div>
          </div>
          <div class="main-right-table-box" v-if="select == '5'">
            <div class="main-right-table-box-top2">
              <div class="top2-title">
                <div class="top2-title-left">
                  <img src="../assets/newGW/lcfk.png" alt="">
                </div>
                <div class="top2-title-center">2013年</div>
                <div class="top2-title-right">注册成立</div>
              </div>
              <div class="top2-title">
                <div class="top2-title-left">
                  <img src="../assets/newGW/lcfk.png" alt="">
                </div>
                <div class="top2-title-center">2014年</div>
                <div class="top2-title-right">北京用友政务核心合作伙伴最佳新秀奖</div>
              </div>
              <div class="top2-title">
                <div class="top2-title-left">
                  <img src="../assets/newGW/lcfk.png" alt="">
                </div>
                <div class="top2-title-center">2014年</div>
                <div class="top2-title-right">北京用友政务行业创新奖</div>
              </div>
              <div class="top2-title">
                <div class="top2-title-left">
                  <img src="../assets/newGW/lcfk.png" alt="">
                </div>
                <div class="top2-title-center">2015年</div>
                <div class="top2-title-right">北京用友政务行业创新奖</div>
              </div>
              <div class="top2-title">
                <div class="top2-title-left">
                  <img src="../assets/newGW/lcfk.png" alt="">
                </div>
                <div class="top2-title-center">2016年</div>
                <div class="top2-title-right">
                  <div>北京用友政务行业创新奖</div>
                  <div class="top2-title-righ-top">服务全省近百个区县的乡镇街道</div>
                  <div class="top2-title-righ-top">扶贫项目管理系统成功上线</div>
                </div>
              </div>
              <div class="top2-title">
                <div class="top2-title-left">
                  <img src="../assets/newGW/lcfk.png" alt="">
                </div>
                <div class="top2-title-center">2017年</div>
                <div class="top2-title-right">
                  <div>入驻长沙高新开发区兴工科技园</div>
                  <div class="top2-title-righ-top">获得十个软件著作权</div>
                  <div class="top2-title-righ-top">创新研发资金管理，监督监管等系统</div>
                </div>
              </div>
              <div class="top2-title">
                <div class="top2-title-left">
                  <img src="../assets/newGW/lcfk.png" alt="">
                </div>
                <div class="top2-title-center">2018年</div>
                <div class="top2-title-right">
                  <div>通过湖南省高新技术企业</div>
                  <div class="top2-title-righ-top">通过湖南省软件企业认证</div>
                  <div class="top2-title-righ-top">中标湖南省村级财务统一软件项目</div>
                </div>
              </div>
              <div class="top2-title">
                <div class="top2-title-left">
                  <img src="../assets/newGW/lcfk.png" alt="">
                </div>
                <div class="top2-title-center">2019年</div>
                <div class="top2-title-right">
                  <div>湖南省村级财务统一软件项目成功上线</div>
                  <div class="top2-title-righ-top">洪江市扶贫项目自己绩效管理系统成功上线</div>
                  <div class="top2-title-righ-top">与湖南财正会计师事务所签订战略合作</div>
                  <div class="top2-title-righ-top">与博纳检测认证签订战略合作</div>
                </div>
              </div>
              <div class="top2-title">
                <div class="top2-title-left">
                  <img src="../assets/newGW/lcfk.png" alt="">
                </div>
                <div class="top2-title-center">2020年</div>
                <div class="top2-title-right">
                  <div>中标湖南省村级财务统一软件运维服务</div>
                  <div class="top2-title-righ-top">永定区征拆业务综合管理系统项目成功上线</div>
                  <div class="top2-title-righ-top">通过ISO9001质量体系认证</div>
                </div>
              </div>
              <div class="top2-title">
                <div class="top2-title-left">
                  <img src="../assets/newGW/lcfk.png" alt="">
                </div>
                <div class="top2-title-center">2021年</div>
                <div class="top2-title-right">
                  <div>公司商标"四毅软件"注册成功</div>
                  <div class="top2-title-righ-top">用友核算云系统实施维护</div>
                  <div class="top2-title-righ-top">澧县银财直连系统三期开发</div>
                  <div class="top2-title-righ-top">短长租房业务管理平台系统项目成功上线</div>
                </div>
              </div>
              <div class="top2-title">
                <div class="top2-title-left">
                  <img src="../assets/newGW/lcfk.png" alt="">
                </div>
                <div class="top2-title-center">未来</div>
                <div class="top2-title-right">
                  <div>将继续秉承："汇聚公共管理智慧"的理念</div>
                  <div class="top2-title-righ-top">用信息化助推公共管理提升、业务创新,</div>
                  <div class="top2-title-righ-top">助创高效、开放、公正、廉洁的政府</div>
                </div>
              </div>
            </div>
          </div>
          <div class="main-right-table-box" v-if="select == '6'">
            <div class="main-right-table-box-center">
              <div><img src="../assets/newGW/qyzzx.png" alt=""></div>
            </div>
            <div class="main-right-table-box-top" style="margin-top:-20px">
              <div v-for="(item, index) in ZizeData" :key="index" class="top-dt-img">
                <img :src="baseUrl + 'Attachment/' + item.urlList" style="width:100%;height:100%">
              </div>
            </div>
          </div>
          <div class="main-right-table-box" v-if="select == '7'" style="margin:0">
            <div class="main-right-table-box-center">
              <div class="list">
                <div class="item">
                  <div class="img"><img src="../assets/newGW/lx-img1.png" alt=""></div>
                  <div class="text">
                    高新企业园区，宽敞办公厅
                  </div>
                </div>
                <div class="item">
                  <div class="img"><img src="../assets/newGW/lx-img2.png" alt=""></div>
                  <div class="text">
                    丰富的文化团建活动，张弛有度，快乐工作
                  </div>
                </div>
                <div class="item">
                  <div class="img"><img src="../assets/newGW/lx-img3.png" alt=""></div>
                  <div class="text">
                    培训机制完善，助你成长
                  </div>
                </div>
                <div class="item">
                  <div class="img"><img src="../assets/newGW/lx-img4.png" alt=""></div>
                  <div class="text">
                    7H工作制、健康体检、员工节日礼品、生日礼金
                  </div>
                </div>
                <div class="item">
                  <div class="img"><img src="../assets/newGW/lx-img5.png" alt=""></div>
                  <div class="text">
                    奖励机制多多，项目奖金、冲刺奖金、股权激励
                  </div>
                </div>
                <div class="item">
                  <div class="img"><img src="../assets/newGW/lx-img6.png" alt=""></div>
                  <div class="text">
                    带薪年假、病假、法定假日
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="select == '7'" style="margin-top:18px" class="main-right-table">
          <div class="main-right-table-title">
            <div><img src="../assets/newGW/fk.png" alt=""></div>
            <div class="table-title-wz">招聘岗位</div>
            <div><img src="../assets/newGW/fk.png" alt=""></div>
          </div>
          <div><img src="../assets/newGW/guanyuxt.png" alt=""></div>
          <div class="main-right-table-box" v-if="select == '7'" style="margin:0">
            <div class="main-right-table-box-center">
              <div class="zp-list">
                <div class="zp-item">
                  <div class="text">内控咨询项目经理</div>
                  <div class="img">
                    <el-button class="btn" :icon="isShow1?'el-icon-arrow-up':'el-icon-arrow-down'" type="danger" plain circle size="small" @click="isShow1 = !isShow1"></el-button>
                    <img src="../assets/newGW/zp-img.png" alt="">
                    <img v-if="isShow1" src="../assets/newGW/zp-img1.png" alt="">
                  </div>
                </div>
                <div class="zp-item">
                  <div class="text">产品经理</div>
                  <div class="img">
                    <el-button class="btn" :icon="isShow2?'el-icon-arrow-up':'el-icon-arrow-down'" type="danger" plain circle size="small" @click="isShow2 = !isShow2"></el-button>
                    <img src="../assets/newGW/zp-img.png" alt="">
                    <img v-if="isShow2" src="../assets/newGW/zp-img2.png" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Regularbox></Regularbox>
  </div>
</template>
<script>
import axios from "../axios/axios.js";
import { get } from '../axios/axios.js';
import Regularbox from './regularbox.vue';
export default {
  components: { Regularbox },
  data() {
    return {
      currentPage: 1,
      pagesize: 9,
      totalPages: 0,
      tableData: [],
      ZizeData: [],
      DataType: '0',
      titleData: '公司简介',
      select: '',
      scrollerHeight: '',
      activeIndex: '1',
      isShow1:false,
      isShow2:false
    };
  },
  created() {
    this.baseUrl = axios.configurl;
    if (this.$route.query.data == undefined) {
      this.activeIndex = '2'
      this.select = '2'
    } else {
      this.activeIndex = this.$route.query.data
      this.select = this.$route.query.data
      this.titleData = this.$route.query.name
    }
  },
  watch: {
    '$route'() {
      if (this.$route.query.data == undefined) {
        this.activeIndex = '2'
        this.select = '2'
      } else {
        this.activeIndex = this.$route.query.data
        this.select = this.$route.query.data
        this.titleData = this.$route.query.name
      }
    }
  },
  mounted() {
    this.getNewsIndustry();
    this.getZzdataList()
  },
  methods: {
    async getNewsIndustry() {
      await get('/api/PageQuery/GetFrontEndNewsTrends', { Title: '', Page: this.currentPage, Rows: this.pagesize, DataType: this.DataType }).then(res => {
        this.tableData = []
        for (let i of res.data.date) {
          this.tableData.push({ title: i.title, createTime: i.createTime, id: i.id })
        }
      })
    },
    //企业资质
    async getZzdataList() {
      await get("/api/PageQuery/GetQualificationFrontEnd",).then((res) => {
        this.ZizeData = res.data.list.splice(0, 16)
      });
    },
    handSelect(key) {
      this.select = key
      switch (key) {
        case '2':
          this.titleData = '公司简介'
          break;
        case '3':
          this.titleData = '使命与愿景'
          break;
        case '4':
          this.titleData = '团队管理'
          break;
        case '5':
          this.titleData = '发展历程'
          break;
        case '6':
          this.titleData = '企业资质',
            this.getZzdataList()
          break;
        case '7':
          this.titleData = '公司福利'
          break;
      }
    },
    handleCurrentChange() {
      console.log('213123')
    },
    handleSizeChange(size) {
      this.pagesize = size;
    },
  },
}
</script>

<style lang="scss" scoped>
.center {
  display: flex;
  align-items: center;
  justify-content: center;

  .newsContent-main {
    width: 1000px;
    margin-top: 20px;
    padding-bottom: 20px;
    display: flex;
    background-color: #FFFFFF;

    .main-left {
      width: 180px;
      background: #C70F1A;
      opacity: 1;
      border-radius: 0px;
      display: flex;
      flex-direction: column;

      .main-left-top {
        height: 69px;

        .main-left-top-title {
          margin: 20px 0px 11px 20px;
          text-align: left;

          .main-left-top-title-xw {
            width: 64px;
            height: 21px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 0px;
            color: #FFFFFF;
            opacity: 1;
          }

          .main-left-top-title-yw {
            margin-top: 2px;
            height: 15px;
            font-size: 11px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 0px;
            color: #FB9898;
            opacity: 1;
          }
        }
      }

      .main-left-top-title-xt {
        height: 1px;
        margin: 0 6px 0 7px;
        background: url("../assets/newGW/xwxt.png");
      }

      .main-left-bottom {
        margin-top: 10px;

        .el-menu-vertical-demo {
          text-align: left;
          font-size: 11px;
          font-family: Microsoft YaHei;
          line-height: 0px;
          color: #FFFFFF;
          opacity: 1;
          border-right: none;

          .main-left-bottom-title {
            width: 128px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .main-left-bottom-title:hover {
          transition: all 0.8s;
          transform: translateX(10px);
        }
      }
    }

    .main-right-table {
      width: 800px;
      opacity: 1;
      border-radius: 0px;
      margin-left: 20px;
      padding-top: 1px;
      background-color: #F5F5F5;
      .main-right-table-title {
        width: 125px;
        margin: 25px 0px 0px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .table-title-wz {
          height: 21px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          line-height: 15px;
          color: #000000;
          opacity: 1;
        }
      }

      .main-right-table-box-center {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        .list{
          width: 100%;
          padding: 10px 28px;
          .item{
            width: 237px;
            float: left;
            margin-right: 15px;
            margin-bottom: 15px;
            position: relative;
            top: 0;
            transition: all 0.5s ease-in;
            img{
              display: block;
            }

          }
          .item:nth-child(3n){
            margin-right: 0;
          }
          .item:hover{
            top: -10px;
          }
          .text{
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #000000;
            text-align: left;
            height: 37px;
            padding: 13px 10px;
            background-color: #fff;
          }
        }
        .zp-list{
          width: 100%;
          margin-top: 10px;
          padding: 10px 28px;
          .zp-item{
            margin-bottom: 30px;
            .text{
              font-size: 20px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #000000;
              margin: 0 0 27px 0;
              text-align: left;
            }
            .img{
              position: relative;
              .btn{
                position: absolute;
                top: 30px;
                right: 30px;
              }
              .el-button--danger.is-plain{
                background: transparent;
              }
              .el-button--danger.is-plain:hover,
              .el-button--danger.is-plain:focus{
                background: #F56C6C;
              }
            }
            img{
              display: block;
              margin: 20px auto 0;
            }
          }
        }
      }

      .main-right-table-box {
        margin: 0px 29px 5px 28px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .main-right-table-box-top {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-around;
          padding-bottom: 15px;
          padding-top: 10px;

          .top-dt-img {
            width: 164px;
            height: 223px;
            margin-top: 13px;
          }
        }

        .main-right-table-box-top2 {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          padding-bottom: 15px;

          .top2-title {
            display: flex;
            margin-top: 15px;


            .top2-title-left {
              margin-top: 2px;
            }

            .top2-title-center {
              width: 47px;
              font-size: 14px;
              color: #333333;
              margin-left: 19px;
            }

            .top2-title-right {
              margin-left: 23px;
              font-size: 14px;
              display: flex;
              flex-direction: column;
              text-align: left;

              .top2-title-righ-top {
                margin-top: 15px;
              }
            }
          }
        }

        .main-right-table-box-wz1 {
          font-size: 14px;
          font-family: Source Han Sans CN;
          line-height: 30px;
          color: #333333;
          opacity: 1;
          text-align: left;
          padding-bottom: 10px;

          .wz-one {
            font-size: 16px;
            font-family: Source Han Sans CN;
            line-height: 30px;
            color: #C70F1A;
            opacity: 1;
          }

          .wz-two {
            font-size: 14px;
            font-family: Source Han Sans CN;
            line-height: 30px;
            color: #333333;
            opacity: 1;
          }
        }

        .main-right-table-box-wz {
          text-indent: 2em;
          font-size: 14px;
          margin-top: 3px;
          font-family: Source Han Sans CN;
          line-height: 30px;
          color: #333333;
          opacity: 1;
          margin-left: 3px;
          text-align: left;
        }

        .cell-class-name {
          height: 35px;
          background: red;
          opacity: 1;
          text-align: center;
          font-size: 12px;
          font-family: Microsoft YaHei;
          line-height: 14px;
          color: #333333;
          opacity: 1
        }
      }
    }
  }
}



::v-deep .el-table__body {
  -webkit-border-vertical-spacing: 10px;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  width: 33px;
  height: 28px;
  font-size: 9px;
  margin-top: 2px;
  background: #C21F32;
  opacity: 1;
  border-radius: 2px;
}

::v-deep .el-table .el-table__cell {
  padding: 9px 0;
}

::v-deep .el-submenu .el-menu-item {
  min-width: 100px
}

//设置鼠标悬停时el-menu-item的样式
.el-menu-item:hover {
  background-image: url("../assets/newGW/navebg.png");
  background-size: 100% 100%;
}

::v-deep .el-menu-item.is-active {
  // 被点击以后字体颜色改变
  background-image: url("../assets/newGW/navebg.png");
  background-size: 100% 100%;
  color: #F5F5F5;
}

::v-deep .el-submenu__title {
  height: 44px;
  line-height: 37px;
}

::v-deep .el-submenu .el-menu-item {
  height: 35px;
  line-height: 35px;
}

::v-deep .el-submenu__title i {
  color: #FFFFFF
}
</style>