<template>
  <div class="newsContent-main">
    <div class="main-left">
      <div class="main-left-top">
        <div class="main-left-top-title">
          <div class="main-left-top-title-xw">新闻动态</div>
          <div class="main-left-top-title-yw">NEWS INFORMATION</div>
        </div>
      </div>
      <div class="main-left-top-title-xt"></div>
      <div class="main-left-bottom">
        <el-row>
          <el-col :span="24">
            <el-menu background-color="#C70F1A" class="el-menu-vertical-demo" @select="handSelect"
              :default-active="activeIndex" text-color="#FFFFFF">
              <el-menu-item index="1">
                <span>公司动态</span>
              </el-menu-item>
              <el-menu-item index="2">
                <span>行业新闻</span>
              </el-menu-item>
              <el-menu-item index="3">
                <span>政策聚焦</span>
              </el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="main-right-table" v-if="show">
      <div class="main-right-table-title">
        <div><img src="../assets/newGW/fk.png" alt=""></div>
        <div class="table-title-wz">{{ titleData }}</div>
        <div><img src="../assets/newGW/fk.png" alt=""></div>
      </div>
      <div class="main-right-table-box">
        <el-table :data="tableData" :cell-style="{ padding: '6px 0px 6px 0px', }" highlight-current-row
          :header-cell-style="{ background: '#DEDEDE', color: '#C21F32', 'text-align': 'center' }"
          style="font-size:13px;background: #F5F5F5;width:100%" @row-click="onRowClick">
          <el-table-column prop="" label="" width="20">
            <template slot-scope="scope">
              <div class="list-img">
                <img src="../assets/newGW/xwdtFk.png" alt="">
              </div>
              {{ scope.row.customerName }}
            </template>
          </el-table-column>
          <el-table-column prop="title" label="信息详情" align="left" width="550px">
          </el-table-column>
          <el-table-column prop="createTime" label="公布时间" align="center"></el-table-column>
        </el-table>
        <div class="paginationBox">
          <el-pagination :current-page="currentPage" :page-size="pagesize" background layout=" prev, pager, next, jumper"
            :total="totalPages" @current-change="handleCurrentChange" @size-change="handleSizeChange"
            style="background: #FFFFFF;height:35px;text-align:center;" />
        </div>
      </div>
    </div>
    <div class="main-right-table" v-if="showHtml">
      <div v-html="htmlData" class="main-right-table-content"></div>
    </div>
    <Regularbox></Regularbox>
  </div>
</template>
<script>
import { get } from '../axios/axios.js';
import moment from "moment";
import Regularbox from './regularbox.vue';
export default {
  components: { Regularbox },
  data() {
    return {
      newId: '',
      currentPage: 1,
      pagesize: 9,
      totalPages: 0,
      tableData: [],
      DataType: '1',
      queryID: '',
      titleData: '公司动态',
      show: true,
      showHtml: false,
      htmlData: '',
      activeIndex: '1',
    };
  },
  created() {
    if (this.$route.query.id !== undefined) {
      this.show = false;
      this.showHtml = true
      this.activeIndex = '1'
      this.queryID = this.$route.query.id
      this.GetFrontEndNews()
    } else {
      this.activeIndex = '1'
      this.DataType = this.$route.query.data
      this.activeIndex = this.$route.query.data
      this.GetFrontEndNewsTrends()
    }
  },
  mounted() {
    this.GetFrontEndNews();
  },
  watch: {
    '$route'() {
      if (this.$route.query.id !== undefined) {
        this.show = false;
        this.showHtml = true
        this.activeIndex = '1'
        this.queryID = this.$route.query.id
        this.GetFrontEndNews()
      } else {
        this.activeIndex = '1'
        this.DataType = this.$route.query.data
        this.activeIndex = this.$route.query.data
        this.titleData = this.$route.query.name
        this.currentPage = 1
        this.GetFrontEndNewsTrends()
      }
      if (this.$route.query.data == undefined && this.$route.query.name == undefined && this.$route.query.id == undefined) {
        this.DataType = '1'
        this.activeIndex = '1'
        this.titleData = '公司动态'
      }
    }
  },
  methods: {
    //时间格式化
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    async GetFrontEndNewsTrends() {
      await get('/api/PageQuery/GetFrontEndNewsTrends', { Title: '', Page: this.currentPage, Rows: this.pagesize, DataType: this.DataType }).then(res => {
        if (res.flag) {
          this.tableData = []
          this.totalPages = res.total
          for (let i of res.data.date) {
            this.tableData.push({ title: i.title, createTime: this.formatDate(i.time), id: i.id })
          }
        }
      })
    },
    async GetFrontEndNews() {
      await get('/api/PageQuery/GetFrontEndNews', { id: this.queryID }).then(res => {
        if (res.flag) {
          this.htmlData = res.data.content
        }
      })
    },
    handSelect(key) {
      this.DataType = key
      this.show = true
      this.showHtml = false
      if (key == '2') {
        this.titleData = '行业新闻'
        this.currentPage = 1
      } else if (key == '3') {
        this.titleData = '政策聚焦'
        this.currentPage = 1
      } else {
        this.titleData = '公司动态'
        this.currentPage = 1
      }
      this.GetFrontEndNewsTrends()
    },
    handleSizeChange(val) {
      this.pagesize = val;
      this.GetFrontEndNewsTrends();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.GetFrontEndNewsTrends()
    },
    onRowClick(row) {
      this.show = false
      this.showHtml = true
      get('/api/PageQuery/GetFrontEndNews', { id: row.id }).then(res => {
        if (res.flag) {
          this.htmlData = res.data.content
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.newsContent-main {
  width: 1000px;
  margin-top: 20px;
  padding-bottom: 20px;
  display: flex;
  background-color: #FFFFFF;

  .main-left {
    width: 180px;
    height: 577px;
    background: #C70F1A;
    opacity: 1;
    border-radius: 0px;
    display: flex;
    flex-direction: column;

    .main-left-top {
      height: 69px;

      .main-left-top-title {
        margin: 20px 0px 11px 20px;
        text-align: left;

        .main-left-top-title-xw {
          width: 64px;
          height: 21px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 0px;
          color: #FFFFFF;
          opacity: 1;
        }

        .main-left-top-title-yw {
          margin-top: 2px;
          height: 15px;
          font-size: 11px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 0px;
          color: #FB9898;
          opacity: 1;
        }
      }
    }

    .main-left-top-title-xt {
      height: 1px;
      margin: 0 6px 0 7px;
      background: url("../assets/newGW/xwxt.png");
    }

    .main-left-bottom {
      margin-top: 10px;

      .el-menu-vertical-demo {
        text-align: left;
        font-size: 11px;
        font-family: Microsoft YaHei;
        line-height: 0px;
        color: #FFFFFF;
        opacity: 1;
        border-right: none;
      }
    }
  }

  .main-right-table {
    width: 800px;
    background-color: #F5F5F5;
    opacity: 1;
    border-radius: 0px;
    margin-left: 20px;

    .main-right-table-title {
      width: 125px;
      margin: 25px 0px 19px 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .table-title-wz {
        width: 64px;
        height: 21px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 15px;
        color: #000000;
        opacity: 1;
      }
    }

    .main-right-table-box {
      margin: 15px 29px 0px 28px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .cell-class-name {
        height: 35px;
        background: red;
        opacity: 1;
        text-align: center;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 14px;
        color: #333333;
        opacity: 1
      }
    }

    .paginationBox {
      width: 100%;
      display: flex;
      background-color: #FFFFFF;
      align-items: center;
      justify-content: center;
    }
  }

  .main-right-table-content {
    padding: 20px;
  }
}

::v-deep .el-table__body {
  -webkit-border-vertical-spacing: 10px;
}

::v-deep .el-pagination {
  display: flex;
  align-items: center;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  width: 33px;
  height: 28px;
  font-size: 9px;
  background: #C21F32;
  opacity: 1;
  border-radius: 2px;
}

::v-deep .el-table .el-table__cell {
  padding: 9px 0;
  cursor: pointer;
}

//设置鼠标悬停时el-menu-item的样式
.el-menu-item:hover {
  background-image: url("../assets/newGW/navebg.png");
  background-size: 100% 100%;
}

::v-deep .el-menu-item.is-active {
  // 被点击以后字体颜色改变
  background-image: url("../assets/newGW/navebg.png");
  background-size: 100% 100%;
  color: #F5F5F5;
}
</style>