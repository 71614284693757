import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/Home.vue'
import MoreIndustry from '@/navigation/moreIndustry.vue'
import Map from '@/navigation/map.vue';
import ProductsNews from '@/navigation/productsNews.vue'
import ServeNews from '@/navigation/serveNews.vue'
import ServicePage from "@/navigation/servicePage.vue"
import solution from "@/navigation/solution.vue"
import Mainbusiness from "@/navigation/mainbusiness.vue"
import financialIntegration from "@/navigation/financialIntegration.vue"
import assetCloud from "@/navigation/assetCloud.vue"
import newsIndustry from "@/navigation/newsIndustry.vue"
import totalSearch from "@/navigation/totalSearch.vue"
import productCenter from "@/navigation/productCenter.vue"
import contact from "@/navigation/contact.vue"

Vue.use(VueRouter)

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)

// }

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '首页',
      keepAlive: true,
    }
  },

  {
    path: '/moreindustry',
    name: 'MoreIndustry',
    component: MoreIndustry,
    meta: {
      title: '所有动态'
    }
  },
  {
    path: '/newsIndustry',
    name: 'NewsIndustry',
    component: newsIndustry,
    meta: {
      title: '新闻动态动态'
    }
  },
  {
    path: '/map',
    name: 'Map',
    component: Map
  },
  {
    path: '/productsnews',
    name: 'ProductsNews',
    component: ProductsNews,
    meta: {
      title: '产品'
    }
  },
  {
    path: '/serve',
    name: 'ServeNews',
    component: ServeNews,
    meta: {
      title: '服务'
    }
  },
  {
    path: '/solution',
    name: 'Solution',
    component: solution,
    meta: {
      title: '解决方案',
      keepAlive: true,
    }
  },
  {

    path: '/servicePage',
    name: 'ServicePage',
    component: ServicePage,
    meta: {
      title: '公司服务'
    }
  },
  //主营业务弹框
  {
    path: '/mainbusiness',
    name: 'Mainbusiness',
    component: Mainbusiness,
    meta: {
      title: '主营业务'
    }
  },
  //政务一体化
  {
    path: '/financialIntegration',
    name: 'FinancialIntegration',
    component: financialIntegration,
    meta: {
      title: '政务一体化'
    }
  },
  //资产云
  {
    path: '/assetCloud',
    name: 'AssetCloud',
    component: assetCloud,
    meta: {
      title: '政务一体化'
    }
  },
  {
    path: '/totalSearch',
    name: 'TotalSearch',
    component: totalSearch,
    meta: {
      title: '全局搜索页面'
    }
  },
  {
    path: '/productCenter',
    name: 'ProductCenter',
    component: productCenter,
    meta: {
      title: '产品中心',
      keepAlive: true,
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: contact,
    meta: {
      title: '联系我们'
    }
  },

]


const router = new VueRouter({
  routes
})


export default router


// 解决Vue-Router升级导致的Uncaught(in promise) navigation guard问题
// push
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}




// replace
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
  // 路由跳转后，让页面回到顶部
  console.log(to, from, next);
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.pageYOffset = 0;
  next();
})

