<template>
  <div class="all-main">
    <div class="internally-main">
      <site-top class="site-top"></site-top>
      <div class="internally-buttom" v-if="name">
        <div class="internally-buttom-title">{{ title }}</div>
        <div v-if="index == '1'" class="internally-buttom-center">提高政府管理效能和服务水平，提升政务服务。
          助力数字政府建设</div>
        <div v-if="index == '2'" class="internally-buttom-center"> 全面梳理业务流程，查找经营管理合规风险点。
          建立预算闭环管理系统。</div>
        <di v-if="index == '3'" class="internally-buttom-center">加速数字化转型和运营创新，结合企业发展战略与先进管理理念。
          将经营活动全面数字化，并整合为利于管理、分析、决策的信息资源，从而提高企业经济效益、加强企业的“核心竞争力。</di>
        <div v-if="index == '4'" class="internally-buttom-center">
          为企业量身定做的一系列软件，可根据企业自身的业务和工作流程减少或者增加功能，提供安全、可靠、私有化的服务解决方案，全方位满足用户的信息化应用需求。</div>
        <div v-if="index == '5'" class="internally-buttom-center">目标与绩效一体化闭环管理平台，科学高效的绩效管理体系。
          企业的经营目标转化为可测量的标准。
          为企业经营决策和执行结果提供有效支持信息。</div>
        <div v-if="index == '6'" class="internally-buttom-center">
          基于人工智能的财务平台，通过构建基于商业智能的管理会计平台，企业可以获得贴合不同用户需求的多维度、立体化的数据信息，对管理者的决策过程提供智能化支撑。</div>
      </div>
      <div v-if="showConten" v-html="htmlData" class="center-box"></div>
      <site-foot :borderShow='true'></site-foot>
    </div>
  </div>
</template>

<script>
import SiteFoot from '../components/siteFoot.vue'
import siteTop from '../components/siteTop.vue'
import { get } from "../axios/axios.js";
export default {
  components: { siteTop, SiteFoot },
  data() {
    return {
      index: '',
      title: '',
      name: true,
      showConten: false,
      htmlData: ''
    }
  },
  created() {
    console.log(this.$route.query.dataname, this.$route.query.dataID);
    if (this.$route.query.dataname == '产品') {
      this.showConten = true
      this.name = false
    } else {
      this.showConten = false
      this.name = true
      this.index = this.$route.query.data
    }
    this.getNewsIndustry()
    this.title = this.$route.query.name
    // if (this._isMobile()) {
    //   //手机端
    //   document.documentElement.style.fontSize = document.documentElement.clientWidth / 10 + 'px';
    //   console.log(document.documentElement.style.fontSize);
    // } else {
    //   //pc端
    // }
  },
  methods: {
    async getNewsIndustry() {
      await get('/api/PageQuery/GetFrontProductServiceSelectChild', { id: this.$route.query.dataID }).then(res => {
        if (res.flag) {
          this.htmlData = res.data.content
        }
      })
    },
    //判断手机端还是PC端
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
  }

}
</script>

<style lang="scss" scoped>
.internally-main {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #fff;

  .center-box {
    width: 1000px;
    padding: 20px 0 20px 0;
  }

  .site-top {
    width: 100%;
  }

  .internally-top {
    width: 100%;
    height: 490px;

    img {
      width: 100%;
      height: 100%;

    }
  }

  .internally-buttom {
    height: 500px;
    width: 1000px;
    margin: 20px 0px 20px 0px;
    background-color: #F5F5F5;

    .internally-buttom-title {
      font-size: 24px;
      font-weight: 700;
      color: #000;
      margin: 20px 0px 20px 20px;
    }

    .internally-buttom-center {
      font-size: 16px;
      font-weight: 700;
      color: #000;
      text-align: left;
    }
  }

  .internally-title {
    width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 90px;
    margin-bottom: 100px;

    .new-text {
      margin-top: 60px;
      width: 412px;
      height: 95px;
      background: url('../assets/title-bg.jpg');
      background-size: 100% 100%;

      h1 {
        margin-top: 25px;
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2e2e2e;
      }

      span {
        display: block;
        margin-top: 15px;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2e2e2e;
      }
    }

    p {
      margin-top: 60px;
      font-size: 20px;
      font-weight: 400;
      line-height: 36px;
      color: #000000;
    }

    .internally-img {
      margin-top: 60px;
      width: 1130px;
      height: 520px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .internally-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;

    h1 {
      margin-top: 60px;
      font-size: 30px;
      font-weight: bold;
      color: #2e2e2e;
    }

    .text-box {
      width: 1200px;
      margin-top: 20px;
      text-align: left;
      margin-bottom: 75px;

      p {
        margin-top: 40px;

        .red-span {
          font-size: 20px;
          color: #eb1b1b;
          margin-right: 10px;
        }

        span {
          color: #000;
          line-height: 36px;
          font-size: 20px;
        }
      }
    }
  }

  .apply-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    margin-bottom: 100px;

    h1 {
      margin-top: 60px;
      font-size: 30px;
      font-weight: bold;
      color: #2e2e2e;
    }

    .apply-nav {
      margin-top: 60px;
      width: 1200px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .apply-nav-list {
        width: 232px;
        height: 65px;
        background: #e20000;
        border-radius: 5px;
        font-size: 28px;
        line-height: 65px;
        font-weight: 400;
        color: #ffffff;
      }
    }

    .apply-nav-text {
      margin-top: 20px;
      width: 1200px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .apply-nav-text-list {
        width: 232px;
        height: 279px;
        background: #ffffff;
        border: 1px solid #707070;
        border-radius: 5px;

        span {
          display: block;
          text-align: left;
          margin: 23px 16px 32px 16px;
          font-size: 22px;
          font-weight: 400;
          line-height: 39px;
          color: #090909;
          white-space: break-word;
        }
      }
    }
  }
}

// .mobile {
//   display: none;
// }

// @media screen and (max-width: 981px) {
//   .internally-main {
//     display: none;
//   }

//   .mobile {
//     display: block;

//     .mobile-banner {
//       width: 100%;
//       height: 4rem;

//       img {
//         width: 100%;
//         height: 100%;
//       }
//     }

//     .internally-title {
//       width: 100%;
//       padding: 0 0.3rem;
//       box-sizing: border-box;
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       margin-bottom: 0.5rem;

//       .new-text {
//         margin-top: 0.3rem;
//         width: 4rem;
//         height: 1rem;
//         background: url('../assets/mobile/title.jpg');
//         background-size: 100% 100%;

//         h1 {
//           font-size: 0.48rem;
//           font-family: Microsoft YaHei;
//           font-weight: bold;
//           color: #2e2e2e;
//         }

//         span {
//           display: block;
//           margin-top: 0.1rem;
//           font-size: 0.28rem;
//           font-family: Microsoft YaHei;
//           font-weight: 400;
//           color: #2e2e2e;
//         }
//       }

//       p {
//         text-indent: 2em;
//         text-align: left;
//         margin-top: 0.3rem;
//         font-size: 0.2rem;
//         font-weight: 400;
//         line-height: 0.45rem;
//         color: #000000;
//       }

//       .internally-img {
//         margin-top: 0.2rem;
//         width: 90%;
//         height: 4rem;

//         img {
//           width: 100%;
//           height: 100%;
//         }
//       }
//     }

//     .internally-text {
//       width: 100%;
//       padding: 0 0.3rem;
//       box-sizing: border-box;
//       display: flex;
//       flex-direction: column;
//       background-color: #f5f5f5;

//       h1 {
//         margin-top: 0.3rem;
//         font-size: 0.3rem;
//         font-weight: bold;
//         color: #2e2e2e;
//       }

//       .text-box {
//         width: 100%;
//         text-align: left;
//         margin-bottom: 0.5rem;

//         p {
//           font-size: 0.2rem;
//           margin-top: 0.4rem;

//           .red-span {
//             color: #eb1b1b;
//             margin-right: 0.1rem;
//           }

//           span {
//             color: #000;
//           }
//         }
//       }
//     }

//     .apply-box {
//       width: 100%;
//       padding: 0 0.3rem;
//       box-sizing: border-box;
//       display: flex;
//       flex-direction: column;
//       background-color: #fff;
//       margin-bottom: 1rem;

//       h1 {
//         margin-top: 0.4rem;
//         font-size: 0.3rem;
//         font-weight: bold;
//         color: #2e2e2e;
//       }

//       .apply-nav {
//         margin-top: 0.4rem;
//         width: 100%;
//         display: flex;
//         flex-direction: row;
//         justify-content: space-between;

//         .apply-nav-list {
//           width: 1.8rem;
//           height: 0.65rem;
//           background: #e20000;
//           border-radius: 0.05rem;
//           font-size: 0.28rem;
//           line-height: 0.65rem;
//           font-weight: 400;
//           color: #ffffff;
//         }
//       }

//       .apply-nav-text {
//         margin-top: 0.2rem;
//         width: 100%;
//         display: flex;
//         flex-direction: row;
//         justify-content: space-between;

//         .apply-nav-text-list {
//           width: 1.8rem;
//           height: 2.3rem;
//           background: #ffffff;
//           border: 1px solid #707070;
//           border-radius: 0.05rem;

//           span {
//             display: block;
//             text-align: left;
//             margin: 0.15rem;
//             font-size: 0.18rem;
//             font-weight: 400;
//             line-height: 0.3rem;
//             color: #090909;
//             white-space: break-word;
//           }
//         }
//       }
//     }
//   }
// }
</style>
