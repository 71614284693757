<template>
  <div class="centenr">
    <div class="newsContent-main">
      <div class="main-left">
        <div class="main-left-top">
          <div class="main-left-top-title">
            <div class="main-left-top-title-xw">解决方案</div>
            <div class="main-left-top-title-yw">ABOUT US</div>
          </div>
        </div>
        <div class="main-left-top-title-xt"></div>
        <div class="main-left-bottom">
          <el-row>
            <el-col :span="24">
              <el-menu background-color="#C70F1A" class="el-menu-vertical-demo" @select="handSelect" default-active="1"
                :collapse-transition="true" :unique-opened=true text-color="#FFFFFF" @open="onClickMenu">
                <el-submenu :index=String(index) v-for="(item, index) in tableData" :key="index">
                  <template slot="title">
                    <span>{{ item.name }}</span>
                  </template>
                  <el-menu-item-group v-for="(items, indexs) in threeSolution" :key="indexs">
                    <el-menu-item :index="String(items.id)">
                      <span class="main-left-bottom-title"
                        style="display:block;width:128px;margin-left:15px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;"
                        :title="items.title">{{ items.title }}</span>
                    </el-menu-item>
                  </el-menu-item-group>
                </el-submenu>
              </el-menu>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="main-right-table">
        <div v-html="dataHtml.content" class="main-right-table-center"> </div>
      </div>
      <Regularbox></Regularbox>
    </div>
  </div>
</template>
<script>
import axios from "../axios/axios.js";
import Regularbox from './regularbox.vue';
import { get } from '../axios/axios.js';

export default {
  components: { Regularbox },
  data() {
    return {
      currentPage: 1,
      pagesize: 99,
      totalPages: 0,
      tableData: [],
      DataType: '1',
      select: 22,
      dataHtml: [],
      isCollapse: true, //导航栏默认为展开
      showData: false,//有id就显示
      showTopNave: true, //导航栏默认
      groupid: 3,
      //解决方案三级标题
      solutiontype: 1,
      threeSolution: [],
    };
  },
  created() {
    if (this.$route.query.data !== undefined) {
      this.select = this.$route.query.data
    }
    this.baseUrl = axios.configurl;
  },
  mounted() {
    this.getNewsIndustry();
    this.getXqDataList();
  },
  watch: {
    '$route'() {
      this.select = this.$route.query.data
      this.getXqDataList()
    }
  },
  methods: {
    async getNewsIndustry() {
      //解决方案
      await get("/api/PageQuery/GetDictitem", { groupid: this.groupid, Page: 1, Rows: 99, }).then((res) => {
        if (res.flag) {
          this.tableData = []
          for (let item of res.data) {
            if (item.name !== '咨询服务') {
              this.tableData.push({ name: item.name, itemcode: item.itemcode })
            }
          }
        }
      });
    },
    async getXqDataList() {
      await get('/api/PageQuery/GetFrontSolutionSelect', { id: this.select }).then(res => {
        if (res.flag) {
          this.dataHtml = res.data
        }
      })
    },
    //解决三级标题方案初始化
    async getThreeList() {
      this.threeSolution = []
      get('/api/PageQuery/GetFrontSolution', { title: '', Page: 1, Rows: 99, solutiontype: this.solutiontype, content: '', }).then(res => {
        if (res.flag) {
          this.threeSolution = res.data
        }
      })
    },
    handSelect(key) {
      console.log(key);
      this.select = key
      this.getXqDataList()
    },
    onClickMenu(key) {
      this.solutiontype = Number(key) + 1
      this.getThreeList()
    }
  },
}
</script>

<style lang="scss" scoped>
.centenr {
  display: flex;
  align-items: center;
  justify-content: center;

  .newsContent-main {
    width: 62.5rem;
    margin-top: 1.25rem;
    padding-bottom: 1.25rem;
    display: flex;
    background-color: #FFFFFF;

    .main-left {
      width: 11.25rem;
      background: #C70F1A;
      opacity: 1;
      border-radius: 0rem;
      display: flex;
      flex-direction: column;
      min-height: 36.0625rem;

      .main-left-top {
        height: 4.3125rem;

        .main-left-top-title {
          margin: 1.25rem 0rem .6875rem 1.25rem;
          text-align: left;

          .main-left-top-title-xw {
            width: 4rem;
            height: 1.3125rem;
            font-size: 1rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 0rem;
            color: #FFFFFF;
            opacity: 1;
          }

          .main-left-top-title-yw {
            margin-top: .125rem;
            height: .9375rem;
            font-size: .6875rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 0rem;
            color: #FB9898;
            opacity: 1;
          }
        }
      }

      .main-left-top-title-xt {
        height: .0625rem;
        margin: 0 .375rem 0 .4375rem;
        background: url("../assets/newGW/xwxt.png");
      }

      .main-left-bottom {
        margin-top: .625rem;

        .el-menu-vertical-demo {
          text-align: left;
          font-size: .6875rem;
          font-family: Microsoft YaHei;
          line-height: 0rem;
          color: #FFFFFF;
          opacity: 1;
          border-right: none;
        }

        .main-left-bottom-title:hover {
          transition: all 0.8s;
          transform: translateX(10px);
        }
      }
    }

    .main-right-table {
      flex: 1;
      margin-left: 20px;
      background-color: #F5F5F5;

      .main-right-table-center {
        padding: 20px;
      }
    }

    @keyframes myMou1 {
      0% {
        margin-top: -12.5rem;
      }

      100% {
        margin-top: 0rem;
      }
    }
  }
}



::v-deep .el-table__body {
  -webkit-border-vertical-spacing: .625rem;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  width: 2.0625rem;
  height: 1.75rem;
  font-size: .5625rem;
  margin-top: .125rem;
  background: #C21F32;
  opacity: 1;
  border-radius: .125rem;
}

::v-deep .el-table .el-table__cell {
  padding: .5625rem 0;
}



::v-deep .el-submenu .el-menu-item {
  min-width: 4.25rem;
  font-size: .6875rem;
  height: 1.5625rem;
  line-height: 1.375rem;
  padding-left: 1.25rem !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

//设置鼠标悬停时el-menu-item的样式
.el-menu-item:hover {
  background-image: url("../assets/newGW/navebg.png");
  background-size: 100% 100%;
  animation: 0.5s;
}

::v-deep .el-menu-item.is-active {
  // 被点击以后字体颜色改变
  background-image: url("../assets/newGW/navebg.png");
  background-size: 100% 100%;
  color: #F5F5F5;
}

::v-deep .el-submenu__title {
  height: 44px;
  line-height: 37px;
}



::v-deep .el-submenu__title i {
  color: #FFFFFF
}
</style>