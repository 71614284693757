import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from './axios/axios.js'
import './assets/font_2616096_1pgk8xly1pg/iconfont.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import 'lib-flexible/flexible';
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)
// axios.defaults.baseURL = (process.env.NODE_ENV === 'development') ? 'http://dev.hnsy.com:5042/' : '/'
Vue.prototype.axios = axios;
Vue.use(ElementUI);
Vue.config.productionTip = false
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'CVn5uT6UaVCyODVE8KrE7E3b8qXG0jrF'
});
Vue.use(require('vue-moment'));

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
  preLoad: 1.3, //预加载高度
  // error: require('../src/assets/news.jpg'), //错误展示图片
  // loading: require('../src/assets/imgLoading.gif'), //加载等待图片
  attempt: 2 //尝试次数
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
