<template>
  <div class="more-main">
    <SiteTop></SiteTop>
    <div class="more-centen">
      <!-- banner图区域 -->
      <div class="more-banner">
        <img src="../assets/industry-dong.jpg" alt="">
      </div>
      <div class="breadcrumb-box">
        <div class="breadcrumb-icon"><img src="../assets/position.jpg" alt="">
        </div>
        <span>我的位置 <i class="el-icon-arrow-right" style="color:#c0c4cc"></i></span>
        <PositionNav></PositionNav>
      </div>
      <div class="all-news">
        <div class="all-news-title">
          <h1>所有动态</h1>
        </div>
        <div class="all-news-list" v-for="(item,index) in industryList" :key="index" @click="gotoContent(item.id)">
          <div class="all-news-left">
            <span :title="item.title">{{item.title}}</span>
          </div>
          <span>{{item.time}}</span>
        </div>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="currentPage" :page-sizes="[10, 15, 20, 30,40,50]" :page-size="15"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </div>

    </div>
    <SiteFoot></SiteFoot>
  </div>
</template>

<script>
import SiteFoot from "../components/siteFoot.vue";
import { get } from '../axios/axios.js';
import SiteTop from "../components/siteTop.vue";
import PositionNav from "./positionNav.vue";
export default {
  data() {
    return {
      newsId: 2,
      currentPage: 1,
      rows: 15,
      industryList: [],
      total: 0
    };
  },
  created() {
    this.getNewsList()
  },
  methods: {
    handleSizeChange(val) {
      this.rows = val
      this.currentPage = 1
      this.getNewsList()

    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getNewsList()
    },
    async getNewsList() {
      await get('/api/PageQuery/GetFrontEndNewsTrends', { DataType: this.newsId, Page: this.currentPage, Rows: this.rows }).then(res => {
        if (res.flag == true) {
          this.industryList = res.data.date
          this.total = res.total
        }
      })
    },
    gotoContent(id) {
      this.$router.push({
        name: 'NewsContent',
        query: { id: id },
      })
    },
  },
  components: { SiteFoot, SiteTop, PositionNav }
}
</script>

<style lang="scss" scoped>
.more-main {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  .more-centen {
    display: flex;
    flex-direction: column;
    align-items: center;
    .more-banner {
      width: 100%;
      height: 680px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .breadcrumb-box {
      width: 1160px;
      height: 58px;
      margin-top: 30px;
      padding-left: 40px;
      border-radius: 33px;
      background-color: #fff;
      display: flex;
      align-items: center;
      display: flex;
      flex-direction: row;
      .breadcrumb-icon {
        width: 17px;
        height: 19px;
        margin-right: 8px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      span {
        display: block;
        width: 120px;
        font-size: 17px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
      }
      .el-breadcrumb {
        margin-left: 10px;
        color: #000;
        font-size: 17px;
      }
      ::v-deep.el-breadcrumb__inner a,
      .el-breadcrumb__inner.is-link {
        font-weight: 400;
        font-size: 17px;
        text-decoration: none;
        transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        color: #ff0000;
      }
    }
    .all-news {
      margin-top: 30px;
      width: 1200px;
      margin-bottom: 100px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      .all-news-title {
        margin-top: 35px;
        width: 412px;
        height: 95px;
        background: url('../assets/title-bg.jpg');
        background-size: 100% 100%;
        h1 {
          margin-top: 25px;
          font-size: 30px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #2e2e2e;
        }
      }
      .all-news-list {
        width: 1060px;
        height: 80px;
        border-bottom: 1px solid #cccccc;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        .all-news-left {
          width: 660px;
          span {
            text-align: left;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #707070;
          }
          span:hover {
            color: #ff0000;
          }
        }
        span {
          display: block;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #b7b7b7;
        }
        span:hover {
          color: #ff0000;
        }
      }
      .pagination {
        margin-top: 60px;
        padding-bottom: 50px;
      }
    }
  }
}
</style>
