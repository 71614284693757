<template>
  <div>
    <!-- 本页面将整个顶部加上导航栏以及下拉显示的导航栏面板作为组件使用 -->
    <div class="site-mian">
      <!-- 版心盒子 -->
      <div class="site-heart">
        <!-- 头部标题盒子 -->
        <div class="xxx-box">
          <!-- 下方导航区域盒子-->
          <div class="top-navigation" @mouseenter="boxShow">
            <!-- logo盒子区域 -->
            <div class="top-logo">
              <img src="../assets/newGW/newLogo.png" alt="">
            </div>
            <!-- 切换导航区域盒子 -->
            <div class="top-handoff">
              <div class="top-handoff-txt">
                <!-- active -->
                <div class="text" :class="{ active: currentChange == index }" v-for="(item, index) in items" :key="index"
                  @mouseenter="onClickItem(index)" @mouseleave="handleItem" @click="goHome(index)">
                  {{ item }}
                  <div :class="showNav === index ? 'red-line' : ''"></div>
                  <div class="nav-box" @click.stop>
                    <!-- 解决方案 -->
                    <div v-if="index == 1" :class="showNav == 1 ? 'top-nav-box' : 'topShow'" @mouseleave="boxShow">
                      <div :class="showNav == 1 ? 'top-detail' : 'boxShow'">
                        <div class="dtop-detail-hope">
                          <div class="dtop-detail-hope-left">
                            <div class="dtop-detail-hope-left-title" v-for="(items, index) in GovernmentInformation" :key="index">
                              <div class="dtop-detail-hope-left-title-span" @click="SolutionSeach(items.itemcode, index)"
                                :class="{ active: currentTwoChange == index }">{{ items.name }}
                              </div>
                            </div>
                          </div>
                          <div class="dtop-detail-hope-right">
                            <div class="hope-buttom-wz" v-for="(item, index) in threeSolution" :key="index">
                              <div class="hope-buttom-wz-span" @click="threeSolveClick(item.id)">
                                <span class="main-left-bottom-title"
                                  style="display:block;width:104px;margin-left:15px;overflow:hidden;" :title=item.title>{{
                                    item.title }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 产品中心 -->
                    <div v-if="index == 2" :class="showNav == 2 ? 'top-nav-box4' : 'topShow'" @mouseleave="boxShow">
                      <div :class="showNav == 2 ? 'top-detail' : 'boxShow'">
                        <div class="dtop-detail-hope">
                          <div class="dtop-detail-hope-left">
                            <div class="dtop-detail-hope-left-title">
                              <div class="dtop-detail-hope-left-title-span" @click="onClickCp(1, '1')"
                                :class="{ active: currentThreeChange == '1' }">产品系列</div>
                            </div>
                            <div class="dtop-detail-hope-left-title">
                              <div class="dtop-detail-hope-left-title-span" @click="onClickCp(2, '2')"
                                :class="{ active: currentThreeChange == '2' }">主营业务</div>
                            </div>
                          </div>
                          <div class="dtop-detail-hope-right">
                            <div class="hope-buttom-wz" v-for="(item, index) in CpList" :key="index">
                              <div class="hope-buttom-wz-span" @click="onClickProduct(item.id, index)">
                                <span class="main-left-bottom-title"
                                  style="display:block;width:104px;margin-left:15px;overflow:hidden;" :title=item.title>{{
                                    item.title }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="index == 3" :class="showNav == 3 ? 'top-nav-box1' : 'topShow'" @mouseleave="boxShow">
                      <div :class="showNav == 3 ? 'top-detail' : 'boxShow'">
                        <div class="dtop-detail-hope">
                          <div class="hope-buttom">
                            <div class="hope-buttom-wz" @click="goNews('1', '公司动态')">公司动态</div>
                            <div class="hope-buttom-wz" @click="goNews('2', '行业新闻')">行业新闻</div>
                            <div class="hope-buttom-wz" @click="goNews('3', '政策聚焦')">政策聚焦</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="index == 4" :class="showNav == 4 ? 'top-nav-box3' : 'topShow'" @mouseleave="boxShow">
                      <div :class="showNav == 4 ? 'top-detail' : 'boxShow'">
                        <div class="dtop-detail-hope">
                          <div class="dtop-detail-hope-left">
                            <div class="dtop-detail-hope-left-title" v-for="(items, index) in advisoryService" :key="index">
                              <div class="dtop-detail-hope-left-title-span" @click="SolutionSeach(items.itemcode, index)"
                                :class="{ active: currentTwoChange == index }">{{ items.name }}
                              </div>
                            </div>
                          </div>
                          <div class="dtop-detail-hope-right">
                            <div class="hope-buttom-wz" v-for="(item, index) in threeSolution" :key="index">
                              <div class="hope-buttom-wz-span" @click="threeServiceClick(item.id)">
                                <span class="main-left-bottom-title"
                                  style="display:block;width:104px;margin-left:15px;overflow:hidden;" :title=item.title>{{
                                    item.title }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="index == 5" :class="showNav == 5 ? 'top-nav-box1' : 'topShow'" @mouseleave="boxShow">
                      <div :class="showNav == 5 ? 'top-detail' : 'boxShow'">
                        <div class="dtop-detail-hope">
                          <div class="hope-buttom">
                            <div class="hope-buttom-wz" @click="goAboutus('2', '公司简介')">公司简介</div>
                            <div class="hope-buttom-wz" @click="goAboutus('5', '发展历程')">发展历程</div>
                            <div class="hope-buttom-wz" @click="goAboutus('6', '企业资质')">企业资质</div>
                            <div class="hope-buttom-wz" @click="goAboutus('7', '加入我们')">加入我们</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="home-banner" v-if="!$route.path.startsWith('/servicePage')">
        <el-carousel :interval="10000" class="carousel-height" ref="carousel1">
          <el-carousel-item v-for="(item, index) in bannerImg" :key="index">
            <img v-lazy="baseUrl + 'Attachment/' + item.url" alt="" class="carousel-img" />
          </el-carousel-item>
        </el-carousel>
        <div class="home-banner-bottom">
          <div class="home-banner-bottom-left">
            <div class="home-banner-bottom-left-img">
              <div class="home-banner-bottom-left-img-box">
                <img src="../assets/newGW/lb.png" alt="" style="width:100%;height:100%">
              </div>
              <div class="home-banner-bottom-left-img-wz">最新咨询</div>
            </div>
            <div class="gdlist">
              <vue-seamless-scroll :data="newData" :class-option="optionHover" class="seamless-warp">
                <ul class="tr-cont" v-for="(item, index) in newData" :key="index">
                  <li>{{ item.homepageContent }}</li>
                </ul>
              </vue-seamless-scroll>
            </div>
          </div>
          <div class="home-banner-bottom-seach">
            <el-input v-model="yanzhengma" placeholder="请输入你想要查询的内容">
              <template #suffix>
                <el-button type="primary" class="el-input__icon el-icon-search" @click="seach()">
                  搜索</el-button>
              </template>
            </el-input>
          </div>
        </div>
      </div>
      <div class="home-banner-contact" v-else>
        <img src="../assets/newGW/lxwmnew.png" alt="" />
      </div>
    </div>
    <div class="mobile">
      <!-- 版心盒子 -->
      <div class="site-heart">
        <!-- 头部标题盒子 -->
        <div class="xxx-box">
          <!-- 下方导航区域盒子-->
          <div class="top-navigation" @mouseenter="boxShow">
            <!-- logo盒子区域 -->
            <div class="top-logo">
              <img src="../assets/newGW/newLogo.png" alt="">
            </div>
            <!-- 切换导航区域盒子 -->
            <div class="top-handoff">
              <div class="top-handoff-txt">
                <!-- active -->
                <div class="text" :class="{ active: currentChange == index }" v-for="(item, index) in items" :key="index"
                  @mouseenter="onClickItem(index)" @mouseleave="handleItem" @click="goHome(index)">
                  {{ item }}
                  <div :class="showNav === index ? 'red-line' : ''"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nav-box">
          <!-- 解决方案 -->
          <div :class="showNav == 1 ? 'top-nav-box' : 'topShow'" @mouseleave="boxShow">
            <div :class="showNav == 1 ? 'top-detail' : 'boxShow'">
              <div class="dtop-detail-hope">
                <div class="dtop-detail-hope-left">
                  <div class="dtop-detail-hope-left-title" v-for="(items, index) in GovernmentInformation" :key="index">
                    <div class="dtop-detail-hope-left-title-span" @click="SolutionSeach(items.itemcode, index)"
                      :class="{ active: currentTwoChange == index }">{{ items.name }}
                    </div>
                  </div>
                </div>
                <div class="dtop-detail-hope-right">
                  <div class="hope-buttom-wz" v-for="(item, index) in threeSolution" :key="index">
                    <div class="hope-buttom-wz-span" @click="threeSolveClick(item.id)">
                      <span class="main-left-bottom-title"
                        style="display:block;width:104px;margin-left:15px;overflow:hidden;" :title=item.title>{{
                          item.title }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 产品中心 -->
          <div :class="showNav == 2 ? 'top-nav-box4' : 'topShow'" style="margin-left:768px;" @mouseleave="boxShow">
            <div :class="showNav == 2 ? 'top-detail' : 'boxShow'">
              <div class="dtop-detail-hope">
                <div class="dtop-detail-hope-left">
                  <div class="dtop-detail-hope-left-title">
                    <div class="dtop-detail-hope-left-title-span" @click="onClickCp(1, '1')"
                      :class="{ active: currentThreeChange == '1' }">产品系列</div>
                  </div>
                  <div class="dtop-detail-hope-left-title">
                    <div class="dtop-detail-hope-left-title-span" @click="onClickCp(2, '2')"
                      :class="{ active: currentThreeChange == '2' }">主营业务</div>
                  </div>
                </div>
                <div class="dtop-detail-hope-right">
                  <div class="hope-buttom-wz" v-for="(item, index) in CpList" :key="index">
                    <div class="hope-buttom-wz-span" @click="onClickProduct(item.id, index)">
                      <span class="main-left-bottom-title"
                        style="display:block;width:104px;margin-left:15px;overflow:hidden;" :title=item.title>{{
                          item.title }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="showNav == 3 ? 'top-nav-box1' : 'topShow'" @mouseleave="boxShow">
            <div :class="showNav == 3 ? 'top-detail' : 'boxShow'">
              <div class="dtop-detail-hope">
                <div class="hope-buttom">
                  <div class="hope-buttom-wz" @click="goNews('1', '公司动态')">公司动态</div>
                  <div class="hope-buttom-wz" @click="goNews('2', '行业新闻')">行业新闻</div>
                  <div class="hope-buttom-wz" @click="goNews('3', '政策聚焦')">政策聚焦</div>
                </div>
              </div>
            </div>
          </div>
          <div :class="showNav == 4 ? 'top-nav-box3' : 'topShow'" style="margin-left:932px;" @mouseleave="boxShow">
            <div :class="showNav == 4 ? 'top-detail' : 'boxShow'">
              <div class="dtop-detail-hope">
                <div class="dtop-detail-hope-left">
                  <div class="dtop-detail-hope-left-title" v-for="(items, index) in advisoryService" :key="index">
                    <div class="dtop-detail-hope-left-title-span" @click="SolutionSeach(items.itemcode, index)"
                      :class="{ active: currentTwoChange == index }">{{ items.name }}
                    </div>
                  </div>
                </div>
                <div class="dtop-detail-hope-right">
                  <div class="hope-buttom-wz" v-for="(item, index) in threeSolution" :key="index">
                    <div class="hope-buttom-wz-span" @click="threeServiceClick(item.id)">
                      <span class="main-left-bottom-title"
                        style="display:block;width:104px;margin-left:15px;overflow:hidden;" :title=item.title>{{
                          item.title }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="showNav == 5 ? 'top-nav-box1' : 'topShow'" @mouseleave="boxShow" style="margin-left:805px">
            <div :class="showNav == 5 ? 'top-detail' : 'boxShow'">
              <div class="dtop-detail-hope">
                <div class="hope-buttom">
                  <div class="hope-buttom-wz" @click="goAboutus('2', '公司简介')">公司简介</div>
                  <div class="hope-buttom-wz" @click="goAboutus('5', '发展历程')">发展历程</div>
                  <div class="hope-buttom-wz" @click="goAboutus('6', '企业资质')">企业资质</div>
                  <div class="hope-buttom-wz" @click="goAboutus('7', '加入我们')">加入我们</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="home-banner" v-if="!$route.path.startsWith('/servicePage')">
        <el-carousel :interval="10000" class="carousel-height" ref="carousel1">
          <el-carousel-item v-for="(item, index) in bannerImg" :key="index">
            <img v-lazy="baseUrl + 'Attachment/' + item.url" alt="" class="carousel-img" />
          </el-carousel-item>
        </el-carousel>
        <div class="home-banner-bottom">
          <div class="home-banner-bottom-left">
            <div class="home-banner-bottom-left-img">
              <div class="home-banner-bottom-left-img-box">
                <img src="../assets/newGW/lb.png" alt="" style="width:100%;height:100%">
              </div>
              <div class="home-banner-bottom-left-img-wz">最新咨询</div>
            </div>
            <div class="gdlist">
              <vue-seamless-scroll :data="newData" :class-option="optionHover" class="seamless-warp">
                <ul class="tr-cont" v-for="(item, index) in newData" :key="index">
                  <li>{{ item.homepageContent }}</li>
                </ul>
              </vue-seamless-scroll>
            </div>
          </div>
          <div class="home-banner-bottom-seach">
            <el-input v-model="yanzhengma" placeholder="请输入你想要查询的内容">
              <template #suffix>
                <el-button type="primary" class="el-input__icon el-icon-search" @click="seach()">
                  搜索</el-button>
              </template>
            </el-input>
          </div>
        </div>
      </div>
      <div class="home-banner-contact" v-else>
        <img src="../assets/newGW/lxwmnew.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import router from "../router"
import axios from '../axios/axios.js'
import { get, post } from '../axios/axios.js'
import { Message } from 'element-ui'
// import Map from "../navigation/map.vue"

export default {
  data() {
    return {
      // 判断当前是否为关于我们页面
      aShow: false,
      callShow: false,
      inputSearch: "",
      showNav: 0,
      //导航
      currentPage: 1,
      pagesize: 99,
      totalPages: 0,
      DataType: '1',
      iconShow: false,
      detailIndex: 0,
      serveIndex: 0,
      baseUrl: '',
      dataHtml: [],
      affairShow: false,
      bannerImg: [],
      groupid: 3,
      //解决方案
      Solution: [],
      //咨询服务
      advisoryService: [],
      //企业信息化
      GovernmentInformation: [],
      //解决方案三级标题
      solutiontype: 2,
      threeSolution: [],
      //产品
      CpList: [],
      yanzhengma: '',
      //新闻
      newData: [],
      //鼠标当前移入
      currentChange: -1,
      //导航栏二级左侧列表
      currentTwoChange: '0',
      //导航栏三级左侧列表
      currentThreeChange: '1',
      nameData: '产品系列',
      idData: '1',
      SolutionIdData: '1',
      // 首页导航栏数据
      items: ["首页", "解决方案", "产品中心", "新闻动态", "咨询服务", "关于我们",],
    };
  },
  // 如果当前路由上mission则显示a标签
  created() {
    this.baseUrl = axios.configurl
    if (this.$route.path == "/mission") {
      this.aShow = true;
    }
    if (this.$route.path == "/") {
      this.callShow = true
    }
    // if (this._isMobile()) {
    //   //手机端
    //   document.documentElement.style.fontSize = document.documentElement.clientWidth / 10 + 'px';
    //   console.log(document.documentElement.style.fontSize);
    // } else {
    //   //pc端
    // }
  },
  mounted() {
    this.getSolution();
    this.getLblist()
    this.getNewlist()
    this.getThreeList()
    this.getCpList()
  },

  computed: {
    optionHover() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 20, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    //轮播图
    async getLblist() {
      await post("/api/PageQuery/GetFrontHomepagePictures",).then((res) => {
        if (res.flag) {
          this.bannerImg = res.data
          // this.$refs.carousel1.setActiveItem(0)
        } else {
          Message.error("数据加载失败");
        }
      });
    },
    //解决方案
    async getSolution() {
      await get("/api/PageQuery/GetDictitem", { groupid: this.groupid, Page: 1, Rows: 99, }).then((res) => {
        if (res.flag) {
          this.Solution = res.data
        } else {
          Message.error("数据加载失败");
        }
      });
    },
    //新闻动态
    async getNewlist() {
      await get("/api/PageQuery/GetFrontEndNewsTrends", { DataType: 2, Page: 1, Rows: 99, }).then((res) => {
        if (res.flag) {
          this.newData = res.data.date
        } else {
          Message.error("数据加载失败");
        }
      });
    },
    //解决方案三级标题方案初始化
    async getThreeList() {
      get('/api/PageQuery/GetFrontSolution', { title: '', Page: 1, Rows: 99, solutiontype: this.solutiontype, content: '', }).then(res => {
        if (res.flag) {
          this.threeSolution = res.data
        } else {
          Message.error("数据加载失败");
        }
      })
    },
    //产品中心
    async getCpList() {
      this.CpList = []
      get('/api/PageQuery/GetFrontProductServiceChild', { title: '', Page: 1, Rows: 99, ServiceType: 0, content: '', }).then(res => {
        if (res.flag) {
          this.CpList = res.data.gettProductService
        } else {
          Message.error("数据加载失败");
        }
      })
    },
    //主营业务
    async GetFrontMainBusiness() {
      this.CpList = []
      get('/api/PageQuery/GetFrontMainBusiness', { title: '', Page: 1, Rows: 99, mainBusinessType: 0, content: '', }).then(res => {
        if (res.flag) {
          this.CpList = res.data
        } else {
          Message.error("数据加载失败");
        }
      })
    },
    // //判断手机端还是PC端
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    // 鼠标移入导航栏选项区域
    onClickItem(i) {
      this.showNav = i;
      this.currentChange = i
      if (i == 2) {
        this.groupid = 2
      } else if (i == 1) {
        this.groupid = 3
        this.solutiontype = 2
        this.getSolution()
        this.getThreeList()
        this.GovernmentInformation = []
        for (let item of this.Solution) {
          if (item.name !== '咨询服务') {
            this.GovernmentInformation.push({ name: item.name, itemcode: item.itemcode })
          }
        }
      } else if (i == 4) {
        this.groupid = 3
        this.getSolution()
        this.solutiontype = 1
        this.getThreeList()
        this.advisoryService = []
        for (let item of this.Solution) {
          if (item.name == '咨询服务') {
            this.advisoryService.push({ name: item.name, itemcode: item.itemcode })
          }
        }
      }
    },
    // 鼠标离开导航栏选项区域
    handleItem() { },
    // 鼠标离开事件
    boxShow() {
      this.showNav = 0;
      this.currentChange = -1
    },
    // 点击跳转
    goHome(i) {
      if (i == 0) {
        router.push({
          name: "Home"
        });
      } else if (i == 3) {
        router.push({
          name: "NewsIndustry"
        });
      }
      else if (i == 5) {
        router.push({
          name: "ServicePage"
        });
      } else if (i == 1) {
        router.push({
          name: "Solution"
        });
      } else if (i == 4) {
        router.push({
          name: "ProductsNews"
        });
      } else if (i == 6) {
        router.push({
          name: "Contact"
        });
      }
    },
    //     else if(i == 2) {
    //   router.push({
    //     name: "ProductCenter"
    //   });
    // }
    // 去地图页面
    gotoMap() {
      router.push({
        name: "Map"
      });
    },
    //新闻下拉框跳转
    goNews(id, name) {
      router.push({
        name: "NewsIndustry",
        query: { data: id, name: name }
      });
    },
    //解决方案下拉框跳转
    solveClick(e) {
      router.push({
        name: "Solution",
        query: { data: e }
      });
    },
    //咨询服务跳转
    goconsulting() {
      router.push({
        name: "ProductsNews"
      });
    },
    //产品跳转
    goAssetCloud(id) {
      router.push({
        name: "AssetCloud",
        query: { id: id }
      });
    },
    //关于我们跳转
    goAboutus(index, name) {
      router.push({
        name: "ServicePage",
        query: { data: index, name: name }
      });
    },
    //全局搜索跳转
    async seach() {
      router.push({
        name: "TotalSearch",
        query: { centenr: this.yanzhengma }
      });
    },
    //解决方案查询
    SolutionSeach(id, index) {
      this.currentTwoChange = index
      this.solutiontype = id
      this.getThreeList()
    },
    //解决方案与产品系列鼠标移入
    onClcikTitle(id) {
      console.log(id)

    },
    //解决方案三级标题跳转
    threeSolveClick(id) {
      router.push({
        name: "Solution",
        query: { data: id }
      });
    },
    //咨询服务三级标题调转
    threeServiceClick(id) {
      router.push({
        name: "ProductsNews",
        query: { data: id }
      });
    },
    //产品中心头部切换
    onClickCp(id, index) {
      this.currentThreeChange = index
      this.idData = index
      if (id == 1) {
        this.getCpList()
        this.nameData = '产品系列'
      } else if (id == 2) {
        this.GetFrontMainBusiness()
        this.nameData = '主营业务'
      }
    },
    //产品中心点击跳转
    onClickProduct(id, index) {
      this.$emit('changeNavSubItemIndex', { parentId: this.idData, childrenId: index })
      router.push({
        name: "ProductCenter",
        query: { data: id, name: this.nameData, parentId: this.idData }
      });
    },
  },

  // components: { Map }
}
</script>

<style lang="scss" scoped>
.curson {
  cursor: pointer !important;
}

.boxShow {
  display: none;
}

.red-line {
  position: absolute;
  bottom: 0;
  width: 0px;
  height: 0px;
  border-bottom: .375rem solid #fff;
  border-left: .375rem solid transparent;
  /*transparent 表示透明*/
  border-right: .375rem solid transparent;
}

.title-click {
  width: 100%;
  height: 3.125rem;
  color: #ff0000 !important;
  background-image: linear-gradient(to right, #ffc3c3, #fffbfb, #ffb8b8);
}

.site-mian {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .site-heart {
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .xxx-box {
      // background-image: url('../assets/top.jpg.gif');
      // background-size: 100% 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .top-navigation {
        background-size: 100%;
        height: 5.6875rem;
        width: 75rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .top-srarch {
          position: relative;

          span {
            position: absolute;
            display: block;
            top: .3125rem;
            right: .625rem;

            img {
              width: 3.125rem;
              height: 2.0625rem;
            }
          }
        }
      }

      .top-handoff {
        height: 91px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 50px;
        padding-bottom: 10px;

        .top-handoff-txt {
          margin-left: 346px;
          height: 100%;
          width: 1200px;
          text-align: center;
          display: flex;
          flex-direction: row;

          >.text {
            width: 91px;
            height: 100%;
            font-size: 13px;
            font-weight: 400;
            color: #646464;
            display: flex;
            cursor: pointer;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
          }

          >.active {
            background: #BF1A20;
            opacity: 1;
            border-radius: 0px;
            color: #FFFFFF;
          }
        }
      }
    }

    .nav-box {
      position: absolute;
      left: 0;
      top: 96px;
      display: flex;
      background-color: #fff;
      justify-content: center;
      .top-nav-box {
        min-width: 550px;
        height: 199px;
        // position: absolute;
        // margin-left: 535px;
        box-shadow: 0 2px 2px 2px rgba($color: #000000, $alpha: 0.16);
        background-color: #FFFFFF;
        display: flex;
        justify-content: center;

        .top-detail {
          width: 100%;
          display: flex;
          flex-direction: row;
          cursor: pointer;
          position: absolute;
          overflow: hidden;
          background-color: #FFFFFF;
          height: 199px;

          .dtop-detail-hope {
            text-align: left;
            display: flex;
            color: #FFFFFF;

            .dtop-detail-hope-left {
              width: 91px;
              min-height: 265px;
              background: #BCBCBC;

              .dtop-detail-hope-left-title {
                display: flex;
                flex-direction: column;
                align-items: center;

                .dtop-detail-hope-left-title-span {
                  font-size: 13px;
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  line-height: 0px;
                  opacity: 1;
                  height: 13px;
                  margin-top: 20px;
                }
              }

              .active {
                color: #C70F1A;
              }
            }

            .dtop-detail-hope-right {
              flex: 1;
              margin: 0px 0px 37px 0px;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              flex-direction: column;

              .hope-buttom-wz {
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 118px;
                height: 13px;
                margin-top: 20px;
                height: 12px;
                font-size: 13px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 12px;
                color: #646464;
                opacity: 1;
                margin-left: 8px;
              }

              .hope-buttom-wz :hover {
                color: #C70F1A;
              }
            }
          }
        }
      }

      .top-nav-box1 {
        width: 90px;
        // position: absolute;
        // margin-left: 440px;
        max-height: 266px;
        box-shadow: 0 2px 2px 2px rgba($color: #000000, $alpha: 0.16);
        background: #BCBCBC;
        display: flex;
        justify-content: center;

        .top-detail {
          cursor: pointer;
          max-height: 266px;

          .hope-buttom {
            display: flex;
            flex-direction: column;
            padding-bottom: 10px;
          }

          .hope-buttom-wz {
            width: 130px;
            height: 17px;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 0px;
            color: #FFFFFF;
            margin-top: 20px;
            opacity: 1;
          }

          .hope-buttom :hover {
            color: #C70F1A;
          }
        }
      }

      .top-nav-box3 {
        min-width: 400px;
        height: 199px;
        // position: absolute;
        // margin-left: 535px;
        box-shadow: 0 2px 2px 2px rgba($color: #000000, $alpha: 0.16);
        background-color: #FFFFFF;
        display: flex;
        justify-content: center;

        .top-detail {
          width: 100%;
          display: flex;
          flex-direction: row;
          cursor: pointer;
          position: absolute;
          overflow: hidden;
          background-color: #FFFFFF;
          height: 199px;

          .dtop-detail-hope {
            text-align: left;
            display: flex;
            color: #FFFFFF;

            .dtop-detail-hope-left {
              width: 91px;
              min-height: 265px;
              background: #BCBCBC;

              .dtop-detail-hope-left-title {
                display: flex;
                flex-direction: column;
                align-items: center;

                .dtop-detail-hope-left-title-span {
                  font-size: 13px;
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  line-height: 0px;
                  opacity: 1;
                  height: 13px;
                  margin-top: 20px;
                }
              }

              .active {
                color: #C70F1A;
              }
            }

            .dtop-detail-hope-right {
              flex: 1;
              margin: 0px 0px 37px 0px;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              flex-direction: column;

              .hope-buttom-wz {
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 113px;
                height: 13px;
                margin-top: 20px;
                height: 12px;
                font-size: 13px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 12px;
                color: #646464;
                opacity: 1;
                margin-left: 8px;
              }

              .hope-buttom-wz :hover {
                color: #C70F1A;
              }
            }
          }

          .content {
            padding-top: 30px;
            margin-left: 30px;
            height: 100%;
            width: 100%;
            position: relative;

            .aShowimg {
              display: flex;
              flex-direction: row;

              img {
                margin-top: 5px;
                width: 27px;
                height: 22px;
              }

              h1 {
                margin-left: 5px;
              }
            }

            .aShowimg:hover {
              h1 {
                color: #ff0000;
              }
            }

            .content-title {
              display: flex;
              margin-left: 120px;
              margin-right: 150px;
              flex-direction: row;
              justify-content: space-between;

              a {
                font-size: 23px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #000000;
                text-decoration: none;
              }

              h1 {
                font-size: 23px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #000000;
              }
            }

            .content-content {
              margin-top: 60px;
              margin-left: 30px;
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: row;

              .new {
                position: relative;
                text-align: center;
                padding-left: 80px;

                h2 {
                  font-size: 21px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #000000;
                }

                .new-title:hover {
                  h2 {
                    color: #ff0000;
                  }
                }

                .news {
                  width: 300px;
                  margin-top: 25px;
                  text-align: left;

                  .new-time {
                    display: flex;
                    flex-direction: row;

                    .red-time-box {
                      padding-top: 2px;

                      span {
                        font-size: 16px;
                      }

                      .red-time {
                        display: block;
                        margin-top: -8px;
                        width: 8px;
                        height: 4px;
                        font-size: 16px;
                        background-color: #ff7777;
                      }
                    }

                    h3 {
                      margin-left: 7px;
                      font-size: 16px;
                      font-family: SimSun;
                      font-weight: 400;
                      color: #000000;
                      overflow: hidden; //文本溢出隐藏
                      text-overflow: ellipsis; //文本溢出显示省略号
                      white-space: nowrap; //不换行
                    }
                  }

                  .new-time:hover {
                    h3 {
                      color: #ff0000;
                    }
                  }

                  .time {
                    display: block;
                    font-size: 15px;
                    margin-top: 10px;
                    margin-left: 17px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                  }
                }
              }

              .new::before {
                content: '';
                position: absolute;
                left: 0px;
                top: -80px;
                width: 1px;
                height: 360px;
                background: #c7c7c7;
              }

              .new::after {
                content: '';
                position: absolute;
                left: 410px;
                top: 10px;
                width: 1px;
                height: 248px;
                background: #c7c7c7;
              }

              .industry {
                h2 {
                  font-size: 21px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #000000;
                }

                .new-title:hover {
                  h2 {
                    color: #ff0000;
                  }
                }

                .news {
                  margin-top: 25px;
                  margin-left: 80px;
                  width: 300px;
                  text-align: left;

                  .new-time {
                    display: flex;
                    flex-direction: row;

                    .red-time-box {
                      padding-top: 2px;

                      span {
                        font-size: 16px;
                      }

                      .red-time {
                        display: block;
                        margin-top: -8px;
                        width: 8px;
                        height: 4px;
                        font-size: 16px;
                        background-color: #ff7777;
                      }
                    }

                    h3 {
                      margin-left: 7px;
                      font-size: 16px;
                      font-family: SimSun;
                      font-weight: 400;
                      color: #000000;
                      overflow: hidden; //文本溢出隐藏
                      text-overflow: ellipsis; //文本溢出显示省略号
                      white-space: nowrap; //不换行
                    }
                  }

                  .new-time:hover {
                    h3 {
                      color: #ff0000;
                    }
                  }

                  .time {
                    display: block;
                    font-size: 15px;
                    margin-top: 10px;
                    margin-left: 17px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                  }
                }
              }
            }

            .content-slogan {
              position: absolute;
              left: 50%;
              bottom: 50px;
              cursor: auto;
              transform: translate(-45%, -45%);

              span {
                font-size: 21px;
                color: #000000;
              }
            }
          }
        }

        .top-products {
          width: 300px;
          height: 266px;
          display: flex;
          flex-direction: row;
          position: absolute;
          cursor: pointer;
          overflow: hidden;
          background-color: #fff;
          animation: myfirst 0.4s linear normal;

          .products-title {
            width: 244px;
            height: 100%;

            .title-one {
              width: 244px;
              height: 90px;
              position: relative;
              line-height: 90px;

              span {
                display: block;
                font-size: 24px;
                margin-left: 30px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                text-align: left;
                color: #ffffff;
              }
            }

            .title-one::before {
              content: '';
              position: absolute;
              left: 0px;
              top: 90px;
              width: 100%;
              height: 1px;
              background-image: linear-gradient(to right,
                  #ff2a2a,
                  #fff0f0,
                  #ff2626);
            }

            .title {
              width: 100%;
              height: 48px;
              position: relative;
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;

              .title-one-txt {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-left: 57px;
                font-size: 16px;

                .title-one-img {
                  width: 23px;
                  height: 23px;
                  margin-right: 10px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }

              i {
                margin-right: 10px;
              }
            }

            .title::before {
              content: '';
              position: absolute;
              left: 0px;
              top: 50px;
              width: 100%;
              height: 1px;
              background-image: linear-gradient(to right,
                  #ff2a2a,
                  #fff0f0,
                  #ff2626);
            }
          }

          .products-content {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: flex-start;
            padding-top: 10px;

            .products-item {
              position: relative;
              margin-top: 20px;
              height: 110px;
              width: 46%;
              text-align: left;

              .products-icon {
                margin-left: 78px;
                display: flex;
                flex-direction: row;
                align-items: center;

                img {
                  width: 20px;
                  height: 20px;
                  margin-right: 10px;
                }

                h1 {
                  font-size: 24px;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                  color: #000000;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                }
              }

              span {
                margin-left: 78px;
                margin-top: 20px;
                display: block;
                font-size: 17px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #707070;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }

              span::before {
                content: '';
                position: absolute;
                left: 78px;
                top: 110px;
                width: 360px;
                height: 1px;
                background: #999999;
              }
            }

            .products-item-null {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .products-item:hover {
              h1 {
                color: #ff0000;
              }
            }
          }
        }

        .top-backing {
          width: 1200px;
          height: 462px;
          display: flex;
          flex-direction: row;
          align-items: center;
          position: absolute;
          background-color: #fff;
          // cursor: pointer;
          overflow: hidden;
          animation: myfirst 0.4s linear normal;
          background: url('../assets/bg-big.jpg');
          background-size: 100% 100%;

          .backing-img {
            width: 500px;
            height: 390px;
            margin-left: 50px;
            border: 3px solid #a1696e;
            overflow: hidden;
          }

          .backing-text {
            width: 600px;
            height: 396px;
            margin-left: 0px;
            margin-right: 37px;
            background: #60000a;
            opacity: 0.59;
            display: flex;
            flex-direction: column;
            align-items: center;

            h1 {
              margin-top: 57px;
              font-size: 21px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #ffffff;
            }

            .h1-span {
              display: block;
              margin-top: 30px;
              width: 540px;
              height: 1px;
              background-color: #fff;
              margin-bottom: 17px;
            }

            .backing-span-box {
              display: flex;
              flex-direction: row;
              margin-top: 18px;
              text-align: left;
              width: 100%;

              .left-span {
                margin-left: 40px;
                display: block;
                font-size: 19px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #f2c7c7;
              }

              .or-span {
                display: block;
                margin: 0 15px;
              }

              span {
                text-align: left;
                display: block;
                font-size: 19px;
                font-family: Microsoft YaHei;
                color: #ffffff;
              }
            }
          }
        }
      }

      .top-nav-box4 {
        min-width: 600px;
        height: 199px;
        // position: absolute;
        // margin-left: 535px;
        box-shadow: 0 2px 2px 2px rgba($color: #000000, $alpha: 0.16);
        background-color: #FFFFFF;
        display: flex;
        justify-content: center;

        .top-detail {
          width: 100%;
          display: flex;
          flex-direction: row;
          cursor: pointer;
          position: absolute;
          overflow: hidden;
          background-color: #FFFFFF;
          height: 199px;

          .dtop-detail-hope {
            text-align: left;
            display: flex;
            color: #FFFFFF;

            .dtop-detail-hope-left {
              width: 91px;
              min-height: 265px;
              background: #BCBCBC;

              .dtop-detail-hope-left-title {
                display: flex;
                flex-direction: column;
                align-items: center;

                .dtop-detail-hope-left-title-span {
                  font-size: 13px;
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  line-height: 0px;
                  opacity: 1;
                  height: 13px;
                  margin-top: 20px;
                }
              }

              .active {
                color: #C70F1A;
              }
            }

            .dtop-detail-hope-right {
              flex: 1;
              margin: 0px 0px 37px 0px;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              flex-direction: column;

              .hope-buttom-wz {
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 118px;
                height: 13px;
                margin-top: 20px;
                height: 12px;
                font-size: 13px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 12px;
                color: #646464;
                opacity: 1;
                margin-left: 8px;
              }

              .hope-buttom-wz :hover {
                color: #C70F1A;
              }
            }
          }
        }
      }
    }

    .topShow {
      display: none;
    }
  }

  .home-banner {
    width: 100%;
    height: 460px;
    position: relative;

    ::v-deep.el-carousel__container {
      position: relative;
      height: 460px;
    }

    img {
      width: 100%;
      height: 460px;
    }

    .home-banner-bottom {
      height: 42px;
      background: url('../assets/newGW/bannerBj.png');
      border-radius: 0px;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      z-index: 999;
      display: flex;
      align-items: center;
      justify-content: center;

      .home-banner-bottom-left {
        display: flex;
        align-items: center;

        .home-banner-bottom-left-img {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 42px;
          background: #840005;
          opacity: 1;
          border-radius: 0px;

          .home-banner-bottom-left-img-box {
            width: 20px;
            height: 20px;
            padding: 12px 0px 11px 12px;
          }

          .home-banner-bottom-left-img-wz {
            width: 58px;
            font-size: 13px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            line-height: 0px;
            color: #FFFFFF;
            opacity: 1;
            padding: 16px 19px 13px 11px;
          }
        }

        .gdlist {
          width: 326px;
          font-size: 11px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 21px;
          color: #FFFFFF;
          opacity: 1;
          overflow: hidden;
          margin-left: 10px;
          /* 第二步：让文本不会换行， 在同一行继续 */
          white-space: nowrap;

          /* 第三步：用省略号来代表未显示完的文本 */
          text-overflow: ellipsis;

          .seamless-warp {
            width: 100%;
            height: 20px;
          }
        }
      }

      .home-banner-bottom-seach {
        padding-left: 50px;
        display: flex;
      }
    }
  }

  .home-banner-contact {
    width: 100%;
    height: 460px;

    img {
      width: 100%;
      height: 460px;
    }
  }
}

.mobile {
  display: none;
}

@media screen and (max-width: 981px) {
  .site-mian {
    display: none;
  }

  .mobile {
    display: block;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .site-heart {
      z-index: 999;
      width: 100%;
      height: 100%;
      background-color: #fff;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      .xxx-box {
        // background-image: url('../assets/top.jpg.gif');
        // background-size: 100% 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .top-navigation {
          background-size: 100%;
          height: 5.6875rem;
          width: 75rem;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .top-srarch {
            position: relative;

            span {
              position: absolute;
              display: block;
              top: .3125rem;
              right: .625rem;

              img {
                width: 3.125rem;
                height: 2.0625rem;
              }
            }
          }
        }

        .top-handoff {
          height: 91px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 50px;
          padding-bottom: 10px;

          .top-handoff-txt {
            margin-left: 346px;
            height: 100%;
            width: 1200px;
            text-align: center;
            display: flex;
            flex-direction: row;

            .text {
              width: 91px;
              height: 100%;
              font-size: 13px;
              font-weight: 400;
              color: #646464;
              display: flex;
              cursor: pointer;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }

            .active {
              background: #BF1A20;
              opacity: 1;
              border-radius: 0px;
              color: #FFFFFF;
            }
          }
        }
      }

      .nav-box {
        height: 100%;
        margin-left: 100px;
        position: relative;
        display: flex;
        background-color: #fff;
        justify-content: center;

        .top-nav-box {
          min-width: 550px;
          height: 199px;
          // position: absolute;
          // margin-left: 535px;
          box-shadow: 0 2px 2px 2px rgba($color: #000000, $alpha: 0.16);
          background-color: #FFFFFF;
          display: flex;
          justify-content: center;

          .top-detail {
            width: 100%;
            display: flex;
            flex-direction: row;
            cursor: pointer;
            position: absolute;
            overflow: hidden;
            background-color: #FFFFFF;
            height: 199px;

            .dtop-detail-hope {
              text-align: left;
              display: flex;
              color: #FFFFFF;

              .dtop-detail-hope-left {
                width: 91px;
                min-height: 265px;
                background: #BCBCBC;

                .dtop-detail-hope-left-title {
                  display: flex;
                  flex-direction: column;
                  align-items: center;

                  .dtop-detail-hope-left-title-span {
                    font-size: 13px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    line-height: 0px;
                    opacity: 1;
                    height: 13px;
                    margin-top: 20px;
                  }
                }

                .active {
                  color: #C70F1A;
                }
              }

              .dtop-detail-hope-right {
                flex: 1;
                margin: 0px 0px 37px 0px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                flex-direction: column;

                .hope-buttom-wz {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  width: 118px;
                  height: 13px;
                  margin-top: 20px;
                  height: 12px;
                  font-size: 13px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  line-height: 12px;
                  color: #646464;
                  opacity: 1;
                  margin-left: 8px;
                }

                .hope-buttom-wz :hover {
                  color: #C70F1A;
                }
              }
            }
          }
        }

        .top-nav-box1 {
          width: 90px;
          // position: absolute;
          // margin-left: 440px;
          max-height: 266px;
          box-shadow: 0 2px 2px 2px rgba($color: #000000, $alpha: 0.16);
          background: #BCBCBC;
          display: flex;
          justify-content: center;

          .top-detail {
            cursor: pointer;
            max-height: 266px;

            .hope-buttom {
              display: flex;
              flex-direction: column;
              padding-bottom: 10px;
            }

            .hope-buttom-wz {
              width: 130px;
              height: 17px;
              font-size: 13px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 0px;
              color: #FFFFFF;
              margin-top: 20px;
              opacity: 1;
            }

            .hope-buttom :hover {
              color: #C70F1A;
            }
          }
        }

        .top-nav-box3 {
          min-width: 400px;
          height: 199px;
          // position: absolute;
          // margin-left: 535px;
          box-shadow: 0 2px 2px 2px rgba($color: #000000, $alpha: 0.16);
          background-color: #FFFFFF;
          display: flex;
          justify-content: center;

          .top-detail {
            width: 100%;
            display: flex;
            flex-direction: row;
            cursor: pointer;
            position: absolute;
            overflow: hidden;
            background-color: #FFFFFF;
            height: 199px;

            .dtop-detail-hope {
              text-align: left;
              display: flex;
              color: #FFFFFF;

              .dtop-detail-hope-left {
                width: 91px;
                min-height: 265px;
                background: #BCBCBC;

                .dtop-detail-hope-left-title {
                  display: flex;
                  flex-direction: column;
                  align-items: center;

                  .dtop-detail-hope-left-title-span {
                    font-size: 13px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    line-height: 0px;
                    opacity: 1;
                    height: 13px;
                    margin-top: 20px;
                  }
                }

                .active {
                  color: #C70F1A;
                }
              }

              .dtop-detail-hope-right {
                flex: 1;
                margin: 0px 0px 37px 0px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                flex-direction: column;

                .hope-buttom-wz {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  width: 113px;
                  height: 13px;
                  margin-top: 20px;
                  height: 12px;
                  font-size: 13px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  line-height: 12px;
                  color: #646464;
                  opacity: 1;
                  margin-left: 8px;
                }

                .hope-buttom-wz :hover {
                  color: #C70F1A;
                }
              }
            }

            .content {
              padding-top: 30px;
              margin-left: 30px;
              height: 100%;
              width: 100%;
              position: relative;

              .aShowimg {
                display: flex;
                flex-direction: row;

                img {
                  margin-top: 5px;
                  width: 27px;
                  height: 22px;
                }

                h1 {
                  margin-left: 5px;
                }
              }

              .aShowimg:hover {
                h1 {
                  color: #ff0000;
                }
              }

              .content-title {
                display: flex;
                margin-left: 120px;
                margin-right: 150px;
                flex-direction: row;
                justify-content: space-between;

                a {
                  font-size: 23px;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                  color: #000000;
                  text-decoration: none;
                }

                h1 {
                  font-size: 23px;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                  color: #000000;
                }
              }

              .content-content {
                margin-top: 60px;
                margin-left: 30px;
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: row;

                .new {
                  position: relative;
                  text-align: center;
                  padding-left: 80px;

                  h2 {
                    font-size: 21px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #000000;
                  }

                  .new-title:hover {
                    h2 {
                      color: #ff0000;
                    }
                  }

                  .news {
                    width: 300px;
                    margin-top: 25px;
                    text-align: left;

                    .new-time {
                      display: flex;
                      flex-direction: row;

                      .red-time-box {
                        padding-top: 2px;

                        span {
                          font-size: 16px;
                        }

                        .red-time {
                          display: block;
                          margin-top: -8px;
                          width: 8px;
                          height: 4px;
                          font-size: 16px;
                          background-color: #ff7777;
                        }
                      }

                      h3 {
                        margin-left: 7px;
                        font-size: 16px;
                        font-family: SimSun;
                        font-weight: 400;
                        color: #000000;
                        overflow: hidden; //文本溢出隐藏
                        text-overflow: ellipsis; //文本溢出显示省略号
                        white-space: nowrap; //不换行
                      }
                    }

                    .new-time:hover {
                      h3 {
                        color: #ff0000;
                      }
                    }

                    .time {
                      display: block;
                      font-size: 15px;
                      margin-top: 10px;
                      margin-left: 17px;
                      font-family: Microsoft YaHei;
                      font-weight: 400;
                      color: #999999;
                    }
                  }
                }

                .new::before {
                  content: '';
                  position: absolute;
                  left: 0px;
                  top: -80px;
                  width: 1px;
                  height: 360px;
                  background: #c7c7c7;
                }

                .new::after {
                  content: '';
                  position: absolute;
                  left: 410px;
                  top: 10px;
                  width: 1px;
                  height: 248px;
                  background: #c7c7c7;
                }

                .industry {
                  h2 {
                    font-size: 21px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #000000;
                  }

                  .new-title:hover {
                    h2 {
                      color: #ff0000;
                    }
                  }

                  .news {
                    margin-top: 25px;
                    margin-left: 80px;
                    width: 300px;
                    text-align: left;

                    .new-time {
                      display: flex;
                      flex-direction: row;

                      .red-time-box {
                        padding-top: 2px;

                        span {
                          font-size: 16px;
                        }

                        .red-time {
                          display: block;
                          margin-top: -8px;
                          width: 8px;
                          height: 4px;
                          font-size: 16px;
                          background-color: #ff7777;
                        }
                      }

                      h3 {
                        margin-left: 7px;
                        font-size: 16px;
                        font-family: SimSun;
                        font-weight: 400;
                        color: #000000;
                        overflow: hidden; //文本溢出隐藏
                        text-overflow: ellipsis; //文本溢出显示省略号
                        white-space: nowrap; //不换行
                      }
                    }

                    .new-time:hover {
                      h3 {
                        color: #ff0000;
                      }
                    }

                    .time {
                      display: block;
                      font-size: 15px;
                      margin-top: 10px;
                      margin-left: 17px;
                      font-family: Microsoft YaHei;
                      font-weight: 400;
                      color: #999999;
                    }
                  }
                }
              }

              .content-slogan {
                position: absolute;
                left: 50%;
                bottom: 50px;
                cursor: auto;
                transform: translate(-45%, -45%);

                span {
                  font-size: 21px;
                  color: #000000;
                }
              }
            }
          }

          .top-products {
            width: 300px;
            height: 266px;
            display: flex;
            flex-direction: row;
            position: absolute;
            cursor: pointer;
            overflow: hidden;
            background-color: #fff;
            animation: myfirst 0.4s linear normal;

            .products-title {
              width: 244px;
              height: 100%;

              .title-one {
                width: 244px;
                height: 90px;
                position: relative;
                line-height: 90px;

                span {
                  display: block;
                  font-size: 24px;
                  margin-left: 30px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  text-align: left;
                  color: #ffffff;
                }
              }

              .title-one::before {
                content: '';
                position: absolute;
                left: 0px;
                top: 90px;
                width: 100%;
                height: 1px;
                background-image: linear-gradient(to right,
                    #ff2a2a,
                    #fff0f0,
                    #ff2626);
              }

              .title {
                width: 100%;
                height: 48px;
                position: relative;
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .title-one-txt {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  margin-left: 57px;
                  font-size: 16px;

                  .title-one-img {
                    width: 23px;
                    height: 23px;
                    margin-right: 10px;

                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }

                i {
                  margin-right: 10px;
                }
              }

              .title::before {
                content: '';
                position: absolute;
                left: 0px;
                top: 50px;
                width: 100%;
                height: 1px;
                background-image: linear-gradient(to right,
                    #ff2a2a,
                    #fff0f0,
                    #ff2626);
              }
            }

            .products-content {
              width: 100%;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              align-content: flex-start;
              padding-top: 10px;

              .products-item {
                position: relative;
                margin-top: 20px;
                height: 110px;
                width: 46%;
                text-align: left;

                .products-icon {
                  margin-left: 78px;
                  display: flex;
                  flex-direction: row;
                  align-items: center;

                  img {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                  }

                  h1 {
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #000000;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                  }
                }

                span {
                  margin-left: 78px;
                  margin-top: 20px;
                  display: block;
                  font-size: 17px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #707070;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                }

                span::before {
                  content: '';
                  position: absolute;
                  left: 78px;
                  top: 110px;
                  width: 360px;
                  height: 1px;
                  background: #999999;
                }
              }

              .products-item-null {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .products-item:hover {
                h1 {
                  color: #ff0000;
                }
              }
            }
          }

          .top-backing {
            width: 1200px;
            height: 462px;
            display: flex;
            flex-direction: row;
            align-items: center;
            position: absolute;
            background-color: #fff;
            // cursor: pointer;
            overflow: hidden;
            animation: myfirst 0.4s linear normal;
            background: url('../assets/bg-big.jpg');
            background-size: 100% 100%;

            .backing-img {
              width: 500px;
              height: 390px;
              margin-left: 50px;
              border: 3px solid #a1696e;
              overflow: hidden;
            }

            .backing-text {
              width: 600px;
              height: 396px;
              margin-left: 0px;
              margin-right: 37px;
              background: #60000a;
              opacity: 0.59;
              display: flex;
              flex-direction: column;
              align-items: center;

              h1 {
                margin-top: 57px;
                font-size: 21px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #ffffff;
              }

              .h1-span {
                display: block;
                margin-top: 30px;
                width: 540px;
                height: 1px;
                background-color: #fff;
                margin-bottom: 17px;
              }

              .backing-span-box {
                display: flex;
                flex-direction: row;
                margin-top: 18px;
                text-align: left;
                width: 100%;

                .left-span {
                  margin-left: 40px;
                  display: block;
                  font-size: 19px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #f2c7c7;
                }

                .or-span {
                  display: block;
                  margin: 0 15px;
                }

                span {
                  text-align: left;
                  display: block;
                  font-size: 19px;
                  font-family: Microsoft YaHei;
                  color: #ffffff;
                }
              }
            }
          }
        }

        .top-nav-box4 {
          min-width: 600px;
          height: 199px;
          // position: absolute;
          // margin-left: 535px;
          box-shadow: 0 2px 2px 2px rgba($color: #000000, $alpha: 0.16);
          background-color: #FFFFFF;
          display: flex;
          justify-content: center;

          .top-detail {
            width: 100%;
            display: flex;
            flex-direction: row;
            cursor: pointer;
            position: absolute;
            overflow: hidden;
            background-color: #FFFFFF;
            height: 199px;

            .dtop-detail-hope {
              text-align: left;
              display: flex;
              color: #FFFFFF;

              .dtop-detail-hope-left {
                width: 91px;
                min-height: 265px;
                background: #BCBCBC;

                .dtop-detail-hope-left-title {
                  display: flex;
                  flex-direction: column;
                  align-items: center;

                  .dtop-detail-hope-left-title-span {
                    font-size: 13px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    line-height: 0px;
                    opacity: 1;
                    height: 13px;
                    margin-top: 20px;
                  }
                }

                .active {
                  color: #C70F1A;
                }
              }

              .dtop-detail-hope-right {
                flex: 1;
                margin: 0px 0px 37px 0px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                flex-direction: column;

                .hope-buttom-wz {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  width: 118px;
                  height: 13px;
                  margin-top: 20px;
                  height: 12px;
                  font-size: 13px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  line-height: 12px;
                  color: #646464;
                  opacity: 1;
                  margin-left: 8px;
                }

                .hope-buttom-wz :hover {
                  color: #C70F1A;
                }
              }
            }
          }
        }
      }

      .topShow {
        display: none;
      }
    }

    .home-banner {
      width: 100%;
      height: 460px;
      position: relative;

      ::v-deep.el-carousel__container {
        position: relative;
        height: 460px;
      }

      img {
        width: 100%;
        height: 460px;
      }

      .home-banner-bottom {
        height: 42px;
        background: url('../assets/newGW/bannerBj.png');
        border-radius: 0px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;

        .home-banner-bottom-left {
          display: flex;
          align-items: center;

          .home-banner-bottom-left-img {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 42px;
            background: #840005;
            opacity: 1;
            border-radius: 0px;

            .home-banner-bottom-left-img-box {
              width: 20px;
              height: 20px;
              padding: 12px 0px 11px 12px;
            }

            .home-banner-bottom-left-img-wz {
              width: 58px;
              font-size: 13px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              line-height: 0px;
              color: #FFFFFF;
              opacity: 1;
              padding: 16px 19px 13px 11px;
            }
          }

          .gdlist {
            width: 326px;
            font-size: 11px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 21px;
            color: #FFFFFF;
            opacity: 1;
            overflow: hidden;
            margin-left: 10px;
            /* 第二步：让文本不会换行， 在同一行继续 */
            white-space: nowrap;

            /* 第三步：用省略号来代表未显示完的文本 */
            text-overflow: ellipsis;

            .seamless-warp {
              width: 100%;
              height: 20px;
            }
          }
        }

        .home-banner-bottom-seach {
          padding-left: 50px;
          display: flex;
        }
      }
    }

    .home-banner-contact {
      width: 100%;
      height: 460px;

      img {
        width: 100%;
        height: 460px;
      }
    }
  }
}




.home-banner ::v-deep.el-carousel__indicators {
  // 指示器
  left: unset;
  transform: unset;
  left: 28.75rem;
  bottom: 4.6875rem;
}

.home-banner ::v-deep.el-carousel__indicator--horizontal button {
  width: 3.125rem !important;
  height: .25rem;
  background: #FFFFFF;
  opacity: 0.41;
  border-radius: 3.125rem;
}

.home-banner ::v-deep .el-carousel__indicator--horizontal.is-active button {
  // 指示器按钮
  width: 3.125rem;
  height: .25rem;
  background: #FFFFFF;
  opacity: 1;
  border-radius: 3.125rem;
}

::v-deep .el-input__inner {
  width: 30.625rem;
  height: 1.8125rem;
  background: #FFFFFF;
  border: .0625rem solid #BBBBBB;
  opacity: 1;
  border-radius: .125rem;
}

::v-deep .el-button--primary {
  width: 6.5625rem;
  height: 29px;
  background: #C70F1A;
  opacity: 1;
  border-radius: .125rem;
  font-size: .6875rem;
  line-height: .3125rem;
  border: red;
}

::v-deep .el-input__suffix {
  right: 0px;
}
</style>
