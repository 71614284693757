<template>
  <div class="center">
    <SiteTop></SiteTop>
    <div class="center-box">
      <div class="newsContent-main">
        <div class="main-left">
          <div class="main-left-top">
            <div class="main-left-top-title">
              <div class="main-left-top-title-xw">联系我们</div>
              <div class="main-left-top-title-yw">Contact us</div>
            </div>
          </div>
          <div class="main-left-top-title-xt"></div>
          <div class="main-left-bottom">
            <el-row>
              <el-col :span="24">
                <el-menu background-color="#C70F1A" class="el-menu-vertical-demo" :default-active="activeIndex"
                  text-color="#FFFFFF">
                  <el-menu-item index="1">
                    <span>联系我们</span>
                  </el-menu-item>
                </el-menu>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="main-right-table-box">
          <img src="../assets/newGW/lxwmImg.png" alt="">
        </div>
      </div>
      <Regularbox></Regularbox>
    </div>
    <site-foot></site-foot>
  </div>
</template>
<script>
import SiteTop from "../components/siteTop.vue";
import SiteFoot from "../components/siteFoot.vue";
import Regularbox from '../components/regularbox.vue';
export default {
  components: { SiteTop, SiteFoot, Regularbox, },
  data() {
    return {
      activeIndex: '1',
      isCollapse: true, //导航栏默认为展开
      showTopNave: true //导航栏默认
    };
  },
  created() {

  },

  mounted() {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.center {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #FFFFFF;

  .center-box {
    display: flex;
    justify-content: center;

    .newsContent-main {
      width: 62.5rem;
      margin-top: 1.25rem;
      padding-bottom: 1.25rem;
      display: flex;
      background-color: #FFFFFF;

      .main-left {
        width: 180px;
        height: 577px;
        background: #C70F1A;
        opacity: 1;
        border-radius: 0px;
        display: flex;
        flex-direction: column;

        .main-left-top {
          height: 69px;

          .main-left-top-title {
            margin: 20px 0px 11px 20px;
            text-align: left;

            .main-left-top-title-xw {
              width: 64px;
              height: 21px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 0px;
              color: #FFFFFF;
              opacity: 1;
            }

            .main-left-top-title-yw {
              margin-top: 2px;
              width: 42px;
              height: 15px;
              font-size: 11px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 0px;
              color: #FB9898;
              opacity: 1;
            }
          }
        }

        .main-left-top-title-xt {
          height: 1px;
          margin: 0 6px 0 7px;
          background: url("../assets/newGW/xwxt.png");
        }

        .main-left-bottom {
          margin-top: 10px;

          .el-menu-vertical-demo {
            text-align: left;
            font-size: 11px;
            font-family: Microsoft YaHei;
            line-height: 0px;
            color: #FFFFFF;
            opacity: 1;
            border-right: none;
          }
        }
      }

      .main-right-table-box {
        height: 100%;
        margin-left: 20px;
        // background-color: #F5F5F5;
      }
    }
  }

  // @keyframes myMou1 {
  //   0% {
  //     margin-top: -12.5rem;
  //   }

  //   100% {
  //     margin-top: 0rem;
  //   }
  // }
}

::v-deep .el-table__body {
  -webkit-border-vertical-spacing: .625rem;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  width: 2.0625rem;
  height: 1.75rem;
  font-size: .5625rem;
  margin-top: .125rem;
  background: #C21F32;
  opacity: 1;
  border-radius: .125rem;
}

::v-deep .el-table .el-table__cell {
  padding: .5625rem 0;
}



::v-deep .el-submenu .el-menu-item {
  min-width: 6.25rem;
  font-size: .6875rem;
  height: 1.5625rem;
  line-height: 1.375rem;
  padding-left: 1.25rem !important;
}

//设置鼠标悬停时el-menu-item的样式
.el-menu-item:hover {
  background-image: url("../assets/newGW/navebg.png");
  background-size: 100% 100%;
  animation: 0.5s;
}

::v-deep .el-menu-item.is-active {
  // 被点击以后字体颜色改变
  background-image: url("../assets/newGW/navebg.png");
  background-size: 100% 100%;
  color: #F5F5F5;
}
</style>