<template>
  <div class="contact">
    <baidu-map class="map" :center="markerPoint" :zoom="zoom" :scroll-wheel-zoom='true'>
      <bm-marker :position="markerPoint" :dragging="true" @click="infoWindowOpen">
        <bm-info-window :show="show" @close="infoWindowClose" @open="infoWindowOpen" style="font-size:14px">
          四毅软件 <br><br>
          地址：长沙市兴工科技产业园
        </bm-info-window>
      </bm-marker>
    </baidu-map>
    <!-- <SiteFoot></SiteFoot> -->
  </div>
</template>

<script>
// import SiteFoot from "../components/siteFoot.vue";
export default {
  data() {
    return {
      markerPoint: { lng: 112.886999, lat: 28.216066 },
      show: false,
      zoom: 18

    };
  },
  methods: {
    infoWindowClose() {
      this.show = false;
    },
    infoWindowOpen() {
      this.show = true;
    },

  },
  mounted() {

  },
  // components: { SiteFoot }
};
</script>

<style>
.map {
  width: 100%;
  margin: auto;
  height: 390px;
}
</style>