<template>
  <div>
    <div class="servicePage-main">
      <SiteTop></SiteTop>
      <SolutionNav></SolutionNav>
      <site-foot></site-foot>
    </div>
  </div>
</template> 
<script>
import SiteFoot from '../components/siteFoot.vue';
import SiteTop from '../components/siteTop.vue';
import SolutionNav from "../components/SolutionNav.vue";
export default {
  components: { SiteTop, SiteFoot, SolutionNav },
  data() {
    return {
      productChild: {
        navSubItemIndex: '1',
      }
    }
  },
  created() {
    // if (this._isMobile()) {
    //   //手机端
    //   document.documentElement.style.fontSize = document.documentElement.clientWidth / 10 + 'px';
    //   console.log(document.documentElement.style.fontSize);
    // } else {
    //   //pc端
    //   console.log(document.documentElement.style.fontSize);
    // }
  },
  methods: {
    //判断手机端还是PC端
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
  }
}

</script>

<style lang="scss" scoped>
.servicePage-main {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;

  .servicePage-main-box {
    display: flex;
    justify-content: center;
  }

  .servicePage-center {
    width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .new-text {
      margin-top: 60px;
      width: 412px;
      height: 95px;
      background: url('../assets/title-bg.jpg');
      background-size: 100% 100%;

      h1 {
        margin-top: 25px;
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2e2e2e;
      }

      span {
        display: block;
        margin-top: 15px;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2e2e2e;
      }
    }

    .servicePage-icon {
      margin-top: 90px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      .servicePage-icon-list {
        width: 270px;
        padding: 10px;
        display: flex;
        margin-bottom: 70px;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 160px;
          height: 160px;
        }

        h5 {
          margin-top: 50px;
          font-size: 20px;
          font-weight: 600;
          color: #000000;
        }

        span {
          margin-top: 15px;
          display: block;
          font-size: 18px;
          font-weight: 400;
          color: #000000;
        }
      }
    }
  }

  .servicePage-center-box {
    width: 100%;
    height: 370px;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    align-items: center;

    h1 {
      margin-top: 50px;
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #2e2e2e;
    }

    .span-title {
      margin-top: 45px !important;
      display: block;
      font-size: 20px;
      margin-top: 10px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
    }

    .span-box {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        margin-top: 15px;
        display: block;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
      }
    }
  }

  .servicePage-foot {
    // width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;

    .text {
      margin-top: 60px;
      width: 412px;
      height: 95px;

      h1 {
        margin-top: 25px;
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2e2e2e;
      }
    }

    .foot-span {
      margin-top: 60px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      width: 850px;

      span {
        display: block;
        font-size: 18px;
        font-weight: 400;
        line-height: 36px;
        color: #000000;
      }
    }

    .foot-img {
      margin: 50px 0 140px 0;
      width: 768px;
      height: 397px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .servicePage-map {
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text {
      margin-top: 60px;
      width: 412px;
      height: 95px;

      h1 {
        margin-top: 25px;
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2e2e2e;
      }
    }

    .foot-img {
      margin: 60px 0 110px 0;
      width: 1200px;
      height: 620px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.mobile {
  display: none;
}

// @media screen and (max-width: 981px) {
//   .servicePage-main {
//     display: none;
//   }

//   .mobile {
//     display: block;

//     .mobile-banner {
//       width: 100%;
//       height: 3.5rem;

//       img {
//         width: 100%;
//         height: 100%;
//       }
//     }

//     .mobile-title {
//       width: 100%;
//       margin-top: 0.6rem;
//       display: flex;
//       flex-direction: column;
//       align-items: center;

//       .h-title {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         flex-direction: column;
//         width: 60%;
//         height: 1.2rem;
//         background: url('../assets/mobile/title.jpg');

//         h1 {
//           font-size: 0.4rem;
//           font-family: Microsoft YaHei;
//           font-weight: bold;
//           color: #2e2e2e;
//         }

//         span {
//           display: block;
//           margin-top: 0.1rem;
//           font-size: 0.2rem;
//           font-family: Microsoft YaHei;
//           font-weight: 400;
//           color: #2e2e2e;
//         }
//       }
//     }

//     .advantage-box {
//       width: 100%;
//       display: flex;
//       flex-direction: row;
//       justify-content: space-around;
//       margin-top: 0.4rem;

//       .servicePage-icon-list {
//         width: 2.7rem;
//         padding: 0.1rem;
//         display: flex;
//         margin-bottom: 0.7rem;
//         flex-direction: column;
//         align-items: center;
//         justify-content: center;

//         img {
//           width: 1.6rem;
//           height: 1.6rem;
//         }

//         h5 {
//           margin-top: 0.5rem;
//           font-size: 0.24rem;
//           font-weight: 600;
//           color: #000000;
//         }

//         span {
//           margin-top: 0.15rem;
//           display: block;
//           font-size: 0.2rem;
//           font-weight: 400;
//           color: #000000;
//         }
//       }
//     }

//     .mobile-text {
//       width: 100%;
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       justify-content: center;
//       background-color: #fff;

//       .text {
//         margin-top: 0.6rem;
//         width: 4.2rem;
//         height: 0.95rem;

//         h1 {
//           margin-top: 0.25rem;
//           font-size: 0.3rem;
//           font-family: Microsoft YaHei;
//           font-weight: bold;
//           color: #2e2e2e;
//         }
//       }

//       .foot-span {
//         margin-top: 0.3rem;
//         display: flex;
//         flex-direction: row;
//         flex-wrap: wrap;
//         align-items: center;
//         justify-content: center;
//         width: 80%;

//         span {
//           display: block;
//           font-size: 0.2rem;
//           font-weight: 400;
//           line-height: 0.36rem;
//           color: #000000;
//         }
//       }

//       .foot-img {
//         margin: 0.5rem 0 1.4rem 0;
//         width: 7.6rem;
//         height: 4.2rem;

//         img {
//           width: 100%;
//           height: 100%;
//         }
//       }
//     }

//     .mobile-map {
//       width: 100%;
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       background: #f5f5f5;
//       height: 6.2rem;

//       .text {
//         width: 4.2rem;
//         height: 1rem;

//         h1 {
//           margin-top: 0.3rem;
//           font-size: 0.3rem;
//           font-family: Microsoft YaHei;
//           font-weight: bold;
//           color: #2e2e2e;
//         }
//       }

//       .foot-img {
//         width: 90%;
//         height: 4.5rem;

//         img {
//           width: 100%;
//           height: 100%;
//         }
//       }
//     }
//   }
// }
</style>
