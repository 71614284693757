<template>
  <div class="main-right-table">
    <div class="main-right-table-top">
      <div class="main-right-table-top-title">
        <div><img src="../assets/newGW/fk.png" alt=""></div>
        <div class="main-right-table-top-title-wz">方案整体设计 </div>
        <div><img src="../assets/newGW/fk.png" alt=""></div>
      </div>
      <div class="main-right-table-top-bottom">
        <div class="bottom-box-wz">《中共中央 国务院关于全面实施预算绩效管理的意见》，建立预算绩效管理全过程、 全方位、全覆盖的管理信息系统。
          <span>
            以建立
            “全方位、全过程和全覆盖”预算绩效管理体系为目标，实现对预算绩效的全链条管理。实现预算
            编制有目标、预算执行有监控、预算完成有评价、评价结果有反馈、
            反馈结果有应用。</span>
        </div>
      </div>
    </div>
    <div class="main-right-table-center">
      <div class="main-right-table-center-title">
        <div class="main-right-table-center-title-wz">全新技术 全新模式 重新定义财政云服务 </div>
      </div>
      <div class="main-right-table-center-bottom">
        <img src="../assets/newGW/qxjstp.png" alt="">
      </div>
    </div>
    <div class="main-right-table-bottom">
      <div class="main-right-table-bottom-title">业务框架 </div>
      <div class="main-right-table-bottom-title-box">管理要求，以标准的业务流程和丰富的基础资料库，配合多类型的分析模型支撑全过程预算绩效管理，
        <span>形成全面预算绩效管理业务框架</span>
      </div>
    </div>
    <div class="main-right-table-foot">
      <div class="foot-title">多层次、全方位绩效管理</div>
      <div class="foot-title-two">
        <div class="foot-title-two-box">项目绩效管理</div>
        <div class="foot-title-two-box">政策绩效管理</div>
        <div class="foot-title-two-box"> 部门整体绩效管理</div>
        <div class="foot-title-two-box" id="two-box-top">政府财政运行绩效管理</div>
      </div>
      <div class="foot-title-three">
        <div class="foot-title-three-box">
          <div class="foot-title-three-box-title">事发绩效评估</div>
          <div class="foot-title-three-box-center">
            <div class="foot-title-three-box-center-wz">
              <div class="box-center-wz-top"></div>
              <div class="box-center-wz-bottom">项目立项评审</div>
            </div>
            <div class="foot-title-three-box-center-wz">
              <div class="box-center-wz-top"></div>
              <div class="box-center-wz-bottom">投资绩效评估</div>
            </div>
          </div>
        </div>
        <div class="foot-title-three-box">
          <div class="foot-title-three-box-title">绩效目标管理</div>
          <div class="foot-title-three-box-center">
            <div class="foot-title-three-box-center-wz">
              <div class="box-center-wz-top"></div>
              <div class="box-center-wz-bottom">绩效目标填报</div>
            </div>
            <div class="foot-title-three-box-center-wz">
              <div class="box-center-wz-top"></div>
              <div class="box-center-wz-bottom">绩效目标审核</div>
            </div>
            <div class="foot-title-three-box-center-wz">
              <div class="box-center-wz-top"></div>
              <div class="box-center-wz-bottom">绩效目标批复</div>
            </div>
          </div>
        </div>
        <div class="foot-title-three-box">
          <div class="foot-title-three-box-title">绩效跟踪管理</div>
          <div class="foot-title-three-box-center">
            <div class="foot-title-three-box-center-wz">
              <div class="box-center-wz-top"></div>
              <div class="box-center-wz-bottom">跟踪任务下达</div>
            </div>
            <div class="foot-title-three-box-center-wz">
              <div class="box-center-wz-top"></div>
              <div class="box-center-wz-bottom">跟踪信息填报</div>
            </div>
            <div class="foot-title-three-box-center-wz">
              <div class="box-center-wz-top"></div>
              <div class="box-center-wz-bottom">绩效跟踪审核</div>
            </div>
            <div class="foot-title-three-box-center-wz">
              <div class="box-center-wz-top"></div>
              <div class="box-center-wz-bottom">跟踪监控预警</div>
            </div>
          </div>
        </div>
        <div class="foot-title-three-box">
          <div class="foot-title-three-box-title">绩效评价管理</div>
          <div class="foot-title-three-box-center">
            <div class="foot-title-three-box-center-wz">
              <div class="box-center-wz-top"></div>
              <div class="box-center-wz-bottom">评价任务下达</div>
            </div>
            <div class="foot-title-three-box-center-wz">
              <div class="box-center-wz-top"></div>
              <div class="box-center-wz-bottom">评价信息填报
              </div>
            </div>
            <div class="foot-title-three-box-center-wz">
              <div class="box-center-wz-top"></div>
              <div class="box-center-wz-bottom">评价信息审核
              </div>
            </div>
            <div class="foot-title-three-box-center-wz">
              <div class="box-center-wz-top"></div>
              <div class="box-center-wz-bottom">专家评审
                审核</div>
            </div>
          </div>
        </div>
        <div class="foot-title-three-box">
          <div class="foot-title-three-box-title">评价结果应用</div>
          <div class="foot-title-three-box-center">
            <div class="foot-title-three-box-center-wz">
              <div class="box-center-wz-top"></div>
              <div class="box-center-wz-bottom">问题整改</div>
            </div>
            <div class="foot-title-three-box-center-wz">
              <div class="box-center-wz-top"></div>
              <div class="box-center-wz-bottom">整改落实</div>
            </div>
            <div class="foot-title-three-box-center-wz">
              <div class="box-center-wz-top"></div>
              <div class="box-center-wz-bottom">
                评价公开</div>
            </div>
            <div class="foot-title-three-box-center-wz">
              <div class="box-center-wz-top"></div>
              <div class="box-center-wz-bottom">评价报告</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-right-table-productBox">
      <div class="main-right-table-productBox-title">产品特点 </div>
      <div class="main-right-table-productBox-title-box">绩效管理的高要求，借鉴互联网思维，
        <span>以大数据、人工智能、云计算等主流技术为支撑,为财政、部门和预算单位提供满足“全方位、全过程和全覆盖”要求的数智化预算绩效产品</span>
      </div>
      <div class="img-box">
        <div><img src="../assets/newGW/cptd1.png" alt=""></div>
        <div><img src="../assets/newGW/cptd2.png" alt=""></div>
        <div><img src="../assets/newGW/cptd3.png" alt=""></div>
        <div><img src="../assets/newGW/cptcd4.png" alt=""></div>
        <div><img src="../assets/newGW/cptd5.png" alt=""></div>
      </div>
    </div>
    <div class="main-right-table-Construction">
      <div class="main-right-table-Construction-title">
        <div class="main-right-table-Construction-title-wz">全面预算绩效建设价值 </div>
      </div>
      <div class="main-right-table-Construction-h1">
        打造全方位、全业务 安全、高效的数据财政解决方案
      </div>
      <div class="main-right-table-Construction-bottom">
        <img src="../assets/newGW/qmys.png" alt="">
      </div>
      <div class="main-right-table-Construction-bottom-xt">
        <img src="../assets/newGW/ysxt.png" alt="">
      </div>
      <div class="Construction-bottom-title">
        <div>构建全方位预算绩效管理格局</div>
        <div>完善全覆盖预算绩效管理体系</div>
        <div>建立全过程预算绩效管理链条</div>
      </div>
      <div class="Construction-bottom-img">
        <img src="../assets/newGW/jxpgbj.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>

export default {

  data() {
    return {
      currentPage: 1,
      pagesize: 99,
      totalPages: 0,
      tableData: [],
      ZizeData: [],
      DataType: '1',
      titleData: '公司简介',
      select: '1',
      scrollerHeight: '420',
      dataHtml: [],
      isCollapse: true, //导航栏默认为展开
    };
  },
  created() {
  },
  mounted() {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.main-right-table {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: 800px;

  .main-right-table-top {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #F5F5F5;

    .main-right-table-top-title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 33px;

      .main-right-table-top-title-wz {
        height: 16px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 15px;
        color: #000000;
        opacity: 1;
        padding: 0px 9px 0px 9px;
      }
    }

    .main-right-table-top-bottom {
      display: flex;
      flex-wrap: nowrap;
      padding: 22px 50px 35px 50px;
      justify-content: space-between;

      .bottom-box-wz {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        opacity: 1;
        text-align: left;
        text-indent: 50px;
      }

      .bottom-box-wz>span {
        color: #C70F1A;
      }
    }
  }

  .main-right-table-center {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #FFFFFF;

    .main-right-table-center-title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 33px;

      .main-right-table-center-title-wz {
        height: 16px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 15px;
        color: #000000;
        opacity: 1;
        padding: 0px 9px 0px 9px;
      }
    }

    .main-right-table-center-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;

    }
  }

  .main-right-table-bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #F5F5F5;

    .main-right-table-bottom-title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 33px;
      height: 16px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      line-height: 15px;
      color: #000000;
      opacity: 1;

    }

    .main-right-table-bottom-title-box {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 30px;
      color: #000000;
      opacity: 1;
      text-align: left;
      text-indent: 30px;
      padding: 22px 50px 35px 50px;
    }

    .main-right-table-bottom-title-box span {
      color: #C70F1A;
      margin-left: 3px;
    }


  }

  .main-right-table-foot {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #FFFFFF;

    .foot-title {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #2E2E2E;
      opacity: 1;
      padding-top: 33px;
    }

    .foot-title-two {
      display: flex;
      justify-content: space-between;
      padding: 23px 50px 0 50px;

      .foot-title-two-box {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        opacity: 1;
        background-color: #C70F1A;
        width: 155px;
        height: 38px;
        border-radius: 5px;
        line-height: 38px;
      }

      #two-box-top {
        font-size: 14px;
      }
    }

    .foot-title-three {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 23px 50px 0 50px;

      .foot-title-three-box {
        background: #F5F5F5;
        opacity: 1;
        border-radius: 5px;
        height: 163px;

        .foot-title-three-box-title {
          width: 124px;
          height: 26px;
          background: #CBCBCB;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          line-height: 26px;
          color: #C70F1A;
          opacity: 1;
        }

        .foot-title-three-box-center {
          display: flex;
          flex-direction: column;
          align-items: center;

          .foot-title-three-box-center-wz {
            display: flex;
            justify-content: space-between;
            align-items: center;
            justify-content: center;
            margin-top: 10px;

            .box-center-wz-top {
              width: 7px;
              height: 7px;
              background: #EB1B1B;
              border-radius: 50%;
              opacity: 1;
              margin-right: 13px;

            }

            .box-center-wz-bottom {
              font-size: 13px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 22px;
              color: #2E2E2E;
              opacity: 1;
            }
          }
        }
      }
    }

    .main-right-table-productBox {}
  }

  .main-right-table-productBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #F5F5F5;
    margin-top: 30px;

    .main-right-table-productBox-title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 33px;
      height: 16px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      line-height: 15px;
      color: #000000;
      opacity: 1;

    }

    .main-right-table-productBox-title-box {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 30px;
      color: #000000;
      opacity: 1;
      text-align: left;
      text-indent: 30px;
      padding: 22px 50px 0px 50px;
    }

    .main-right-table-productBox-title-box span {
      color: #C70F1A;
      margin-left: 3px;
    }

    .img-box {
      display: flex;
      justify-content: space-between;
      padding: 20px 50px 40px 50px;
    }
  }

  .main-right-table-Construction {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #FFFFFF;

    .main-right-table-Construction-title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 33px;

      .main-right-table-Construction-title-wz {
        height: 16px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 15px;
        color: #000000;
        opacity: 1;
        padding: 0px 9px 0px 9px;
      }
    }

    .main-right-table-Construction-h1 {
      font-size: 10px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000000;
      opacity: 1;
      padding-top: 15px;
    }

    .main-right-table-Construction-bottom {
      padding-top: 25px;
    }

    .main-right-table-Construction-bottom-xt {
      padding-top: 18px;
    }

    .Construction-bottom-title {
      display: flex;
      padding: 9px 150px 0px 142px;
      font-size: 11px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 26px;
      color: #C70F1A;
      opacity: 1;
      justify-content: space-between;
    }

    .Construction-bottom-img {
      padding-top: 32px;
    }
  }
}

@keyframes myMou1 {
  0% {
    margin-top: -12.5rem;
  }

  100% {
    margin-top: 0rem;
  }
}



::v-deep .el-table__body {
  -webkit-border-vertical-spacing: .625rem;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  width: 2.0625rem;
  height: 1.75rem;
  font-size: .5625rem;
  margin-top: .125rem;
  background: #C21F32;
  opacity: 1;
  border-radius: .125rem;
}

::v-deep .el-table .el-table__cell {
  padding: .5625rem 0;
}



::v-deep .el-submenu .el-menu-item {
  min-width: 6.25rem;
  font-size: .6875rem;
  height: 1.5625rem;
  line-height: 1.375rem;
  padding-left: 1.25rem !important;
}

//设置鼠标悬停时el-menu-item的样式
.el-menu-item:hover {
  background-image: url("../assets/newGW/navebg.png");
  background-size: 100% 100%;
  animation: 0.5s;
}

::v-deep .el-menu-item.is-active {
  // 被点击以后字体颜色改变
  background-image: url("../assets/newGW/navebg.png");
  background-size: 100% 100%;
  color: #F5F5F5;
}
</style>