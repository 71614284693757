<template>
  <div class="center">
    <div class="newsContent-main">
      <div class="main-left">
        <div class="main-left-top">
          <div class="main-left-top-title">
            <div class="main-left-top-title-xw">全站搜索</div>
            <div class="main-left-top-title-yw">Seach</div>
          </div>
        </div>
        <div class="main-left-top-title-xt"></div>
        <div class="main-left-bottom">
          <el-row>
            <el-col :span="24">
              <el-menu background-color="#C70F1A" class="el-menu-vertical-demo" :default-active="activeIndex"
                text-color="#FFFFFF">
                <el-menu-item index="1">
                  <span @click="allSeachClick">全站搜索</span>
                </el-menu-item>
              </el-menu>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="main-right-table" v-if="show">
        <div class="main-right-table-title">
          <div><img src="../assets/newGW/fk.png" alt=""></div>
          <div class="table-title-wz">全站搜索</div>
          <div><img src="../assets/newGW/fk.png" alt=""></div>
        </div>
        <div class="main-right-table-box">
          <el-table :data="tableData.slice((currentPage - 1) * pagesize, currentPage * pagesize)"
            :cell-style="{ padding: '6px 0px 6px 0px', }" highlight-current-row
            :header-cell-style="{ background: '#DEDEDE', color: '#C21F32', 'text-align': 'center' }"
            style="font-size:12px;background: #F5F5F5;width:100%" @row-click="onRowClick">
            <el-table-column prop="" label="" width="20">
              <template slot-scope="scope">
                <div class="list-img">
                  <img src="../assets/newGW/xwdtFk.png" alt="">
                </div>
                {{ scope.row.customerName }}
              </template>
            </el-table-column>
            <el-table-column prop="homepagecontext" label="信息详情" align="left" width="550px">
            </el-table-column>
            <el-table-column prop="time" label="公布时间" align="center"></el-table-column>
          </el-table>
          <div class="paginationBox">
            <el-pagination :current-page="currentPage" :page-sizes="[9, 10, 20, 40]" :page-size="pagesize" background
              layout=" prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
              @current-change="handleCurrentChange" style="background: #FFFFFF;height:35px;text-align:center;" />
          </div>
        </div>
      </div>
      <div class="main-right-table" v-if="showHtml">
        <div v-html="htmlData" class="main-right-table-content"></div>
      </div>
    </div>
    <Regularbox></Regularbox>
  </div>
</template>
<script>
import { get } from '../axios/axios.js';
import Regularbox from './regularbox.vue';
import moment from "moment";
export default {
  components: { Regularbox },
  data() {
    return {
      newId: '',
      currentPage: 1,
      pagesize: 9,
      totalPages: 0,
      total: '',
      tableData: [],
      DataType: '1',
      show: true,
      showHtml: false,
      showJgData: true,
      htmlData: '',
      activeIndex: '1',
    };
  },
  created() {
    if (this.$route.query.centenr !== undefined) {
      this.show = false;
      this.showHtml = true
      this.activeIndex = '1'
      this.getInteData()
      this.allSeachClick()
    } else {
      this.show = true;
    }
  },
  watch: {
    '$route'() {
      if (this.$route.query.centenr !== undefined) {
        console.log('21312')
        this.show = false;
        this.showHtml = true
        this.activeIndex = '1'
        this.getInteData()
        this.allSeachClick()
      } else {
        this.show = true;
      }
    }
  },
  mounted() {
    this.getInteData();
  },
  methods: {
    //时间格式化
    formatDate(value) {
      return moment(value * 1000).format("YYYY-MM-DD");
    },
    async getInteData() {
      await get('/api/PageQuery/GetPageQuery', { content: this.$route.query.centenr, Page: 1, Rows: 9, }).then(res => {
        if (!res.flag) {
          this.tableData = []
          for (let i of res.data) {
            this.tableData.push({ homepagecontext: i.homepagecontext, time: this.formatDate(i.time), id: i.id, type: i.type })
            this.total = res.total
          }
        }
      })
    },
    handleCurrentChange() {
      console.log('213123')
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.getNewsIndustry()
    },
    onRowClick(row) {
      this.show = false
      this.showHtml = true
      if (row.type == '1') {
        get('/api/PageQuery/GetFrontEndNews', { id: row.id }).then(res => {
          if (res.flag) {
            this.htmlData = res.data.content
          }
        })
      } else if (row.type == '2') {
        get('/api/PageQuery/GetFrontSolutionSelect', { id: row.id }).then(res => {
          if (res.flag) {
            this.htmlData = res.data.content
          }
        })
      } else if (row.type == '3') {
        get('/api/PageQuery/GetFrontProductServiceSelectChild', { id: row.id }).then(res => {
          if (res.flag) {
            this.htmlData = res.data.content
          }
        })
      } else if (row.type == '4') {
        get('/api/PageQuery/GetFrontMainBusinessSelect', { id: row.id }).then(res => {
          if (res.flag) {
            this.htmlData = res.data.content
          }
        })
      }
    },
    allSeachClick() {
      this.show = true
      this.showHtml = false
    }
  },
}
</script>

<style lang="scss" scoped>
.center {
  display: flex;
  justify-content: center;

  .newsContent-main {
    width: 1000px;
    margin-top: 20px;
    padding-bottom: 20px;
    display: flex;
    background-color: #FFFFFF;

    .main-left {
      width: 180px;
      height: 577px;
      background: #C70F1A;
      opacity: 1;
      border-radius: 0px;
      display: flex;
      flex-direction: column;

      .main-left-top {
        height: 69px;

        .main-left-top-title {
          margin: 20px 0px 11px 20px;
          text-align: left;

          .main-left-top-title-xw {
            width: 64px;
            height: 21px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 0px;
            color: #FFFFFF;
            opacity: 1;
          }

          .main-left-top-title-yw {
            margin-top: 2px;
            width: 42px;
            height: 15px;
            font-size: 11px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 0px;
            color: #FB9898;
            opacity: 1;
          }
        }
      }

      .main-left-top-title-xt {
        height: 1px;
        margin: 0 6px 0 7px;
        background: url("../assets/newGW/xwxt.png");
      }

      .main-left-bottom {
        margin-top: 10px;

        .el-menu-vertical-demo {
          text-align: left;
          font-size: 11px;
          font-family: Microsoft YaHei;
          line-height: 0px;
          color: #FFFFFF;
          opacity: 1;
          border-right: none;
        }
      }
    }

    .main-right-table {
      width: 800px;
      background-color: #F5F5F5;
      opacity: 1;
      border-radius: 0px;
      margin-left: 20px;

      .main-right-table-title {
        width: 125px;
        margin: 25px 0px 19px 28px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .table-title-wz {
          width: 64px;
          height: 21px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 15px;
          color: #000000;
          opacity: 1;
        }
      }

      .main-right-table-box {
        margin: 15px 29px 0px 28px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .cell-class-name {
          height: 35px;
          background: red;
          opacity: 1;
          text-align: center;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 14px;
          color: #333333;
          opacity: 1
        }
      }

      .paginationBox {
        width: 100%;
        display: flex;
        background-color: #FFFFFF;
        align-items: center;
        justify-content: center;
      }
    }

    .main-right-table-content {
      padding: 20px;
    }
  }
}



::v-deep .el-table__body {
  -webkit-border-vertical-spacing: 10px;
}

::v-deep .el-pagination {
  display: flex;
  align-items: center;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  width: 33px;
  height: 28px;
  font-size: 9px;
  background: #C21F32;
  opacity: 1;
  border-radius: 2px;
}

::v-deep .el-table .el-table__cell {
  padding: 9px 0;
  cursor: pointer;
}

//设置鼠标悬停时el-menu-item的样式
.el-menu-item:hover {
  background-image: url("../assets/newGW/navebg.png");
  background-size: 100% 100%;
}

::v-deep .el-menu-item.is-active {
  // 被点击以后字体颜色改变
  background-image: url("../assets/newGW/navebg.png");
  background-size: 100% 100%;
  color: #F5F5F5;
}

::v-deep .el-table td.el-table__cell div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>