<template>
  <!-- <a-locale-provider> -->
  <!-- :style="style" -->
  <div id="app" :class="isColor ? 'bjCss' : ''">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>

    <!-- <router-view v-if="isRouterAlive"></router-view> -->
  </div>
  <!-- </a-locale-provider> -->
</template>

<script>
// import { get } from "@/axios/axios";
// import { Message } from "element-ui";

export default {
  data() {
    return {
      isRouterAlive: true,
      isColor: false
      // locale: zhCN,
      // w: 760,
      // h: 1000,
      // style: {
      //   width: "",
      //   height: "",
      //   transform: "",
      //   overflowX: "",
      //   translateX: "",
      // }
    }
  },


  methods: {
    getAlllist() {
      // get('/api/GeneralSituation/GetBasicConfigurationData', {
      //   ConfigurationType: 1
      // }).then(res => {
      //   console.log(res);
      //   if (res.flag) {

      //     this.isColor = res.data;
      //   } else {
      //     Message.error('数据加载失败')
      //   }
      // })
    },
  },
  mounted() {
    this.getAlllist()
  }
  // methods: {
  //   isPhone() {
  //     if (/Android|webOs|iPhone|iPid|BlackBerry/i.test(navigator.userAgent)) {
  //       return true
  //     }
  //     return
  //   },
  //   getScale() {
  //     const w = window.innerWidth / this.w
  //     const h = window.innerHeight / this.h
  //     this.style.transform = "scale(" + w + ',' + h + ")";
  //     let type = /iPad|iPhone/i.test(navigator.userAgent)
  //     if (!type) {
  //       let s = window.screen.width / 1200;
  //       document.body.style.zoom = s;
  //       document.body.style.overflow = 'auto';
  //     } else {
  //       this.style.overflowX = 'hidden';
  //       document.body.style.minWidth = '375px';
  //     }
  //   },
  // },
  // mounted() {
  //   if (this.isPhone()) {
  //     this.getScale()
  //     window.addEventListener('resize', this.getScale)
  //     this.style.width = '1200px';
  //     this.style.height = '4700px';
  //     this.style.transformOrigin = '0 0';
  //   }
  // },
  // destroyed() {
  //   window.removeEventListener('resize', this.getScale)
  // },


}
</script>


<style lang="scss">
* {
  padding: 0;
  margin: 0;
  // scroll-behavior: smooth;
}

html,
body {
  width: 100%;

}

@media screen and (max-width: 981px) {

  html,
  body {
    width: 127%;
  }
}



#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.bjCss {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
  -webkit-filter: grayscale(1)
}
</style>
