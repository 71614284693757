<template>
  <div class="main-right-table-box">
    <div class="main-right-table-top">
      <div class="main-right-table-top-title">
        <div><img src="../assets/newGW/fk.png" alt=""></div>
        <div class="main-right-table-top-title-wz">财政云平台</div>
        <div><img src="../assets/newGW/fk.png" alt=""></div>
      </div>
      <div class="main-right-table-top-bottom">
        <div class="main-right-table-top-bottom-box">
          财政云以“新技术、新模式、新理念”为基础，以生态共享云平getNewsIndustry 台为核心，遵循金财工程业务和技术标准规范，深度契合深化财税体制改革要求，支撑财政生态业务群建设。
          <span>全面构建标准统一、数据赋能、开放共享、生态互联、智能服务的财政业务新生态。</span>
        </div>
      </div>
    </div>
    <div class="main-right-table-center">
      <div class="main-right-table-center-title-wz">全新技术 全新模式 重新定义财政云服务</div>
      <div class="main-right-table-center-img">
        <img src="../assets/newGW/qxbj.png" alt="">
      </div>
    </div>
    <div class="main-right-table-bottom">
      <div class="main-right-table-bottom-top">财政一体化</div>
      <div class="main-right-table-bottom-center">打造全方位、全业务 安全、高效的数据财政解决方案</div>
      <div class="main-right-table-bottom-foot">
        <div class="main-right-table-bottom-foot-box">
          <div class="box-img"><img src="../assets/newGW/czyth1.png" alt=""></div>
          <div class="box-top-title">数据规范</div>
          <div class="box-top-center">基于统一平台 </div>
          <div class="box-top-center">统一基础数据规范</div>
        </div>
        <div class="main-right-table-bottom-foot-box">
          <div class="box-img"><img src="../assets/newGW/czyth2.png" alt=""></div>
          <div class="box-top-title">无缝衔接</div>
          <div class="box-top-center">一体化设计无接口模式 </div>
          <div class="box-top-center">信息对称安全性强</div>
        </div>
        <div class="main-right-table-bottom-foot-box">
          <div class="box-img"><img src="../assets/newGW/czyth3.png" alt=""></div>
          <div class="box-top-title">体验顺畅</div>
          <div class="box-top-center">流程化操作 </div>
          <div class="box-top-center">面向用户 提升体验</div>
        </div>
        <div class="main-right-table-bottom-foot-box" id="box-top-size">
          <div class="box-img"><img src="../assets/newGW/czyih4.png" alt=""></div>
          <div class="box-top-title">性能可靠</div>
          <div class="box-top-center">新技术、新架构 </div>
          <div class="box-top-center">专项性能优化</div>
        </div>
        <div class="main-right-table-bottom-foot-box" id="box-top-size">
          <div class="box-img"><img src="../assets/newGW/czyth5.png" alt=""></div>
          <div class="box-top-title">数据规范</div>
          <div class="box-top-center">业务与数据分离 </div>
          <div class="box-top-center">应用与查询分离</div>
          <div class="box-top-center">保证查询效率</div>
        </div>
        <div class="main-right-table-bottom-foot-box" id="box-top-size">
          <div class="box-img"><img src="../assets/newGW/czyth6.png" alt=""></div>
          <div class="box-top-title">灵活扩展</div>
          <div class="box-top-center">敏捷迭代、灵活扩展 </div>
          <div class="box-top-center">快速支撑财政改革新要求</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {

  data() {
    return {
      currentPage: 1,
      pagesize: 99,
      totalPages: 0,
      tableData: [],
      ZizeData: [],
      DataType: '1',
      titleData: '公司简介',
      select: '1',
      scrollerHeight: '420',
      dataHtml: [],
      isCollapse: true, //导航栏默认为展开
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.main-right-table-box {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: 800px;

  .main-right-table-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F5F5F5;

    .main-right-table-top-title {
      display: flex;
      align-items: center;
      padding-top: 33px;

      .main-right-table-top-title-wz {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #000000;
        opacity: 1;
        padding: 0px 10px 0px 10px;
      }
    }

    .main-right-table-top-bottom {
      padding: 22px 50px 35px 50px;

      .main-right-table-top-bottom-box {
        text-align: left;
        text-indent: 50px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 30px;
        color: #000000;
        opacity: 1;
      }

      .main-right-table-top-bottom-box>span {
        color: #C70F1A;
      }
    }
  }

  .main-right-table-center {
    display: flex;
    align-content: center;
    flex-direction: column;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 0px;

    .main-right-table-center-title-wz {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      line-height: 0px;
      color: #2E2E2E;
      opacity: 1;
      padding-top: 33px;
    }

    .main-right-table-center-img {
      padding: 4px 0 4px 0;
    }
  }

  .main-right-table-bottom {
    display: flex;
    flex-direction: column;
    background: #F5F5F5;

    .main-right-table-bottom-top {
      padding-top: 32px;
      width: 100%;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #2E2E2E;
      opacity: 1;
    }

    .main-right-table-bottom-center {
      width: 100%;
      font-size: 10px;
      padding-top: 15px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #2E2E2E;
      opacity: 1
    }

    .main-right-table-bottom-foot {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 25px 0px 30px 0px;

      .main-right-table-bottom-foot-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 33.33%;

        .box-top-title {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #2E2E2E;
          opacity: 1;
          padding-top: 16px;
        }

        .box-top-center {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #7A7A7A;
          opacity: 1;
          padding-top: 11px;
        }
      }

      #box-top-size {
        margin-top: 25px;
      }
    }
  }
}

@keyframes myMou1 {
  0% {
    margin-top: -12.5rem;
  }

  100% {
    margin-top: 0rem;
  }
}



::v-deep .el-table__body {
  -webkit-border-vertical-spacing: .625rem;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  width: 2.0625rem;
  height: 1.75rem;
  font-size: .5625rem;
  margin-top: .125rem;
  background: #C21F32;
  opacity: 1;
  border-radius: .125rem;
}

::v-deep .el-table .el-table__cell {
  padding: .5625rem 0;
}



::v-deep .el-submenu .el-menu-item {
  min-width: 6.25rem;
  font-size: .6875rem;
  height: 1.5625rem;
  line-height: 1.375rem;
  padding-left: 1.25rem !important;
}

//设置鼠标悬停时el-menu-item的样式
.el-menu-item:hover {
  background-image: url("../assets/newGW/navebg.png");
  background-size: 100% 100%;
  animation: 0.5s;
}

::v-deep .el-menu-item.is-active {
  // 被点击以后字体颜色改变
  background-image: url("../assets/newGW/navebg.png");
  background-size: 100% 100%;
  color: #F5F5F5;
}
</style>