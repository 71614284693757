<template>
  <div class="newsContent-main">
    <SiteTop></SiteTop>
    <!-- 面包屑导航栏 -->
    <div class="breadcrumb-box">
      <div class="breadcrumb-icon"><img src="../assets/position.jpg" alt="">
      </div>
      <span>我的位置 </span>
      <i class="el-icon-arrow-right" style="color:#c0c4cc;margin-left:10px"></i>
      <PositionNav></PositionNav>
      <i class="el-icon-arrow-right" style="color:#c0c4cc;margin-left:10px;margin-right:10px"></i>
      <span class="breadcrumb-icon-right">{{newName}}</span>
      <i class="el-icon-arrow-right" style="color:#c0c4cc;margin-left:10px;margin-right:10px"></i>
      <span class="breadcrumb-icon-right">{{newTitle}}</span>
    </div>
    <div class="newsContent-top">
      <div class="newsContent-top-box">
        <img :src="baseUrl+'Attachment/'+newIcon" alt="">
        <h1>{{newName}}</h1>
      </div>
    </div>
    <div class="newsContent-center">
      <h1>{{newsContent.title}}</h1>
      <div class="newsContent-spam">
        <span>来源：{{newsContent.source}}</span>
        <span>时间：{{newsContent.time}}</span>
        <!-- <div class="newsContent-spam-size">
          <span>【字体：</span> <span class="span-size" @click="changeSize(1)">大</span> <span class="span-size"
            @click="changeSize(2)">中</span><span class="span-size" @click="changeSize(3)">小</span><span>】</span>
        </div> -->
      </div>
      <div v-html="this.newsContent.content" id="newsContent-txt"></div>
    </div>
    <SiteFoot></SiteFoot>
  </div>
</template>

<script>
import { get } from '../axios/axios.js';
import SiteTop from '../components/siteTop.vue';
import SiteFoot from '../components/siteFoot.vue';
import PositionNav from './positionNav.vue';
import axios from '../axios/axios.js'
export default {
  data() {
    return {
      newId: "",
      newName: '',
      newTitle: '',
      newIcon: '',
      newsContent: "",
      fontSize: 18,
      baseUrl: ''

    };
  },
  mounted() {
    this.baseUrl = axios.configurl
    this.newId = this.$route.query.id;
    this.newName = this.$route.query.name;
    this.newTitle = this.$route.query.title
    this.newIcon = this.$route.query.icon
    this.newsContentClick();
    this.getAllTags(document.getElementById('newsContent-txt').children);
  },
  methods: {
    async newsContentClick() {
      await get("/api/PageQuery/GetFrontEndProductService", { id: this.newId }).then(res => {
        if (res.flag == true) {

          this.newsContent = res.data;
        }
      });
    },
    changeSize(i) {

      if (i == 1) {
        this.fontSize = 24
      } else if (i == 2) {
        this.fontSize = 18
      } else if (i == 3) {
        this.fontSize = 15
      }
    },
    getAllTags(obj) {

      let arr = [];//用来存放获取到的所有的标签
      let arrFontSize = [];//用来存放获取到的所有的标签原来字体大小
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].children) {//当前元素还存在子元素的时候,递归
          this.getAllTags(obj[i].children);
        }
        //如果有fontSize样式 就保存下来
        if (obj[i].style.fontSize) {
          arr.push(obj[i]); //遍历到的元素添加到arr这个数组中间
          arrFontSize.push(parseInt(obj[i].style.fontSize.substring(0, obj[i].style.fontSize.length - 2)));//保存原标签字体大小
        }

      }
    },

    //设置字体大小  如果你还有其他需求 同样可以设置
    setFontSize(base) {
      let arr = [];//用来存放获取到的所有的标签
      let arrFontSize = [];//用来存放获取到的所有的标签原来字体大小
      arr.map((item, index) => {
        item.style.fontSize = arrFontSize[index] * base + 'px';
        item.style.lineHeight = arrFontSize[index] * base * 1.7 + 'px';//这里同时 等比例的设置行间距
      });
    },

  },
  components: { SiteTop, SiteFoot, PositionNav }
}
</script>

<style lang="scss" scoped>
.newsContent-main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  .breadcrumb-box {
    width: 1160px;
    height: 58px;
    margin-top: 30px;
    padding-left: 40px;
    background-color: #fff;
    border-radius: 33px;
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: row;
    box-shadow: -1px 1px 8px 4px rgba($color: #000000, $alpha: 0.16);
    .breadcrumb-icon {
      width: 16px;
      height: 19px;
      margin-right: 6px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    span {
      display: block;
      text-align: left;
      font-size: 17px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
    }
    .breadcrumb-icon-right {
      display: block;
      text-align: left;
      font-size: 17px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ff0000;
    }

    ::v-deep.el-breadcrumb__inner a,
    .el-breadcrumb__inner.is-link {
      font-weight: 400;
      font-size: 17px;
      text-decoration: none;
      font-family: Microsoft YaHei;
      transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      color: #ff0000;
    }
  }
  .newsContent-top {
    width: 1200px;
    height: 60px;
    margin-top: 30px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px -2px 8px 4px rgba($color: #000000, $alpha: 0.16);

    .newsContent-top-box {
      width: 1160px;
      height: 100%;
      border-bottom: 4px solid #ff0000;
      display: flex;
      align-items: center;
      img {
        margin-left: 15px;
        width: 25px;
        height: 25px;
        color: #ff0000;
      }
      h1 {
        margin-left: 10px;
        font-size: 20px;
        color: #000;
      }
    }
  }
  .newsContent-center {
    width: 1040px;
    padding-left: 80px;
    padding-right: 80px;
    margin-bottom: 80px;
    height: 100%;
    text-align: center;
    background-color: #fff;
    box-shadow: 0px 8px 8px 3px rgba($color: #000000, $alpha: 0.16);
    h1 {
      margin-top: 80px;
      font-size: 24px;
      font-weight: bold;
      color: #000000;
    }
    .newsContent-spam {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 40px;

      span {
        display: block;
        font-size: 14px;
        color: #999;
        line-height: 35px;
        margin-left: 20px;
      }
      .newsContent-spam-size {
        display: flex;
        flex-direction: row;
        span {
          display: block;
        }
        .span-size {
          margin-left: 10px;
          cursor: pointer;
        }
        .span-size:hover {
          color: #000;
        }
      }
    }
    #newsContent-txt {
      margin-top: 30px;
      padding-bottom: 50px;
    }
  }
}
</style>