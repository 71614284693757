<template>
  <!-- 底部组件 -->
  <div class="foot-main">
    <div class="foot-main-top"></div>
    <div class="foot-main-foot">
      <div class="foot-main-foot-box">
        <div class="foot-main-foot-box-left">
          <div><img src="../assets/newGW/xlxr.png" alt=""></div>
          <div class="foot-main-foot-box-left-top">
            <div>销售热线:</div>
            <div> 0731-85671808 / 0731-85671806</div>
          </div>
          <div class="foot-main-foot-box-left-center">
            <div>公司地址:</div>
            <div>长沙高新开发区麓云路100号兴工科技园1栋504房</div>
          </div>
          <div class="foot-main-foot-box-left-foot">
            <div>电子邮箱:</div>
            <div>zhaomin@hnsyrj.com</div>
          </div>
        </div>
        <div class="xt-box"></div>
        <div class="foot-main-foot-box-right">
          <div class="foot-main-foot-box-right-top">
            <div class="foot-main-foot-box-right-top-left">
              <div class="left-box">
                <div class="left-box-top">扫码即可关注</div>
                <div class="left-box-top2">官方微信公众号</div>
                <div class="left-box-top3">服务热线：</div>
                <div class="left-box-top4">
                  <div><img src="../assets/newGW/bdh.png" alt=""></div>
                  <div>: 0731-85671805</div>
                </div>
              </div>
              <div class="right-box">
                <img src="../assets/newGW/new2wm.png" alt="">
              </div>
            </div>
            <div class="foot-main-foot-box-right-top-right">
              <div class="hq-box">
                <div class="hq-box-top">获取互联网技术解决方案</div>
                <div class="hq-box-bottom">留下您的联系方式，专属项目团队为您提供方案！</div>
              </div>
              <div class="hq-box-foot">
                <div class="hq-box-input">
                  <el-input v-model="name" class="w-50 m-2" placeholder="请输入您的姓名" />
                  <el-input v-model="phone" class="w-50 m-2" placeholder="请输入您的联系方式" style="margin-top:7px" />
                </div>
                <div class="hq-box-input-click">
                  <div class="buttom" @click="contactClick">
                    立&nbsp;即获&nbsp;取
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="foot-main-foot-box-right-foot">
            <div class="foot-main-foot-box-right-foot-top">
              <div>法律公告 | 隐私保护 | <span @click="goAboutus('6', '企业资质')" style="cursor:pointer"> 荣誉资质</span> | <span
                  style="cursor:pointer" @click="goAboutus('7', '加入我们')"> 加入我们</span> </div>
            </div>
            <div class="foot-main-foot-box-right-foot-foot">
              <div>版权所有：湖南四毅软件有限公司© 2019湘ICP备16006524号</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from "../axios/axios.js";
import router from "../router"
import { Message } from "element-ui";
export default {
  props: {
    borderShow: Boolean,
  },
  data() {
    return {
      addHttp: '',
      name: '',
      phone: '',
    }
  },
  created() {
    // if (this._isMobile()) {
    //   //手机端
    //   // this.ismOrpc = false
    //   document.documentElement.style.fontSize = document.documentElement.clientWidth / 10 + 'px';
    // } else {
    //   //pc端
    // }
  },
  mounted() {
    if (location.href.indexOf('siyirj.com') != -1) {
      this.addHttp = '2019湘ICP备16006524号'
    } else if (location.href.indexOf('hnsyrj.com') != -1) {
      this.addHttp = '湘ICP备2022003398号'
    } else {
      this.addHttp = '湘ICP备2022003398号'
    }
  },
  methods: {
    // 提交联系我们
    async contactClick() {
      await post("/api/PageQuery/AddContactUs", {
        name: this.name,
        phone: this.phone,
      }).then((res) => {
        if (res.flag) {
          Message.success("提交成功");
          this.name = "";
          this.phone = "";
        } else {
          Message.error(res.msg);
        }
      });
    },
    gotoPortal(url) {
      window.open(url, '_blank')
    },
    //判断手机端还是PC端
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    //尾部跳转
    //关于我们跳转
    goAboutus(index, name) {
      router.push({
        name: "ServicePage",
        query: { data: index, name: name }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.foot-main {
  display: flex;
  height: 18.375rem;
  width: 100%;
  display: flex;
  flex-direction: column;

  .foot-main-top {
    opacity: 1;
    border-radius: 0px;
  }

  .foot-main-foot {
    flex: 1;
    width: 100%;
    background-image: url("../assets/newGW/footBg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;

    .foot-main-foot-box {
      width: 62.5rem;
      display: flex;
      flex-wrap: nowrap;
      // margin-left: 478px;

      .foot-main-foot-box-left {
        margin-top: 2.25rem;
        display: flex;
        flex-direction: column;
        font-size: 0.75rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 1.5625rem;
        color: #FFFFFF;
        text-align: left;

        .foot-main-foot-box-left-top {
          margin-top: 0.3125rem;
        }

        .foot-main-foot-box-left-center {
          margin-top: 0.3125rem;
        }

        .foot-main-foot-box-left-foot {
          margin-top: 0.3125rem;
        }
      }

      .xt-box {
        width: 0px;
        height: 220px;
        border: 1px solid #E8E8E8;
        opacity: 0.35;
        margin-left: 2.9375rem;
        margin-top: 2.3125rem;
      }

      .foot-main-foot-box-right {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-top: 2.3125rem;
        margin-left: 2.5628rem;

        .foot-main-foot-box-right-top {
          display: flex;
          flex-wrap: nowrap;

          .foot-main-foot-box-right-top-left {
            width: 16.3125rem;
            height: 9.5rem;
            background: #C70F1A;
            opacity: 1;
            border-radius: 0px;
            display: flex;

            .left-box {
              display: flex;
              flex-direction: column;
              text-align: left;
              margin-top: 1.625rem;
              margin-left: 1.5625rem;

              .left-box-top {
                width: 100%;
                font-size: 0.8125rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                line-height: 0px;
                color: #FFFFFF;
                opacity: 1;
              }

              .left-box-top2 {
                font-size: 11px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 21px;
                color: #FFFFFF;
                opacity: 1;
                margin-top: 15px;
              }

              .left-box-top3 {
                font-size: 11px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 0px;
                color: #FFFFFF;
                opacity: 1;
                margin-top: 30px;
              }

              .left-box-top4 {
                font-size: 11px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 0px;
                color: #FFFFFF;
                opacity: 1;
                margin-top: 24px;
                display: flex;
                align-items: center;
                justify-content: space-between;
              }
            }

            .right-box {
              margin-left: 18px;
              margin-top: 28px;
            }
          }

          .foot-main-foot-box-right-top-right {
            height: 152px;
            background: #343232;
            opacity: 1;
            border-radius: 0px;
            display: flex;
            flex-direction: column;

            .hq-box {
              margin: 26px 94px 10px 25px;
              text-align: left;

              .hq-box-top {
                font-size: 13px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                line-height: 0px;
                color: #FFFFFF;
                opacity: 1;
              }

              .hq-box-bottom {
                margin-top: 10px;
                font-size: 11px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 21px;
                color: #FFFFFF;
                opacity: 1;
              }
            }

            .hq-box-foot {
              display: flex;
              flex-wrap: nowrap;

              .hq-box-input {
                display: flex;
                flex-direction: column;
                text-align: left;
                margin-left: 26px;
              }

              .hq-box-input-click {
                width: 47px;
                height: 47px;
                background: #C70F1A;
                opacity: 1;
                border-radius: 2px;
                margin-left: 10px;
                cursor: pointer;

                .buttom {
                  width: 32px;
                  height: 33px;
                  font-size: 15px;
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  line-height: 18px;
                  color: #FFFFFF;
                  opacity: 1;
                  margin: 5px 7px 7px 7px;
                }
              }
            }


          }
        }

        .foot-main-foot-box-right-foot {
          margin-top: 10px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 36px;
          color: #FFFFFF;
          opacity: 1;
          text-align: left;
        }
      }
    }
  }
}

::v-deep .el-input__inner {
  width: 148px;
  height: 20px;
  background: #DCDCDC;
  opacity: 1;
  border-radius: 2px;
  font-size: 9px;
}
</style>