/* 数据请求的唯一入口 */
import axios from "axios";
import { Message } from 'element-ui';
// function startLoading() {
//   // 开始加载
//   // Message({
//  // message: "加载中...",
//   // });
// }
// function endLoading() {
//   // 结束加载
//   // Message();
// }
// axios.defaults.baseURL = "http://10.168.2.188:5042/"; // 正式
// axios.defaults.baseURL = (process.env.NODE_ENV === 'development') ? 'http://dev.hnsy.com:5042/' : '/'
console.log(process.env.NODE_ENV, 'process.env.NODE_ENV', axios.defaults.baseUrl);
const configurl = (axios.defaults.baseURL = (process.env.NODE_ENV === 'development') ? 'http://dev.hnsy.com:5042/' : '/')
// export default {
//   configG
// }

/* 设置超时时间和是否允许跨域 */
axios.defaults.timeout = 10000;
axios.interceptors.request.use(
  (config) => {
    // startLoading();
    axios.defaults.headers.post["Content-Type"] =
      "'Content-Type':'application/x-www-form-urlencoded'";
    // console.log(config, "1111");
    // const token = localStorage.getItem("token");
    // token && (config.headers.Authorization = token);

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    // endLoading();
    return response.data;
  },
  (error) => {
    // startLoading();
    const { response } = error;
    // 有response说明服务器响应了
    if (response) {
      switch (response.status) {
        // 当前请求需要验证(一般是未登录)
        case 401:
          Message({
            message: "请重新登录",
            duration: 1500,
            forbidClick: true,
          });
          console.log(401);
          break;
        // 服务器拒绝执行(token过期)
        case 403:
          Message({
            message: "验证码过期请重新登录",
            duration: 1500,
            forbidClick: true,
          });
          console.log(403);
          break;
        // 找不到页面
        case 404:
          Message({
            message: "网络请求不存在",
            duration: 1500,
            forbidClick: true,
          });
          console.log('hahhah');
          break;
        // 服务器出错
        case 500:
          break;
        default:
          break;
      }
    } else {
      // 服务器没有响应
      if (!window.navigator.onLine) {
        // 断网处理跳转去断网页面
        this.$router.push({ name: "Home" });
        return;
      }
      return Promise.reject(error);
    }
  }
);
export function get(url, params) {
  return axios.get(url, { params });
}

export function post(url, data) {
  // console.log(url, "url");
  // console.log(data, "data");
  return axios.post(url, data);
}
export default {
  axios,
  configurl
}
