<template>
  <div>
    <div class="news-main">
      <SiteTop></SiteTop>
      <div class="news-center">
        <LeftNav></LeftNav>
      </div>
      <SiteFoot></SiteFoot>
    </div>
  </div>
</template>

<script>
import SiteTop from "../components/siteTop.vue";
import SiteFoot from "../components/siteFoot.vue";
import { get } from '../axios/axios.js';
import axios from '../axios/axios.js'
import LeftNav from "../components/leftNav.vue";
export default {
  components: { SiteTop, SiteFoot, LeftNav },
  data() {
    return {
      newsId: 1,
      currentPage: 1,
      rows: 15,
      newsList: [],
      policyList: [],
      industryList: [],
      total: 0,
      baseUrl: '',
      activeName: 'first',
      gsClore: false,

    };
  },
  created() {
    // if (this._isMobile()) {
    //   //手机端
    //   document.documentElement.style.fontSize = document.documentElement.clientWidth / 10 + 'px';
    //   console.log(document.documentElement.style.fontSize);
    // } else {
    //   //pc端
    // }
    this.baseUrl = axios.configurl
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.newsId = this.$route.query.index;
    this.getNewsIndustry()
    this.getIndustry()
    this.getPolicy()

  },
  mounted() {
    this.newsId = this.$route.query.index
    if (this.newsId == 1) {
      this, this.activeName = 'first'
    } else if (this.newsId == 2) {
      this, this.activeName = 'second'
    } else if (this.newsId == 3) {
      this, this.activeName = 'third'
    }
  },

  methods: {
    //判断手机端还是PC端
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    async getNewsIndustry() {
      await get('/api/PageQuery/GetFrontEndNewsTrends', { DataType: 2, Page: this.currentPage, Rows: this.rows }).then(res => {
        if (res.flag == true) {
          this.total = res.total
          this.industryList = res.data.date
        }
      })
    },
    async getIndustry() {
      await get('/api/PageQuery/GetFrontEndNewsTrends', { DataType: 1, Page: this.currentPage, Rows: this.rows }).then(res => {
        if (res.flag == true) {
          this.newsList = res.data.date
          this.total = res.total
        }
      })
    },
    async getPolicy() {
      await get('/api/PageQuery/GetFrontEndNewsTrends', { DataType: 3, Page: this.currentPage, Rows: this.rows }).then(res => {
        if (res.flag == true) {
          this.policyList = res.data.date
          this.total = res.total
        }
      })
    },
    gotoContent(id) {
      this.$router.push({
        name: 'NewsContent',
        query: { id: id },
      })
    },
    gotoMoreNews() {
      this.$router.push({
        name: 'MoreNews',
      })
    },
    gotoMoreIndustry() {
      this.$router.push({
        name: 'MoreIndustry',
      })
    },
    gotoMorePolicy() {
      this.$router.push({
        name: 'MorePolicy',
      })
    },
    handleClick(tab) {
      if (tab.index == 0) {
        this.gsClore = true
      }

    }
  },
}
</script>

<style lang="scss" scoped>
// @keyframes myScale {
//   0% {
//     transform: scale(1);
//   }

//   100% {
//     transform: scale(1.2);
//   }
// }

.news-main {
  width: 100%;
  height: 100%;

  .news-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .breadcrumb-box {
      width: 1160px;
      height: 58px;
      margin-top: 30px;
      border-radius: 33px;
      padding-left: 40px;
      background-color: #fff;
      display: flex;
      align-items: center;
      display: flex;
      flex-direction: row;

      .breadcrumb-icon {
        width: 17px;
        height: 19px;
        margin-right: 6px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      span {
        display: block;
        margin-left: 10px;
        font-size: 17px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
      }

      .span-icon {
        display: block;
        color: #000;
      }

      .el-breadcrumb {
        margin-left: 10px;
        color: #000;
        font-size: 17px;
      }

      ::v-deep.el-breadcrumb__inner a,
      .el-breadcrumb__inner.is-link {
        font-weight: 400;
        font-size: 17px;
        text-decoration: none;
        transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        color: #ff0000;
      }
    }

    .indeusty-tabs-box {
      margin-top: 18px;
      margin-bottom: 45px;

      ::v-deep .el-tabs__nav-scroll {
        overflow: hidden;
        background-color: #fff;
      }

      ::v-deep .el-tabs__item {
        padding: 0 20px;
        height: 70px;
        width: 250px;
        box-sizing: border-box;
        display: inline-block;
        line-height: 70px;
        list-style: none;
        font-size: 18px;
        font-weight: 500;
        position: relative;
      }

      ::v-deep .el-tabs__item.is-active {
        color: #ff0000;
      }

      ::v-deep .el-tabs__item:hover {
        color: #ff0000;
        cursor: pointer;
      }

      ::v-deep .el-tabs__active-bar {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 3px;
        width: 100px !important;
        margin-left: 50px;
        background-color: red;
        z-index: 1;
        transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        list-style: none;
      }

      .newsNew {
        width: 1200px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;

        .latest-news-title {
          width: 412px;
          height: 95px;
          background: url('../assets/title-bg.jpg');
          background-size: 100% 100%;
          margin-bottom: 40px;

          h1 {
            margin-top: 25px;
            font-size: 30px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ff0000;
          }
        }

        .latest-news-box {
          border-bottom: 1px solid #ccc;

          .latest-news-list {
            width: 974px;
            height: 220px;
            display: flex;
            margin: 20px 0px;
            flex-direction: row;
            align-items: center;
            background-color: #fff;
            position: relative;

            .news-list-img {
              cursor: pointer;
              width: 278px;
              height: 160px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .news-list-text {
              cursor: pointer;
              width: 100%;
              height: 160px;
              margin-left: 30px;
              text-align: left;

              h1 {
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #000000;
              }

              h1:hover {
                color: #ff0000;
              }

              span:hover {
                color: #ff0000;
              }

              span {
                display: block;
                margin-top: 15px;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 45px;
                color: #727272;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }

              .news-time {
                text-align: right;
                margin-top: 35px;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #cccccc;
              }
            }
          }
        }

        .latest-news-box:nth-child(4) {
          border-bottom: 0px;
        }
      }

      .more-news {
        width: 1200px;
        background-color: #fff;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .more-news-txt {
          margin-top: 35px;
          width: 412px;
          height: 95px;
          background: url('../assets/title-bg.jpg');
          background-size: 100% 100%;

          h1 {
            margin-top: 25px;
            font-size: 30px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #2e2e2e;
          }
        }

        .more-box {
          margin-top: 55px;
          width: 1080px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .more-news-list {
            width: 517px;
            height: 470px;
            margin-bottom: 110px;
            background: #fff5f5;

            .more-news-list-title {
              display: flex;
              flex-direction: row;
              width: 100%;
              height: 48px;
              background: linear-gradient(89deg, #ffdede 0%, #fff5f5 100%);
              justify-content: space-between;
              align-items: center;

              .more-listlleft {
                display: flex;
                flex-direction: row;

                .red-box {
                  display: block;
                  width: 7px;
                  height: 28px;
                  background: #ff0000;
                }

                span {
                  display: block;
                  margin-left: 10px;
                  font-size: 20px;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                  color: #2e2e2e;
                }
              }

              span {
                cursor: pointer;
                margin-right: 20px;
                display: block;
                font-size: 17px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #2e2e2e;
              }

              span:hover {
                color: #ff0000;
              }
            }

            .txt-title {
              cursor: pointer;
              position: relative;
              height: 66px;
              background: #fff5f5;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              padding-left: 25px;
              padding-right: 20px;

              .txt-title-txt {
                width: 316px;

                span {
                  text-align: left;
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #707070;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }

              .txt-title-txt:not(:last-child)::after {
                content: '';
                position: absolute;
                left: 20px;
                bottom: 0px;
                width: 472px;
                height: 1px;
                background: #cccccc;
              }

              span {
                display: block;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #cccccc;
              }
            }

            .txt-title:hover {
              span {
                color: #ff0000;
              }
            }
          }
        }

        .pagination {
          margin-top: 60px;
          padding-bottom: 100px;
        }
      }
    }
  }
}

// .mobile {
//   display: none;
// }

// @media screen and (max-width: 981px) {
//   .news-main {
//     display: none;
//   }

//   .mobile {
//     display: block;

//     .mobile-banner {
//       width: 100%;
//       height: 3.5rem;

//       img {
//         width: 100%;
//         height: 100%;
//       }
//     }

//     .mobile-bg {
//       padding-top: 0.2rem;
//       width: 100%;
//       background-color: #f5f5f5;
//       height: calc(100vh - 5.2rem);
//       display: flex;
//       justify-content: center;

//       .mobile-tabs {
//         display: flex;
//         flex-direction: row;
//         justify-content: space-around;

//         // align-items: center;
//         ::v-deep .el-tabs__nav-scroll {
//           overflow: hidden;
//           background-color: #fff;
//         }

//         ::v-deep .el-tabs__item {
//           padding: 0 0.2rem;
//           width: 3rem;
//           margin-bottom: 0.4rem;
//           box-sizing: border-box;
//           display: inline-block;
//           list-style: none;
//           font-size: 0.36rem;
//           font-weight: 600;
//           position: relative;
//         }

//         ::v-deep .el-tabs__item.is-active {
//           color: #000;
//         }

//         ::v-deep .el-tabs__item:hover {
//           color: #000;
//           cursor: pointer;
//         }

//         ::v-deep .el-tabs__active-bar {
//           position: absolute;
//           bottom: 0;
//           left: 0;
//           height: 0.03rem;
//           width: 1rem !important;
//           margin-left: 0.8rem;
//           background-color: red;
//           z-index: 1;
//           transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
//           list-style: none;
//         }

//         .tabs-list-box {
//           display: flex;
//           width: 100%;
//           flex-direction: column;
//           align-items: center;
//           background-color: #fff;

//           .h-title {
//             margin-top: 0.3rem;
//             width: 4rem;
//             height: 1rem;
//             line-height: 1rem;
//             background: url('../assets/mobile/title.jpg');
//             background-size: 100% 100%;

//             h1 {
//               font-size: 0.48rem;
//               font-family: Microsoft YaHei;
//               font-weight: bold;
//               color: #2e2e2e;
//             }
//           }

//           .latest-news-box {
//             padding: 0.1rem 0.15rem;
//             box-sizing: border-box;

//             .latest-news-list {
//               width: 9.5rem;
//               height: 2.2rem;
//               display: flex;

//               flex-direction: row;
//               align-items: center;
//               background-color: #fff;
//               position: relative;
//               border-bottom: 1px solid #ccc;
//               box-sizing: border-box;

//               .news-list-img {
//                 cursor: pointer;
//                 width: 2.8rem;
//                 height: 1.6rem;

//                 img {
//                   width: 100%;
//                   height: 100%;
//                 }
//               }

//               .news-list-text {
//                 cursor: pointer;
//                 width: 100%;
//                 height: 1.6rem;
//                 margin-left: 0.3rem;
//                 text-align: left;

//                 h1 {
//                   font-size: 0.2rem;
//                   font-family: Microsoft YaHei;
//                   font-weight: bold;
//                   color: #000000;
//                 }

//                 h1:hover {
//                   color: #ff0000;
//                 }

//                 span:hover {
//                   color: #ff0000;
//                 }

//                 span {
//                   display: block;
//                   margin-top: 0.16rem;
//                   font-size: 0.2rem;
//                   font-family: Microsoft YaHei;
//                   font-weight: 400;
//                   line-height: 0.45rem;
//                   color: #727272;
//                   display: -webkit-box;
//                   -webkit-line-clamp: 2;
//                   -webkit-box-orient: vertical;
//                   overflow: hidden;
//                 }

//                 .news-time {
//                   text-align: right;
//                   margin-top: 0.3rem;
//                   font-size: 0.2rem;
//                   font-family: Microsoft YaHei;
//                   font-weight: 400;
//                   color: #cccccc;
//                 }
//               }
//             }
//           }

//           .latest-news-list:last-child {
//             border-bottom: 0;
//           }

//           .products-item-null {
//             width: 9.8rem;
//           }
//         }
//       }
//     }
//   }
// }
</style>
