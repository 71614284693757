<template>
  <div class="fix-box">
    <div class="fix-box-left">
      <div class="fix-box-left-box" v-show="ismOrpc">
        <div class="fix-box-left-box-one">
          <div class="one-top">服务热线：</div>
          <div class="one-center">
            <div> <img src="../assets/newGW/xdh.png" alt=""></div>
            <div class="one-center-wz"> : 0731-85671806</div>
          </div>
          <div class="one-foot">
            <div> <img src="../assets/newGW/xdh.png" alt=""></div>
            <div class="one-foot-wz"> : 0731-85671808</div>
          </div>
        </div>
      </div>
      <div class="fix-box-left-box1" style="margin-top:3.75rem" v-show="ismOrWx">
        <div class="fix-box-left-box-two">
          <div class="two-top">
            <div class="two-center">扫码即可关注</div>
            <div class="two-foot">官方微信公众号</div>
          </div>
          <div class="right-box">
            <img src="../assets/newGW/new2wm.png" alt="">
          </div>
        </div>
      </div>
      <div class="fix-box-left-box2" style="margin-top:7.75rem" v-show="ismDhWx" @mouseleave='ycDhClick'>
        <div class="fix-box-left-box-three">
          <div class="three-top">获取互联网技术解决方案</div>
          <div class="three-center">留下您的联系方式，专属项目团队为您提供方案！</div>
          <div class="three-foot">
            <div class="three-foot-box">
              <div style="width:9.25rem">
                <el-input v-model="name" class="w-50 m-2" placeholder="请输入您的姓名" />
                <el-input v-model="phone" class="w-50 m-2" placeholder="请输入您的联系方式" style="margin-top:.4375rem" />
              </div>
            </div>
            <div class="hq-box-input-click">
              <div class="buttom" @click="contactClick">
                立&nbsp;即获&nbsp;取
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fix-box-left-box3" style="margin-top:2.125rem">
      </div>
    </div>
    <div class="fix-box-right">
      <div class="fix-box-right-box">
        <img src="../assets/newGW/dh.png" alt="" @mouseenter="btnClick" @mouseleave='ycClick'>
      </div>
      <div class="fix-box-right-box" style="margin-top:.75rem">
        <img src="../assets/newGW/wx.png" alt="" @mouseenter="btnwxClick" @mouseleave='ycWxClick'>
      </div>
      <div class="fix-box-right-box" style="margin-top:.75rem">
        <img src="../assets/newGW/qb.png" alt="" @mouseenter="btnDhClick">
      </div>
    </div>
  </div>
</template>
<script>

import { post } from "../axios/axios.js";
import { Message } from "element-ui";
export default {
  data() {
    return {
      addHttp: "",
      baseUrl: "",
      ismOrpc: false,
      ismOrWx: false,
      ismDhWx: false,
      name: "",
      phone: "",
      carouselHeight: "40.125rem",
      // 首页轮播图
      bannerImg: [],
      mobileList: [],
    };
  },
  created() {
    // if (this._isMobile()) {
    //   //手机端
    //   document.documentElement.style.fontSize =
    //     document.documentElement.clientWidth / 10 + "px";
    //   console.log(document.documentElement.style.fontSize, "mobile");
    // } else {
    //   //pc端
    //   console.log(document.documentElement.style.fontSize, "pc");
    // }
  },
  mounted() {
    // var ref = this.$route.query.name;
    // if (ref !== undefined) {
    //   document.querySelector("#" + ref).scrollIntoView(true);
    // }
    // window, addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    // 去新闻和行业
    gotoNews(i) {
      this.$router.push({
        name: "NewsIndustry",
        query: { index: i },
      });
    },
    // 去具体新闻内容
    gotoContent(id) {
      this.$router.push({
        name: "NewsContent",
        query: { id: id },
      });
    },
    // 提交联系我们
    async contactClick() {
      await post("/api/PageQuery/AddContactUs", {
        name: this.name,
        phone: this.phone,
      }).then((res) => {
        if (res.flag) {
          Message.success("提交成功");
          this.name = "";
          this.phone = "";
        } else {
          Message.error(res.msg);
        }
      });
    },
    //判断手机端还是PC端
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    btnClick() {
      this.ismOrpc = true
      this.ismDhWx = false
    },
    ycClick() {
      this.ismOrpc = false
    },
    btnwxClick() {
      this.ismOrWx = true
      this.ismDhWx = false
    },
    ycWxClick() {
      this.ismOrWx = false
    },
    btnDhClick() {
      this.ismDhWx = true
    },
    ycDhClick() {
      this.ismDhWx = false
    }
  },
};
</script>


<style scoped lang="scss">
.fix-box {
  position: absolute;
  top: 35rem;
  right: 4.625rem;
  width: 18.75rem;
  display: flex;

  .fix-box-left {
    display: flex;
    flex-direction: column;

    .fix-box-left-box {
      display: flex;
      height: 7.1875rem;
      flex-direction: column;
      background-image: url("../assets/newGW/syfu.png");
      background-size: 100% 100%;

      .fix-box-left-box-one {
        display: flex;
        flex-direction: column;
        padding: 1.875rem 6.8125rem 1.375rem 1.6875rem;

        .one-top {
          width: 4.0625rem;
          height: 1.0625rem;
          font-size: .8125rem;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 0rem;
          color: #C70F1A;
          opacity: 1;
        }

        .one-center {
          height: 1rem;
          font-size: .75rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 0rem;
          color: #C70F1A;
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .one-foot {
          height: 1rem;
          font-size: .75rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 0rem;
          color: #C70F1A;
          opacity: 1;
          margin-top: .6875rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    .fix-box-left-box1 {
      display: flex;
      width: 15.9375rem;
      height: 8.75rem;
      flex-direction: column;
      background-image: url("../assets/newGW/syewm.png");
      background-size: 100% 100%;

      .fix-box-left-box-two {
        display: flex;
        flex-wrap: nowrap;

        .two-top {
          display: flex;
          flex-direction: column;
          width: 5.375rem;
          margin: 2.5rem 0 0 1.625rem;
          text-align: left;

          .two-center {
            height: 1.0625rem;
            font-size: .8125rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            line-height: 0rem;
            color: #C70F1A;
            opacity: 1;
          }

          .two-foot {
            height: .9375rem;
            font-size: .6875rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 1.3125rem;
            color: #C70F1A;
            opacity: 1;
          }
        }

        .right-box {
          flex: 1;
          margin-top: 1.25rem;
        }
      }
    }

    .fix-box-left-box2 {
      display: flex;
      width: 15.9375rem;
      height: 8.75rem;
      flex-direction: column;
      background-image: url("../assets/newGW/syewm.png");
      background-size: 100% 100%;

      .fix-box-left-box-three {
        display: flex;
        flex-direction: column;

        .three-top {
          width: 10.125rem;
          height: 1.0625rem;
          font-size: .8125rem;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 0rem;
          color: #C70F1A;
          opacity: 1;
          margin: 1.25rem 0 0 1.0625rem;
        }

        .three-center {
          text-align: left;
          width: 16.875rem;
          height: .75rem;
          font-size: .5625rem;
          transform: scale(0.8);
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 1.3125rem;
          color: #C70F1A;
          opacity: 1;
        }

        .three-foot {
          margin-top: .75rem;
          display: flex;

          .three-foot-box {
            display: flex;
            flex-direction: column;
            margin-left: 1.625rem;
          }

          .hq-box-input-click {
            width: 2.9375rem;
            height: 2.9375rem;
            background: #C70F1A;
            opacity: 1;
            border-radius: .125rem;
            margin-left: .625rem;
            cursor: pointer;

            .buttom {
              width: 2rem;
              height: 2.0625rem;
              font-size: .9375rem;
              font-family: Source Han Sans CN;
              font-weight: bold;
              line-height: 1.125rem;
              color: #FFFFFF;
              opacity: 1;
              margin: .3125rem .4375rem .4375rem .4375rem;
            }
          }
        }
      }
    }

    .fix-box-left-box3 {
      width: 15.9375rem;
      height: 8.75rem;
    }
  }

  .fix-box-right {
    display: flex;
    flex-direction: column;
    margin-top: .625rem;

    .fix-box-right-box {
      width: 3.125rem;
      height: 3.125rem;
      background-size: cover;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
}

@media screen and (max-width: 981px) {
  .fix-box {
    position: absolute;
    top: 35rem;
    left: 73rem;
    width: 18.75rem;
    display: flex;

    .fix-box-left {
      display: flex;
      flex-direction: column;

      .fix-box-left-box {
        display: flex;
        height: 7.1875rem;
        flex-direction: column;
        background-image: url("../assets/newGW/syfu.png");
        background-size: 100% 100%;

        .fix-box-left-box-one {
          display: flex;
          flex-direction: column;
          padding: 1.875rem 6.8125rem 1.375rem 1.6875rem;

          .one-top {
            width: 4.0625rem;
            height: 1.0625rem;
            font-size: .8125rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            line-height: 0rem;
            color: #C70F1A;
            opacity: 1;
          }

          .one-center {
            height: 1rem;
            font-size: .75rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 0rem;
            color: #C70F1A;
            opacity: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .one-foot {
            height: 1rem;
            font-size: .75rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 0rem;
            color: #C70F1A;
            opacity: 1;
            margin-top: .6875rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }

      .fix-box-left-box1 {
        display: flex;
        width: 15.9375rem;
        height: 8.75rem;
        flex-direction: column;
        background-image: url("../assets/newGW/syewm.png");
        background-size: 100% 100%;

        .fix-box-left-box-two {
          display: flex;
          flex-wrap: nowrap;

          .two-top {
            display: flex;
            flex-direction: column;
            width: 5.375rem;
            margin: 2.5rem 0 0 1.625rem;
            text-align: left;

            .two-center {
              height: 1.0625rem;
              font-size: .8125rem;
              font-family: Microsoft YaHei;
              font-weight: bold;
              line-height: 0rem;
              color: #C70F1A;
              opacity: 1;
            }

            .two-foot {
              height: .9375rem;
              font-size: .6875rem;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 1.3125rem;
              color: #C70F1A;
              opacity: 1;
            }
          }

          .right-box {
            flex: 1;
            margin-top: 1.25rem;
          }
        }
      }

      .fix-box-left-box2 {
        display: flex;
        width: 15.9375rem;
        height: 8.75rem;
        flex-direction: column;
        background-image: url("../assets/newGW/syewm.png");
        background-size: 100% 100%;

        .fix-box-left-box-three {
          display: flex;
          flex-direction: column;

          .three-top {
            width: 10.125rem;
            height: 1.0625rem;
            font-size: .8125rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            line-height: 0rem;
            color: #C70F1A;
            opacity: 1;
            margin: 1.25rem 0 0 1.0625rem;
          }

          .three-center {
            text-align: left;
            width: 16.875rem;
            height: .75rem;
            font-size: .5625rem;
            transform: scale(0.8);
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 1.3125rem;
            color: #C70F1A;
            opacity: 1;
          }

          .three-foot {
            margin-top: .75rem;
            display: flex;

            .three-foot-box {
              display: flex;
              flex-direction: column;
              margin-left: 1.625rem;
            }

            .hq-box-input-click {
              width: 2.9375rem;
              height: 2.9375rem;
              background: #C70F1A;
              opacity: 1;
              border-radius: .125rem;
              margin-left: .625rem;
              cursor: pointer;

              .buttom {
                width: 2rem;
                height: 2.0625rem;
                font-size: .9375rem;
                font-family: Source Han Sans CN;
                font-weight: bold;
                line-height: 1.125rem;
                color: #FFFFFF;
                opacity: 1;
                margin: .3125rem .4375rem .4375rem .4375rem;
              }
            }
          }
        }
      }

      .fix-box-left-box3 {
        width: 15.9375rem;
        height: 8.75rem;
      }
    }

    .fix-box-right {
      display: flex;
      flex-direction: column;
      margin-top: .625rem;

      .fix-box-right-box {
        width: 3.125rem;
        height: 3.125rem;
        background-size: cover;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}

::v-deep .el-input__inner {
  width: 9.25rem;
  height: 1.25rem;
  background: #DCDCDC;
  opacity: 1;
  border-radius: .125rem;
  font-size: .5625rem;
}
</style>
