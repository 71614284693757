<template>
  <div class="main-right-table">
    <div class="main-right-table-top">
      <div class="main-right-table-top-title">
        <div><img src="../assets/newGW/fk.png" alt=""></div>
        <div class="main-right-table-top-title-wz">方案概述</div>
        <div><img src="../assets/newGW/fk.png" alt=""></div>
      </div>
      <div class="main-right-table-top-bottom">
        <div class="bottom-box-wz">以《行政事业单位内部控制规范》为指导，紧紧围绕预算、收支、采购、合同经济活动的开展，
          <span>
            紧紧围绕预算、收支、采购、合同经济活动的开展，并通过八大内控方法进行规范，构建行政事业单位内控管理系统，最终促进行政事业单位加强和规范内部控制，提高内部管理水平和风险防范能力，加强廉政风险防控机制建设。</span>
        </div>
      </div>
      <div class="main-right-table-top-bottom-img">
        <img src="../assets/newGW/nkbj.png" alt="">
      </div>
    </div>

    <div class="main-right-table-bottom">
      <div class="main-right-table-bottom-title">方案概述 </div>
      <div class="main-right-table-bottom-int">
        <div class='main-right-table-bottom-title-box'>1、资金一条线管理:
          <span>
            财政批复的部门预算指标数据导入单位预算信息库之中，外加自有资金，一起形成年度单位预算指标数据。单位可以依需进行指标的二次化分配或细化分解。单位依据分解细化后的预算指标进行日常的使用，在花钱之前，部分业务需要先进行费用申请，经过层层审批，形成报销的依据。在日常报销时，严格按照规定的报销标准和报销范围进行报销，出纳支付，会计人员进行入账，并出相关的账表。
          </span>
        </div>
        <div class='main-right-table-bottom-title-box'>2、物的一条线管理:
          <span>
            单位的资产从预算编制-采购申请-批准文件-合同-付款(结算)–仓储管理-配发-启用登记-日常管理(使用、清查、出租等)-上缴─报废的全周期流转。
          </span>
        </div>
        <div class='main-right-table-bottom-title-box'>3、预算、执行、核算、决算一体化:
          <span>
            实现从单位预算、请款、报销、核算到最后出具决算报告的整体应用流程。
          </span>
        </div>
        <div class='main-right-table-bottom-title-box'>4、采购管理一体化:
          <span>
            实现采购资金预算-采购计划-采购执行-采购支付-采购验收-采购档案的过程管理。
          </span>
        </div>

        <div class='main-right-table-bottom-title-box'>5、基建项目管理一体化:
          <span>
            实现项目立项-概预算-招标及合同-进度跟踪-资金支付-项目核算-验收决算的全周期管理。
          </span>
        </div>
        <div class='main-right-table-bottom-title-box'>6、合同管理一体化:
          <span>
            实现合同登记-合同备案-合同变更-支付执行-进度跟踪的全周期管理。
          </span>
        </div>
        <div class='main-right-table-bottom-title-box'>7、信息公开一体化:
          <span>
            通过综合信息门户对资源数据中心的数据进行一站式查询、展现，对内控有关信息进行透明化公开。
          </span>
        </div>
        <div class='main-right-table-bottom-title-box'>8、监督检查评价一体化:
          <span>
            通过建立风险预警规则模型-定义监控规则-疑点采集-结果反馈与审核-监控处理-疑点关闭等。
          </span>
        </div>
      </div>
    </div>
    <div class="main-right-table-foot">
      <div class="foot-title">总体框架</div>
      <div class="foot-title-two">
        <img src="../assets/newGW/nkbtntp.png" alt="">
      </div>
    </div>

  </div>
</template>
<script>

export default {

  data() {
    return {
      currentPage: 1,
      pagesize: 99,
      totalPages: 0,
      tableData: [],
      ZizeData: [],
      DataType: '1',
      titleData: '公司简介',
      select: '1',
      scrollerHeight: '420',
      dataHtml: [],
      isCollapse: true, //导航栏默认为展开
    };
  },
  created() {
  },
  mounted() {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.main-right-table {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: 800px;

  .main-right-table-top {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #F5F5F5;

    .main-right-table-top-title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 33px;

      .main-right-table-top-title-wz {
        height: 16px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 15px;
        color: #000000;
        opacity: 1;
        padding: 0px 9px 0px 9px;
      }
    }

    .main-right-table-top-bottom {
      display: flex;
      flex-wrap: nowrap;
      padding: 22px 50px 35px 50px;
      justify-content: space-between;

      .bottom-box-wz {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        opacity: 1;
        text-align: left;
        text-indent: 50px;
      }

      .bottom-box-wz>span {
        color: #C70F1A;
      }
    }

    .main-right-table-top-bottom-img {
      padding: 22px 0px 10px 0px
    }
  }



  .main-right-table-bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #FFFFFF;

    .main-right-table-bottom-title {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 33px;
      height: 16px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      line-height: 15px;
      color: #000000;
      opacity: 1;

    }

    .main-right-table-bottom-int {
      padding: 20px 50px 35px 50px;

      .main-right-table-bottom-title-box {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 30px;
        color: #000000;
        opacity: 1;
        text-align: left;
      }

      .main-right-table-bottom-title-box span {
        color: #C70F1A;
        margin-left: 3px;
      }
    }

  }

  .main-right-table-foot {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #F5F5F5;

    .foot-title {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #2E2E2E;
      opacity: 1;
      padding-top: 33px;
    }

    .foot-title-two {
      display: flex;
      justify-content: space-between;
      padding: 23px 50px 32px 50px;

    }

    .foot-title-three {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 23px 50px 0 50px;

      .foot-title-three-box {
        background: #F5F5F5;
        opacity: 1;
        border-radius: 5px;

        .foot-title-three-box-title {
          width: 124px;
          height: 26px;
          background: #CBCBCB;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          line-height: 26px;
          color: #C70F1A;
          opacity: 1;
        }

        .foot-title-three-box-center {
          display: flex;
          flex-direction: column;
          align-items: center;

          .foot-title-three-box-center-wz {
            display: flex;
            justify-content: space-between;
            align-items: center;
            justify-content: center;
            margin-top: 10px;

            .box-center-wz-top {
              width: 7px;
              height: 7px;
              background: #EB1B1B;
              border-radius: 50%;
              opacity: 1;
              margin-right: 13px;

            }

            .box-center-wz-bottom {
              font-size: 13px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 22px;
              color: #2E2E2E;
              opacity: 1;
            }
          }
        }
      }
    }

    .main-right-table-productBox {}
  }

}

@keyframes myMou1 {
  0% {
    margin-top: -12.5rem;
  }

  100% {
    margin-top: 0rem;
  }
}



::v-deep .el-table__body {
  -webkit-border-vertical-spacing: .625rem;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  width: 2.0625rem;
  height: 1.75rem;
  font-size: .5625rem;
  margin-top: .125rem;
  background: #C21F32;
  opacity: 1;
  border-radius: .125rem;
}

::v-deep .el-table .el-table__cell {
  padding: .5625rem 0;
}



::v-deep .el-submenu .el-menu-item {
  min-width: 6.25rem;
  font-size: .6875rem;
  height: 1.5625rem;
  line-height: 1.375rem;
  padding-left: 1.25rem !important;
}

//设置鼠标悬停时el-menu-item的样式
.el-menu-item:hover {
  background-image: url("../assets/newGW/navebg.png");
  background-size: 100% 100%;
  animation: 0.5s;
}

::v-deep .el-menu-item.is-active {
  // 被点击以后字体颜色改变
  background-image: url("../assets/newGW/navebg.png");
  background-size: 100% 100%;
  color: #F5F5F5;
}
</style>