<template>
  <div>
    <div class="home-main">
      <SiteTop></SiteTop>
      <div class="home-center">
        <!-- 主营业务 -->
        <div class="newsletter">
          <div class="newsletter-center">
            <div class="newsletter-center-title">
              <div class="wz-top">主&nbsp;营&nbsp;业&nbsp;务</div>
              <div class="wz-center">咨询<span>.</span>服务<span>.</span>软件</div>
              <div class="wz-img"><img src="../assets/newGW/newXT.png" alt=""></div>
            </div>
            <div class="newsletter-center-box">
              <div class="newsletter-center-box-left">
                <div class="newsletter-center-box-left-box">
                  <div class="newsletter-center-box-left-box-top">
                    <div class="min-box" @click="onClickProduct('9', '主营业务')">
                      <div><img src="../assets/newGW/zwxxh.png" alt=""></div>
                      <div class="min-box-wz">政务信息化</div>
                    </div>
                    <div class="min-box-show" @click="onClickProduct('9', '主营业务')">
                      <div class="min-box-wz">
                        <div class="min-box-wz-top">
                          <div class="min-box-wz-top-right">
                            助力数字政府建设
                          </div>
                        </div>
                      </div>
                      <div class="min-box-bottom"></div>
                    </div>
                  </div>
                  <div class="newsletter-center-box-left-box-top" style="margin-top:23px">
                    <div class="min-box" @click="onClickProduct('10', '主营业务')">
                      <div><img src="../assets/newGW/xxnkh1.png" alt=""></div>
                      <div class="min-box-wz">内控信息化</div>
                    </div>
                    <div class="min-box-show">
                      <div class="min-box-wz">
                        <div class="min-box-wz-top">
                          <div class="min-box-wz-top-right" @click="onClickProduct('10', '主营业务')">精细预算 高效管控</div>
                        </div>
                      </div>
                      <div class="min-box-bottom"></div>
                    </div>
                  </div>
                  <div class="newsletter-center-box-left-box-top" style="margin-top:23px">
                    <div class="min-box" @click="onClickProduct('11', '主营业务')">
                      <div><img src="../assets/newGW/qyxxh.png" alt=""></div>
                      <div class="min-box-wz">企业信息化</div>
                    </div>
                    <div class="min-box-show" @click="onClickProduct('11', '主营业务')">
                      <div class="min-box-wz">
                        <div class="min-box-wz-top">
                          <div class="min-box-wz-top-right">业务和数据的可视化分析平台</div>
                        </div>
                      </div>
                      <div class="min-box-bottom"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="newsletter-center-box-center">
                <div><img src="../assets/newGW/newYw.png" alt=""></div>
              </div>
              <div class="newsletter-center-box-right">
                <div class="newsletter-center-box-left-box">
                  <div class="newsletter-center-box-left-box-top">
                    <div class="min-box" @click="onClickProduct('12', '主营业务')">
                      <div><img src="../assets/newGW/dzkf2.png" alt=""></div>
                      <div class="min-box-wz">定制开发</div>
                    </div>
                    <div class="min-box-show">
                      <div class="min-box-wz">
                        <div class="min-box-wz-top">
                          <div class="min-box-wz-top-right" @click="onClickProduct('12', '主营业务')">个性化的需求设计和定制研发</div>
                        </div>
                      </div>
                      <div class="min-box-bottom"></div>
                    </div>
                  </div>
                  <div class="newsletter-center-box-left-box-top" style="margin-top:23px">
                    <div class="min-box" @click="onClickProduct('13', '主营业务')">
                      <div><img src="../assets/newGW/jxgl.png" alt=""></div>
                      <div class="min-box-wz">绩效管理</div>
                    </div>
                    <div class="min-box-show">
                      <div class="min-box-wz">
                        <div class="min-box-wz-top">
                          <div class="min-box-wz-top-right" @click="onClickProduct('13', '主营业务')">经营目标转化为可测量的标准</div>
                        </div>
                      </div>
                      <div class="min-box-bottom"></div>
                    </div>
                  </div>
                  <div class="newsletter-center-box-left-box-top" style="margin-top:23px">
                    <div class="min-box" @click="onClickProduct('14', '主营业务')">
                      <div><img src="../assets/newGW/zncw.png" alt=""></div>
                      <div class="min-box-wz">智能财务</div>
                    </div>
                    <div class="min-box-show">
                      <div class="min-box-wz">
                        <div class="min-box-wz-top">
                          <div class="min-box-wz-top-right" @click="onClickProduct('14', '主营业务')">智能会计管理平台</div>
                        </div>
                      </div>
                      <div class="min-box-bottom"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Regularbox></Regularbox>
        </div>
        <!-- 产品系列 -->
        <div class="industry">
          <div class="industry-center">
            <div class="industry-center-title">
              <div class="wz-top">产&nbsp;品&nbsp;系&nbsp;列</div>
              <div class="wz-center">汇聚公共管理智慧</div>
              <div class="wz-img"><img src="../assets/newGW/newXT.png" alt=""></div>
            </div>
            <div class="industry-center-box">
              <el-carousel indicator-position="outside" height="400px">
                <el-carousel-item v-for="(item, index) in CpList" :key="index">
                  <div class="view-item">
                    <el-card style="width:320px" :body-style="{ padding: '0px' }" v-for="(items, index) in item"
                      :key="index">
                      <div class="view-item-box">
                        <div class="view-item-box-img" @click="onClickProduct(items.id, '产品系列')"> <img
                            :src="baseUrl + 'Attachment/' + items.background"
                            style="width:100%;height:100%;box-shadow: none; cursor: pointer;">
                        </div>
                        <div class="view-item-center"> {{ items.title }}</div>
                        <div class="view-item-buttom" @click="onClickProduct(items.id, '产品系列')">{{ items.introduce }}
                        </div>
                      </div>
                    </el-card>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
        <!-- 新闻动态 -->
        <div class="certiflcate">
          <div class="certiflcate-center">
            <div class="certiflcate-center-title">
              <div class="wz-top">新&nbsp;闻&nbsp;动&nbsp;态</div>
              <div class="wz-center">行业新闻 公司动态</div>
              <div class="wz-img"><img src="../assets/newGW/newXT.png" alt=""></div>
            </div>
            <div class="certiflcate-center-box">
              <div class="certiflcate-center-box-box">
                <div class="certiflcate-center-box-left" @click=newClick(newData[0].id) v-if="newData[0]">
                  <img :src="baseUrl + 'Attachment/' + newData[0].url" style="width:100%;height:100%">
                </div>
                <div class="certiflcate-center-box-right">
                  <div class="certiflcate-center-box-right-box" v-for="(item, index) in newData" :key="index"
                    @click="newClick(item.id)">
                    <div class=" right-box-left">
                      <div class="right-box-left-top">{{ formatDateDD(item.time) }}</div>
                      <div class="right-box-left-buttom">{{ formatDate(item.time) }}</div>
                    </div>
                    <div class="right-box-right">
                      <div class="right-box-right-top">{{ item.title }}</div>
                      <div class="right-box-right-buttom">{{ item.homepageContent }}</div>
                    </div>
                    <div class="xtBox">
                    </div>
                  </div>
                  <div class="ckButtom" @click="newClick()">
                    <div class="ckButtom-wz">查看更多</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 企业资质 -->
        <div class="enterprise">
          <div class="enterprise-center">
            <div class="enterprise-center-title">
              <div class="wz-top">企&nbsp;业&nbsp;资&nbsp;质</div>
              <div class="wz-center">自主研发知识产权认证</div>
              <div class="wz-img"><img src="../assets/newGW/newXT.png" alt=""></div>
            </div>
            <div class="enterprise-center-box">
              <div class="view-item-box" :body-style="{ padding: '0px' }">
                <div style="width:320px;">
                  <img src="../assets/newGW/qyzz1.png" style="width:320px;height:200px">
                </div>
                <div style="width:320px;">
                  <img src="../assets/newGW/qyzz2.png" style="width:320px;height:200px">
                </div>
                <div :body-style="{ padding: '0px' }" style="width:320px;">
                  <img src="../assets/newGW/qyzz3.png" style="width:320px;height:200px">
                </div>
              </div>
              <div class="ckButtom" @click="seachClick('6', '企业资质')">
                查看更多
              </div>
            </div>
          </div>
        </div>
        <!-- 合作伙伴 -->
        <div class="partner">
          <div class="partner-center">
            <div class="partner-center-title">
              <div class="wz-top">合&nbsp;作&nbsp;伙&nbsp;伴</div>
              <div class="wz-img"><img src="../assets/newGW/newXT.png" alt=""></div>
            </div>
            <div class="partner-center-box">
              <div class="view-item" v-for="(item, index) in getHzdata" :key="index">
                <div class="view-item-box"> <img :src="baseUrl + 'Attachment/' + item.url" style="width:100%;height:100%">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SiteFoot></SiteFoot>
  </div>
</template>

<script>
import SiteTop from "./siteTop.vue";
import SiteFoot from "./siteFoot.vue";
import Regularbox from './regularbox.vue';
import axios from "../axios/axios.js";
import { get, post } from "../axios/axios.js";
import router from "../router"
import { Message } from "element-ui";
import moment from "moment";

var _hmt = _hmt || [];
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?4fb36837d829585a17b6f866adef608a";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();
// Regularbox
export default {
  components: { SiteFoot, SiteTop, Regularbox },
  data() {
    return {
      addHttp: "",
      baseUrl: "",
      // ismOrpc: true,
      nameValue: "",
      phoneValue: "",
      carouselHeight: "642px",
      // 首页轮播图
      bannerImg: [],
      mobileList: [],
      //新闻动态
      newData: [],
      //企业资质
      ZizeData: [],
      newDataList: [],
      current: 0,
      //合作伙伴
      getHzdata: [],
      //产品
      CpList: [],
      scroll: 0
    };
  },
  created() {
    this.getAlllist();
    this.getZizeData();
    this.getCplist()
    this.getHzlist()
    this.getLblist()
    this.dy_cutstr()
    this.baseUrl = axios.configurl;
    // if (this._isMobile()) {
    //   //手机端
    //   // this.ismOrpc = false
    //   document.documentElement.style.fontSize = document.documentElement.clientWidth / 10 + "px";
    //   console.log(document.documentElement.style.fontSize, "mobile");
    // } else {
    //   //pc端
    //   // this.ismOrpc = true
    //   console.log(document.documentElement.style.fontSize, "pc");
    // }
  },
  mounted() {
    // var ref = this.$route.query.name;
    // if (ref !== undefined) {
    //   document.querySelector("#" + ref).scrollIntoView(true);
    // }
    // window, addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    // clickContact(val) {
    //   document.querySelector("#" + val).scrollIntoView(true);
    // },
    formatDate(value) {
      return moment(value).format("YYYY-MM");
    },
    formatDateDD(value) {
      return moment(value).format("DD");
    },
    //判断手机端还是PC端
    // _isMobile() {
    //   let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    //   return flag;
    // },
    // 截取字符串
    cutstr(str, len) {
      var str_length = 10;
      var str_cut = new String();
      for (var i = 0; i < str.length; i++) {
        var a = str.charAt(i);
        str_length++;
        if (escape(a).length > 4) {
          //中文字符的长度经编码之后大于4
          str_length++;
        }
        str_cut = str_cut.concat(a);
        if (str_length >= len) {
          str_cut = str_cut.concat("...");
          return str_cut;
        }
      }
      //如果给定字符串小于指定长度，则返回源字符串；
      if (str_length < len) {
        return str;
      }
    },
    // 调用截取函数
    dy_cutstr() {
      let text = this.CpList.map((item) => {
        let content = this.cutstr(item.content,); //截取200个字数
        let title = item.introduce;
        let date = item.date;
        return { title, content, date }; //格式化：需要返回对象数组
      });
      this.str = text; //将格式化的数据赋值给自定义的str[]
    },
    // 去新闻和行业
    gotoNews(i) {
      this.$router.push({
        name: "NewsIndustry",
        query: { index: i },
      });
    },
    // 去专利页面
    gotoPatent(value) {
      this.$router.push({
        name: "Patent",
        query: {
          name: value,
        },
      });
    },
    //轮播图
    async getLblist() {
      await post("/api/PageQuery/GetFrontHomepagePictures",).then((res) => {
        if (res.flag) {
          this.bannerImg = res.data
        } else {
          Message.error("数据加载失败");
        }
      });
    },
    //新闻动态
    async getAlllist() {
      await get("/api/PageQuery/GetFrontEndNewsTrends", { DataType: 1, Page: 1, Rows: 3, }).then((res) => {
        if (res.flag) {
          this.newData = res.data.date
        } else {
          Message.error("数据加载失败");
        }
      });
    },
    //企业资质
    async getZizeData() {
      let newDataList = [];
      let current = 0
      await get("/api/PageQuery/GetQualificationFrontEnd",).then((res) => {
        if (res.flag) {
          this.ZizeData = res.data.list
          if (this.ZizeData && this.ZizeData.length > 0) {
            for (let i = 0; i <= this.ZizeData.length - 1; i++) {
              if (i % 3 !== 0 || i === 0) {	//数据处理成几张展示
                if (!newDataList[current]) {
                  newDataList.push([this.ZizeData[i]])
                } else {
                  newDataList[current].push(this.ZizeData[i])
                }
              } else {
                current++
                newDataList.push([this.ZizeData[i]])
              }
            }
          }
          this.ZizeData = [...newDataList]
        } else {
          Message.error("数据加载失败");
        }
      });
    },
    //产品方案
    async getCplist() {
      let newDataList = [];
      let current = 0
      await get("/api/PageQuery/GetFrontProductServiceChild", { ServiceType: 0, Page: 1, Rows: 99, }).then((res) => {
        if (res.flag) {
          this.CpList = res.data.gettProductService
          if (res.flag) {
            if (this.CpList && this.CpList.length > 0) {
              for (let i = 0; i <= this.CpList.length - 1; i++) {
                if (i % 3 !== 0 || i === 0) {	//数据处理成几张展示
                  if (!newDataList[current]) {
                    newDataList.push([this.CpList[i]])
                    console.log(newDataList)
                  } else {
                    newDataList[current].push(this.CpList[i])
                  }
                } else {
                  current++
                  newDataList.push([this.CpList[i]])
                }
              }
            }
            this.CpList = [...newDataList]
          } else {
            Message.error("数据加载失败");
          }
        } else {
          Message.error("数据加载失败");
        }
      });
    },
    //合作伙伴
    async getHzlist() {
      await get("/api/PageQuery/GetFrontPartner", { name: '', Page: 1, Rows: 10, }).then((res) => {
        if (res.flag) {
          this.getHzdata = res.data
        } else {
          Message.error("数据加载失败");
        }
      });
    },
    // 去具体新闻内容
    newClick(id) {
      this.$router.push({
        name: "NewsIndustry",
        query: { id: id },
      });
    },
    seachClick(data, name) {
      router.push({
        name: "ServicePage",
        query: { data: data, name: name }
      });
    },
    //主营业务跳转
    Mainbusiness(id, name) {
      router.push({
        name: "Mainbusiness",
        query: { data: id, name: name }
      });
    },
    //产品跳转
    onClickProduct(id, name) {
      router.push({
        name: "ProductCenter",
        query: { data: id, name: name }
      });
    },
  }
};
</script>


<style scoped lang="scss">
.home-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;

  .home-center {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

    .home-banner {
      width: 100%;
      height: 40.125rem;

      ::v-deep.el-carousel__container {
        position: relative;
        height: 40.125rem;
      }

      img {
        width: 100%;
        height: 100%;
        animation: myScale 5s linear forwards;
      }
    }

    .newsletter {
      width: 100%;
      background-color: #fff;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: center;

      .newsletter-center {
        width: 62.5rem;
        display: flex;
        margin-top: 3.875rem;
        flex-direction: column;

        .newsletter-center-title {
          display: flex;
          flex-wrap: wrap;
          width: 6.25rem;
          flex-direction: column;
          text-align: left;

          .wz-top {
            font-size: 22px;
            color: #000000;
            opacity: 1;
          }

          .wz-center {
            width: 6.25rem;
            font-size: .8125rem;
            margin-top: 1.0625rem;
            color: #6E6E6E;
            display: flex;
            justify-content: space-between;
          }

          .wz-img {
            margin-top: .5rem;
          }
        }

        .newsletter-center-box {
          margin-top: 1.6875rem;
          display: flex;
          width: 100%;
          padding-bottom: 3.1875rem;
          justify-content: space-between;

          .newsletter-center-box-left {
            display: flex;
            flex-direction: column;

            .newsletter-center-box-left-box-top {
              width: 18.25rem;
              height: 7.1875rem;
              background: #F5F5F5;
              opacity: 1;
              border-radius: .1875rem;
              position: relative;
              display: flex;
              flex-direction: column;

              .min-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: .9375rem;

                .min-box-wz {
                  width: 6.625rem;
                  height: 1.125rem;
                  font-size: 1.1875rem;
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  line-height: 0px;
                  color: #C70F1A;
                  opacity: 1;
                  padding-top: .625rem;
                }
              }

              .min-box-show {
                width: 18.25rem;
                height: 6.875rem;
                position: absolute;
                display: none;

                .min-box-wz {
                  height: calc(100% - .3125rem);
                  background: #C70F1A;
                  opacity: 0.9;
                  bottom: 0;

                  .min-box-wz-top {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .min-box-wz-top-right {
                      margin-top: 2.5rem;
                      font-size: 1rem;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      line-height: 1.3125rem;
                      color: #FFFFFF;
                      opacity: 1;
                      margin-left: .625rem;
                      cursor: pointer;
                      // overflow: hidden;
                      // white-space: nowrap;
                      // text-overflow: ellipsis;
                    }
                  }
                }

                .min-box-bottom {
                  width: 292px;
                  height: 5px;
                  background: #C70F1A;
                  opacity: 1;
                  border-radius: 0px;
                }
              }
            }

            .newsletter-center-box-left-box-top:hover .min-box-show {
              display: block;
            }
          }

          .newsletter-center-box-right {
            display: flex;
            flex-direction: column;

            .newsletter-center-box-left-box-top {
              width: 292px;
              height: 115px;
              background: #F5F5F5;
              opacity: 1;
              border-radius: 3px;
              position: relative;
              display: flex;
              flex-direction: column;

              .min-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 15px;

                .min-box-wz {
                  width: 90px;
                  height: 18px;
                  font-size: 18px;
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  line-height: 0px;
                  color: #C70F1A;
                  opacity: 1;
                  padding-top: 10px;
                }
              }

              .min-box-show {
                width: 292px;
                height: 110px;
                position: absolute;
                display: none;

                .min-box-wz {
                  height: calc(100% - 5px);
                  background: #C70F1A;
                  opacity: 0.9;
                  bottom: 0;

                  .min-box-wz-top {
                    display: flex;
                    justify-content: center;


                    .min-box-wz-top-right {
                      font-size: 16px;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      line-height: 21px;
                      color: #FFFFFF;
                      opacity: 1;
                      margin-top: 40px;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      cursor: pointer;
                    }
                  }
                }

                .min-box-bottom {
                  width: 292px;
                  height: 5px;
                  background: #C70F1A;
                  opacity: 1;
                  border-radius: 0px;
                }
              }
            }

            .newsletter-center-box-left-box-top:hover .min-box-show {
              display: block;
            }
          }
        }
      }
    }

    .industry {
      display: flex;
      flex-direction: column;
      align-items: center;

      .industry-center {
        width: 1000px;
        display: flex;
        margin-top: 62px;
        flex-direction: column;

        .industry-center-title {
          display: flex;
          flex-wrap: wrap;
          width: 100px;
          flex-direction: column;
          text-align: left;

          .wz-top {
            font-size: 22px;
            color: #000000;
            opacity: 1;
          }

          .wz-center {
            width: 132px;
            display: flex;
            font-size: 13px;
            margin-top: 17px;
            color: #6E6E6E;
          }

          .wz-img {
            margin-top: 8px;
          }
        }

        .industry-center-box {
          margin-top: 35px;

          .view-item {
            display: flex;
            justify-content: space-between;

            .view-item-box {
              display: flex;
              flex-direction: column;


              .view-item-center {
                height: 16px;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                line-height: 16px;
                color: #000000;
                opacity: 1;
                text-align: left;
                box-shadow: none;
                margin: 23px;
              }

              .view-item-buttom {
                font-size: 15px;
                font-weight: 400;
                line-height: 21px;
                box-shadow: none;
                color: #6E6E6E;
                opacity: 1;
                text-align: left;
                margin: 0px 23px 23px 23px;
                width: 274px;
                height: 79px;
                /* 1.溢出隐藏 */
                overflow: hidden;
                /* 2.用省略号来代替超出文本 */
                text-overflow: ellipsis;
                /* 3.设置盒子属性为-webkit-box  必须的 */
                display: -webkit-box;
                /* 4.-webkit-line-clamp 设置为2，表示超出2行的部分显示省略号，如果设置为3，那么就是超出3行部分显示省略号 */
                -webkit-line-clamp: 4;
                /* 5.字面意思：单词破坏：破坏英文单词的整体性，在英文单词还没有在一行完全展示时就换行  即一个单词可能会被分成两行展示 */
                word-break: break-all;
                /* 6.盒子实现多行显示的必要条件，文字是垂直展示，即文字是多行展示的情况下使用 */
                -webkit-box-orient: vertical;
                cursor: pointer;
              }

              .view-item-box-img {
                box-shadow: none;
              }
            }
          }

          .view-item :hover {
            background: #FFFFFF;
            box-shadow: 3px 3px 23px rgba(0, 0, 0, 0.27);
            border-radius: 3px;
          }
        }
      }

      .industry-box {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        cursor: pointer;
        justify-content: space-around;

        .content-box {
          text-align: left;
          margin: 10px;
          width: 510px;

          img {
            margin-top: 30px;
            width: 100%;
            height: 280px;
          }

          h1 {
            margin-top: 30px;
            font-size: 26px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #2e2e2e;
          }

          h1:hover {
            color: #ff0000;
          }

          span {
            margin-top: 20px;
            display: block;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 36px;
            color: #777777;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          span:hover {
            color: #ff0000;
          }

          .industry-time {
            display: block;
            margin-top: 40px;
            font-size: 17px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
        }
      }

      button {
        margin-top: 60px;
        width: 246px;
        height: 51px;
        background: #ffd4d4;
        opacity: 0.48;
        border-radius: 34px;
        font-size: 21px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ff0000;
        border: 0;
        margin-bottom: 60px;
      }

      button:hover {
        background-color: #f68989;
      }
    }

    .certiflcate {
      width: 100%;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;

      .certiflcate-center {
        width: 1000px;
        display: flex;
        margin-top: 62px;
        flex-direction: column;

        .certiflcate-center-title {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          text-align: left;

          .wz-top {
            font-size: 22px;
            color: #000000;
            opacity: 1;
          }

          .wz-center {
            width: 132px;
            font-size: 13px;
            margin-top: 17px;
            color: #6E6E6E;
          }

          .wz-img {
            margin-top: 8px;
          }
        }

        .certiflcate-center-box {
          margin-top: 43px;
          width: 100%;
          height: 329px;
          background: #F5F5F5;
          margin-bottom: 40px;
        }

        .certiflcate-center-box-box {
          display: flex;
          flex-wrap: nowrap;
          padding: 20px 18px 20px 20px;

          .certiflcate-center-box-left {
            width: 489px;
            height: 290px;
            cursor: pointer;

            .img {
              background-size: 100% 100%;
            }
          }

          .certiflcate-center-box-right {
            flex: 1;
            margin-left: 24px;
            flex-direction: column;
            cursor: pointer;

            .certiflcate-center-box-right-box {
              display: flex;
              flex-wrap: wrap;

              .right-box-left {
                display: flex;
                flex-wrap: wrap;
                width: 67px;

                .right-box-left-top {
                  width: 100%;
                  font-size: 25px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  line-height: 47px;
                  color: #000000;
                  opacity: 1;
                }

                .right-box-left-buttom {
                  font-size: 10px;
                  width: 100%;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  line-height: 19px;
                  color: #000000;
                  opacity: 1;
                  text-align: center;
                  padding-bottom: 13px;
                }
              }

              .right-box-right {
                display: flex;
                flex-direction: column;
                flex: 1;
                margin-left: 25px;
                overflow: hidden;

                .right-box-right-top {
                  width: 247px;
                  font-size: 13px;
                  margin-top: 10px;
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  line-height: 24px;
                  color: #000000;
                  opacity: 1;
                  text-align: left;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                .right-box-right-buttom {
                  font-size: 10px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  line-height: 21px;
                  color: #8A8A8A;
                  opacity: 1;
                  text-align: left;
                  height: 40px;
                }
              }

              .xtBox {
                width: 449px;
                height: 0px;
                border: 0.5px solid #707070;
                opacity: 0.3;
                box-sizing: border-box;
              }
            }

            .ckButtom {
              text-align: right;
              margin-top: 19px;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 26px;
              color: #C21F32;
              opacity: 1;
              display: flex;
              justify-content: flex-end;

              .ckButtom-wz {
                width: 58px;
                border-bottom: 1px solid #C21F32;
              }
            }
          }
        }
      }
    }

    .enterprise {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .enterprise-center {
        width: 1000px;
        display: flex;
        margin-top: 62px;
        flex-direction: column;

        .enterprise-center-title {
          display: flex;
          flex-wrap: wrap;
          width: 100px;
          flex-direction: column;
          text-align: left;

          .wz-top {
            font-size: 22px;
            color: #000000;
            opacity: 1;
          }

          .wz-center {
            width: 132px;
            font-size: 13px;
            margin-top: 17px;
            color: #6E6E6E;
          }

          .wz-img {
            margin-top: 8px;
          }
        }

        .enterprise-center-box {
          margin-top: 27px;
          display: flex;
          flex-direction: column;

          .view-item-box {
            display: flex;
            justify-content: space-between;
            align-items: center;


            .img {
              width: 100%;
            }
          }

          .ckButtom {
            width: 56px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 26px;
            color: #C21F32;
            border-bottom: 1px solid #C21F32;
            opacity: 1;
            align-self: end;
            display: flex;
            cursor: pointer;
            margin-right: 20px;
            margin-bottom: 48px;
          }
        }
      }
    }

    .partner {
      width: 100%;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;

      .partner-center {
        width: 1301px;
        display: flex;
        margin-top: 40px;
        flex-direction: column;
        align-items: center;

        .partner-center-title {
          display: flex;
          flex-wrap: wrap;
          width: 100px;
          flex-direction: column;

          .wz-top {
            font-size: 22px;
            color: #000000;
            opacity: 1;
          }

          .wz-center {
            width: 132px;
            font-size: 13px;
            margin-top: 17px;
            color: #6E6E6E;
          }

          .wz-img {
            margin-top: 8px;
          }
        }

        .partner-center-box {
          // width: 1301px;
          height: 153px;
          background: #F5F5F5;
          opacity: 1;
          border-radius: 3px;
          margin: 35px 0px 50px 0px;
          display: flex;
          padding: 0px 10px;
          align-items: center;
          justify-content: center;

          .view-item {
            display: flex;
            justify-content: space-between;
            padding: 24px 17px 24px 17px;

            .view-item-box {
              display: flex;
              flex-direction: column;
              height: 100%;
              width: 136px;
              height: 83px;

              .view-item-center {
                height: 16px;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                line-height: 16px;
                color: #000000;
                opacity: 1;
                text-align: left;
                margin: 23px;
              }

            }

            .view-item-buttom {
              font-size: 11px;
              font-weight: 400;
              line-height: 21px;
              color: #6E6E6E;
              opacity: 1;
              text-align: left;
              margin: 0px 23px 51px 23px;

            }
          }
        }
      }
    }

    .contact {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 90px;
      width: 100%;
      height: 200px;
      background-color: #fff;

      .contact-img {
        width: 100%;
        height: 15px;
        // background-image: url("../assets/组 1137.jpg");
        background-size: 100% 100%;
      }

      .contact-top {
        width: 1200px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .contact-txt {
          margin-top: 32px;
          text-align: left;

          h1 {
            font-size: 39px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000;
          }

          span {
            display: block;
            margin-top: 20px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000;
          }
        }

        .contact-phone {
          margin-left: 100px;
          margin-top: 40px;

          span {
            display: block;
            text-align: left;
            font-size: 30px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000;
          }

          .contact-bottom {
            // width: 1200px;
            margin-top: 25px;
            display: flex;
            flex-direction: row;

            button {
              width: 136px;
              height: 30px;
              background: #dcdcdc;
              border-radius: 42px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000;
              border: 0;
            }

            button:hover {
              background-color: #ffd4d4;
            }

            ::v-deep .el-input {
              position: relative;
              font-size: 13px;
              display: inline-block;
              width: 204px;
              height: 30px;
              color: #000;
              margin-right: 12px;
            }

            ::v-deep .el-input__inner {
              width: 204px;
              height: 30px;
              background: #dcdcdc;
              opacity: 1;
              padding-left: 19px;
              border-radius: 42px;
              margin-right: 25px;
              color: #000;
            }
          }
        }
      }
    }
  }
}



.industry-center-box ::v-deep .el-carousel__indicator--horizontal {
  padding-bottom: 50px;
}

.enterprise-center-box ::v-deep .el-carousel__indicator--horizontal {
  padding: 15px 0 50px 0px;
}

::v-deep .el-carousel__indicator--horizontal button {
  width: 16px;
  height: 4px;
  background: #808080;
  opacity: 0.41;
  border-radius: 50px;
  margin-left: 5px;
}

::v-deep .el-carousel__indicator--horizontal.is-active button {
  width: 23px;
  height: 4px;
  background: #C70F1A;
  opacity: 1;
  border-radius: 50px;
}

::v-deep .el-card.is-always-shadow {
  box-shadow: none;
}

//左箭头
::v-deep .el-carousel__arrow--left {
  top: 50%;
  left: 23px;
  width: 41px;
  height: 41px;
  font-size: 15px;
  color: #FFFFFF;
  background: #6E6E6E;
}

//右箭头
::v-deep .el-carousel__arrow--right {
  top: 50%;
  right: 23px;
  width: 41px;
  height: 41px;
  font-size: 15px;
  color: #FFFFFF;
  background: #6E6E6E;
}
</style>
